import React from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";

export default function AdvertiserInformation(props) {
  const { data } = props;

  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="view-advertiser-details-card">
        <Index.Box className="view-add-common-card common-card">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              NC 360 Details
            </Index.Typography>
          </Index.Box>
          <Index.Box className="nc-details-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Account Director:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.accDirector?.name || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Group Head:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.grpHead?.name || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Manager:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.manager?.name || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="view-add-common-card common-card">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              Company Details
            </Index.Typography>
          </Index.Box>
          <Index.Box className="company-details-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Direct Customer:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.directCustomer == true ? "Yes" : "No"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Company Name:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.compName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  State:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.state?.stateName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Zone:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.zone?.name || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  City:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.city || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-row-content-box">
            <Index.Box className="company-details-content-main">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Category:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.category?.categoryName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Sub Category:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.subCategory?.subCategoryName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-row-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Address 1:
              </Index.Typography>
              <Index.Tooltip
                title={data?.add1}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.add1 || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Tooltip>
            </Index.Box>
          </Index.Box>

          <Index.Box className="view-row-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Address 2:
              </Index.Typography>
              <Index.Tooltip
                title={data?.add2}
                arrow
                placement="top"
                className="admin-tooltip"
              >
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.add2 || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Tooltip>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        {data?.brand?.length ? (
          <Index.Box className="view-add-common-card common-card">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title"
                component="h2"
                variant="h2"
              >
                Advertisement Details
              </Index.Typography>
            </Index.Box>
            {data?.brand?.map((values) => {
              return (
                <Index.Box className="advertisement-details-content-main">
                  <Index.Box className="view-content-box">
                    <Index.Box className="view-content-flex">
                      <Index.Typography className="view-content-lable">
                        Brand Name:
                      </Index.Typography>
                      <Index.Box className="view-content-data-flex">
                        <Index.Typography className="view-content-data">
                          {values?.brandName || "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-content-box">
                    <Index.Box className="view-content-flex">
                      <Index.Typography className="view-content-lable">
                        Brand Slogan / Tagline:
                      </Index.Typography>
                      <Index.Box className="view-content-data-flex">
                        <Index.Typography className="view-content-data">
                          {values?.brandTagline || "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-content-box">
                    <Index.Box className="view-content-flex">
                      <Index.Typography className="view-content-lable">
                        Brand Ambassador:
                      </Index.Typography>
                      <Index.Box className="view-content-data-flex">
                        <Index.Typography className="view-content-data">
                          {values?.brandAmbassador || "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-content-box ">
                    <Index.Box className="view-content-flex media-platfom-flex">
                      <Index.Typography className="view-content-lable">
                        Media Platform Use:
                      </Index.Typography>
                      <Index.Box className="view-content-data-flex">
                        {values?.mediaToUse?.length ? (
                          values?.mediaToUse?.map((data) => {
                            return (
                              <Index.Box
                                className="view-category-box"
                                key={data}
                              >
                                <Index.Typography className="view-content-data">
                                  {data?.name}
                                </Index.Typography>
                              </Index.Box>
                            );
                          })
                        ) : (
                          <Index.Typography className="view-content-data">
                            -
                          </Index.Typography>
                        )}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-content-flex view-content-flex-align-item-start">
                    <Index.Typography className="view-content-lable">
                      Normal Advertising Period:
                    </Index.Typography>
                    <Index.Box className="view-category-flex-box">
                      {values?.adPeriod?.length ? (
                        values?.adPeriod?.map((data) => {
                          return (
                            <Index.Box className="view-category-box" key={data}>
                              <Index.Typography className="view-content-data">
                                {data}
                              </Index.Typography>
                            </Index.Box>
                          );
                        })
                      ) : (
                        <Index.Typography className="view-content-data">
                          -
                        </Index.Typography>
                      )}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              );
            })}
          </Index.Box>
        ) : (
          <></>
        )}

        <Index.Box className="view-add-common-card common-card">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              Client Personal Details
            </Index.Typography>
          </Index.Box>
          <Index.Box className="client-details-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Client Id:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.advNo || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  First Name:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.firstName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Last Name:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.lastName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Designation:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.designation || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Contact Number:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.contact || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Email Id:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.email || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  FCT:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.fct ? "Yes" : "No"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  NFCT:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.nfct ? "Yes" : "No"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Favourite Celebrities:
                </Index.Typography>
                <Index.Box className="view-category-flex-box">
                  {data?.celebrities?.length ? (
                    data?.celebrities?.map((data) => {
                      return (
                        <Index.Box className="view-category-box" key={data}>
                          <Index.Typography className="view-content-data">
                            {data}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  ) : (
                    <Index.Typography className="view-content-data">
                      -
                    </Index.Typography>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Passionate:
                </Index.Typography>
                <Index.Box className="view-category-flex-box">
                  {data?.passionate?.length ? (
                    data?.passionate?.map((data) => {
                      return (
                        <Index.Box className="view-category-box" key={data}>
                          <Index.Typography className="view-content-data">
                            {data}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  ) : (
                    <Index.Typography className="view-content-data">
                      -
                    </Index.Typography>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Social Cause:
                </Index.Typography>
                <Index.Box className="view-category-flex-box">
                  {data?.socialCause?.length ? (
                    data?.socialCause?.map((data) => {
                      return (
                        <Index.Box className="view-category-box" key={data}>
                          <Index.Typography className="view-content-data">
                            {data}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })
                  ) : (
                    <Index.Typography className="view-content-data">
                      -
                    </Index.Typography>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Impact Or Show Partnership:
                </Index.Typography>
                <Index.Typography className="view-content-data">
                  {data?.partenership || "-"}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-row-content-box ">
            <Index.Box className="view-content-flex family-details-flex">
              <Index.Typography className="view-content-lable">
                Family Details:
              </Index.Typography>
              {data?.familyDetails?.map((data) => {
                return (
                  <Index.Box className="view-content-data-flex">
                    <Index.Typography className="view-squre-box">
                      {data?.name}
                    </Index.Typography>
                    <Index.Typography className="view-squre-box">
                      {data?.relation}
                    </Index.Typography>
                    <Index.Typography className="view-squre-box">
                      {data?.comment ? data?.comment : ""}
                    </Index.Typography>
                  </Index.Box>
                );
              }) || "-"}
            </Index.Box>
          </Index.Box>
          <Index.Box className="view-row-content-box">
            <Index.Box className="view-content-flex">
              <Index.Typography className="view-content-lable">
                Remark:
              </Index.Typography>
              <Index.Typography className="view-content-data">
                {data?.remark || "-"}
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="view-add-common-card common-card">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              Agency Details
            </Index.Typography>
          </Index.Box>
          <Index.Box className="agency-details-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Agency Name:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.agencyName || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {data?.agencyDetails?.filter(
            (data) =>
              data.role !== "" &&
              data.name !== "" &&
              data.contact !== "" &&
              data.email !== ""
          )?.length ? (
            <Index.Box className="view-row-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Agency Details:
                </Index.Typography>
                <Index.Box className="agency-view-content-flex">
                  {data?.agencyDetails?.map((data) => {
                    return (
                      <Index.Box
                        className="view-content-data-flex"
                        key={data?._id}
                      >
                        {data?.role && (
                          <Index.Typography className="view-squre-box">
                            {data?.role}
                          </Index.Typography>
                        )}
                        {data?.name && (
                          <Index.Typography className="view-squre-box">
                            {data?.name}
                          </Index.Typography>
                        )}
                        {data?.contact && (
                          <Index.Typography className="view-squre-box">
                            {data?.contact}
                          </Index.Typography>
                        )}
                        {data?.email && (
                          <Index.Typography className="view-squre-box">
                            {data?.email}
                          </Index.Typography>
                        )}
                      </Index.Box>
                    );
                  }) || "-"}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ) : (
            <></>
          )}
        </Index.Box>

        {data?.events?.length ? (
          <Index.Box className="view-add-common-card common-card">
            <Index.Box className="admin-sub-title-main">
              <Index.Typography
                className="admin-sub-title"
                component="h2"
                variant="h2"
              >
                Event
              </Index.Typography>
            </Index.Box>
            {data?.events?.map((data) => {
              return (
                <Index.Box
                  className="event-details-content-main"
                  key={data?._id}
                >
                  <Index.Box className="view-content-box">
                    <Index.Box className="view-content-flex">
                      <Index.Typography className="view-content-lable">
                        Event:
                      </Index.Typography>
                      <Index.Box className="view-content-data-flex">
                        <Index.Typography className="view-content-data">
                          {data?.eventName?.name}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="view-content-box">
                    <Index.Box className="view-content-flex">
                      <Index.Typography className="view-content-lable">
                        Date:
                      </Index.Typography>
                      <Index.Box className="view-content-data-flex">
                        <Index.Typography className="view-content-data">
                          {PagesIndex.moment(data?.date).format("DD/MM/YYYY")}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              );
            })}
          </Index.Box>
        ) : (
          ""
        )}

        {/* <Index.Box className="view-add-common-card common-card">
          <Index.Box className="admin-sub-title-main">
            <Index.Typography
              className="admin-sub-title"
              component="h2"
              variant="h2"
            >
              Billing Details
            </Index.Typography>
          </Index.Box>
          <Index.Box className="view-advertiser-content-main">
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Company name:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.brand || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Address:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.brandSlogan || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  Email:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.brandAmbassador || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex view-content-flex-align-item-start">
                <Index.Typography className="view-content-lable">
                  GST No:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.brandAmbassador || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="view-content-box">
              <Index.Box className="view-content-flex">
                <Index.Typography className="view-content-lable">
                  PAN No:
                </Index.Typography>
                <Index.Box className="view-content-data-flex">
                  <Index.Typography className="view-content-data">
                    {data?.brandAmbassador || "-"}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box> */}
      </Index.Box>
    </Index.Box>
  );
}
