import { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import { getIn } from "formik";
import WeekdayTable from "./WeekdayTable";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";

export const SchedulingModalInner = (props) => {
  const {
    index,
    values,
    errors,
    handleChange,
    setFieldValue,
    touched,
    selectedData,
    orderList,
    creativeList,
    slotDataArr,
    programData,
  } = props;
  const [openCalenderStartModal, setOpenCalenderStartModal] = useState(false);
  const [openCalenderEndModal, setOpenCalenderEndModal] = useState(false);

  const dateType = `scheduleDetails[${index}].type`;
  const touchedDateType = getIn(touched, dateType);
  const errorDateType = getIn(errors, dateType);
  const scheduleType = `scheduleDetails[${index}].scheduleType`;
  const touchedScheduleType = getIn(touched, scheduleType);
  const errorScheduleType = getIn(errors, scheduleType);
  const startDate = `scheduleDetails[${index}].startDate`;
  const touchedStartDate = getIn(touched, startDate);
  const errorStartDate = getIn(errors, startDate);
  const endDate = `scheduleDetails[${index}].endDate`;
  const touchedEndDate = getIn(touched, endDate);
  const errorEndDate = getIn(errors, startDate);
  const creativeDuration = `scheduleDetails[${index}].creativeDuration`;
  const creative = `scheduleDetails[${index}].creative`;
  const touchedCreative = getIn(touched, creative);
  const errorCreative = getIn(errors, creative);
  const selectedDates = `scheduleDetails[${index}].selectedDates`;
  const touchedSelectedDates = getIn(touched, selectedDates);
  const errorSelectedDates = getIn(errors, selectedDates);
  const program = `scheduleDetails[${index}].program`;
  const touchedProgram = getIn(touched, program);
  const errorProgram = getIn(errors, program);
  const slots = `scheduleDetails[${index}].slots`;
  const touchedSlots = getIn(touched, slots);
  const errorSlots = getIn(errors, slots);
  const fromTime = `scheduleDetails[${index}].fromTime`;
  const touchedFromTime = getIn(touched, fromTime);
  const errorFromTime = getIn(errors, fromTime);
  const toTime = `scheduleDetails[${index}].toTime`;
  const touchedToTime = getIn(touched, toTime);
  const errorToTime = getIn(errors, toTime);
  const weekDays = `scheduleDetails[${index}].weekDays`;
  const touchedWeekDays = getIn(touched, weekDays);
  const errorWeekDays = getIn(errors, weekDays);
  const setWeekDays = (selectedStartDate, selectedEndDate) => {
    var date = PagesIndex.moment(new Date(selectedStartDate));
    var start = PagesIndex.moment(date).startOf("week");
    var endDate = PagesIndex.moment(new Date(selectedEndDate));
    var end = PagesIndex.moment(endDate).endOf("week");
    var weekArray = [];
    for (var j = 0; j < Math.ceil(end.diff(date, "days") / 7); j) {
      weekArray.push({
        dates: [],
        name: `Week ${j + 1}`,
        total: 0,
      });
      for (var k = 0; k < 7; k++) {
        weekArray[j].dates.push({
          fullDate: start.format("DD-MM-YYYY"),
          date: start.format("DD MMM"),
          value: 0,
          disabled: !(start >= selectedStartDate && start <= selectedEndDate),
        });
        start.add("days", 1);
        if (k == 6) {
          j++;
        }
      }
    }
    if (selectedStartDate && selectedEndDate) {
      setFieldValue(weekDays, weekArray);
    } else {
      setFieldValue(weekDays, []);
    }
  };
  const setSlotData = (selectedStartTime, selectedEndTime) => {
    const start = PagesIndex.moment(new Date(selectedStartTime)).set({
      date: 1,
      month: 1,
    });
    const end = PagesIndex.moment(new Date(selectedEndTime)).set({
      date: 1,
      month: 1,
    });
    const slotData = [];
    slotDataArr
      ?.filter((item) => {
        const fromTime = PagesIndex.moment(item?.fromTime).set({
          date: 1,
          month: 1,
        });
        const toTime = PagesIndex.moment(item?.toTime).set({
          date: 1,
          month: 1,
        });
        return (
          fromTime.isSameOrAfter(start) &&
          toTime.isSameOrAfter(start) &&
          fromTime.isSameOrBefore(end) &&
          toTime.isSameOrBefore(end)
        );
      })
      .map((option) => {
        slotData.push({
          fromTime: option?.fromTime,
          toTime: option.toTime,
        });
      });
    if (selectedStartTime && selectedEndTime) {
      setFieldValue(slots, slotData);
    } else {
      setFieldValue(slots, []);
    }
  };
  return (
    <Index.Box className="order-show-inner-box">
      <Index.Box className="grid-row">
        <Index.Box className="admin-sub-title-main view-order-title-main">
          <Index.Typography className="admin-sub-title">
            Date Type
          </Index.Typography>
        </Index.Box>

        <Index.Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          columnGap={{ xs: 0, sm: 0, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 8",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box add-ads-input-main admin-radio-main">
              <Index.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                id={dateType}
                name={dateType}
                className="admin-radiogroup"
                value={values?.type}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(startDate, null);
                  setFieldValue(endDate, null);
                  setFieldValue(selectedDates, []);
                }}
              >
                <Index.FormControlLabel
                  value="dateRange"
                  control={<Index.Radio className="admin-radio" />}
                  label="Date Range"
                />
                <Index.FormControlLabel
                  value="date"
                  control={<Index.Radio className="admin-radio" />}
                  label="Date"
                />
              </Index.RadioGroup>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {values?.type === "dateRange" ? (
        <Index.Box className="view-date-slot-main">
          <Index.Box className="grid-row">
            <Index.Box
              display="grid"
              gridTemplateColumns="repeat(12, 1fr)"
              columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              rowGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
              className="date-range-row"
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 4",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-input-box  admin-date-input-main input-spacing-remove">
                  <Index.FormHelperText className="admin-form-lable">
                    <span>Start Date</span>
                    <span className="astrick-sing">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <PagesIndex.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <PagesIndex.DatePicker
                        open={openCalenderStartModal}
                        onClose={() => setOpenCalenderStartModal(false)}
                        className="admin-form-control admin-date-picker-control"
                        value={values.startDate} // Use '01/01/2000' as the default value
                        onChange={(date) => {
                          setFieldValue(startDate, date);
                          setFieldValue(endDate, null);
                          setWeekDays(date, values?.endDate, index);
                        }}
                        minDate={
                          selectedData?.startDate
                            ? PagesIndex.dayjs(selectedData?.startDate)
                            : PagesIndex.dayjs(orderList?.startDate)
                        }
                        maxDate={
                          selectedData?.endDate
                            ? PagesIndex.dayjs(selectedData?.endDate)
                            : PagesIndex.dayjs(orderList?.endDate)
                        }
                        slotProps={{
                          popper: {
                            className: "date-picker-body",
                          },
                          inputAdornment: {
                            onClick: () => setOpenCalenderStartModal(true),
                          },
                          textField: {
                            readOnly: true,
                            onClick: () => setOpenCalenderStartModal(true),
                          },
                        }}
                        format="DD/MM/YYYY"
                      />
                    </PagesIndex.LocalizationProvider>
                    <Index.FormHelperText className="admin-error-text">
                      {errorStartDate && touchedStartDate && errorStartDate}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 8",
                  lg: "span 4",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-input-box admin-date-input-main input-spacing-remove">
                  <Index.FormHelperText className="admin-form-lable">
                    <span>End Date</span>
                    <span className="astrick-sing">*</span>
                  </Index.FormHelperText>
                  <Index.Box className="admin-form-group">
                    <PagesIndex.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <PagesIndex.DatePicker
                        open={openCalenderEndModal}
                        onClose={() => setOpenCalenderEndModal(false)}
                        className="admin-form-control admin-date-picker-control"
                        value={values.endDate} // Use '01/01/2000' as the default value
                        onChange={(date) => {
                          setFieldValue(endDate, date);
                          setWeekDays(values?.startDate, date, index);
                        }}
                        minDate={
                          values?.startDate
                            ? PagesIndex.dayjs(values?.startDate)
                            : selectedData?.startDate
                            ? PagesIndex.dayjs(selectedData?.startDate)
                            : PagesIndex.dayjs(orderList?.startDate)
                        }
                        maxDate={
                          selectedData?.endDate
                            ? PagesIndex.dayjs(selectedData?.endDate)
                            : PagesIndex.dayjs(orderList?.endDate)
                        }
                        slotProps={{
                          popper: {
                            className: "date-picker-body",
                          },
                          inputAdornment: {
                            onClick: () => setOpenCalenderEndModal(true),
                          },
                          textField: {
                            readOnly: true,
                            onClick: () => setOpenCalenderEndModal(true),
                          },
                        }}
                        format="DD/MM/YYYY"
                      />
                    </PagesIndex.LocalizationProvider>
                    <Index.FormHelperText className="admin-error-text">
                      {errorEndDate && touchedEndDate && errorEndDate}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      ) : (
        <></>
      )}
      {values?.type === "date" ? (
        <Index.Box className="admin-input-box">
          <DatePicker
            className="admin-form-control admin-multi-date-picker-control"
            id={selectedDates}
            name={selectedDates}
            value={values?.selectedDates?.map((data) => data?.fullDate)}
            multiple
            placeholder="Select Dates"
            plugins={[<DatePanel />]}
            onChange={(dates) => {
              const formattedDates = dates.map((date) => ({
                fullDate: date.format("DD-MM-YYYY"),
                onlyDate: date.format("DD MMM"),
                day: date.format("dddd"),
                value: 0,
                disabled: false,
              }));
              setFieldValue(selectedDates, formattedDates);
            }}
            format={"DD/MM/YYYY"}
            minDate={
              selectedData?.startDate
                ? new Date(selectedData?.startDate)
                : new Date(orderList?.startDate)
            }
            maxDate={
              selectedData?.endDate
                ? new Date(selectedData?.endDate)
                : new Date(orderList?.endDate)
            }
          />
          <Index.FormHelperText className="admin-error-text">
            {errorSelectedDates && touchedSelectedDates && errorSelectedDates}
          </Index.FormHelperText>
        </Index.Box>
      ) : (
        <></>
      )}
      {/* Old Code Start Here */}

      {/* Old Code End Here */}
      <Index.Box className="creative-order-row">
        <Index.Box className="grid-row">
          <Index.Box className="admin-sub-title-main view-order-title-main">
            <Index.Typography className="admin-sub-title">
              Schedule Type
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        <Index.Box
          display="grid"
          gridTemplateColumns="repeat(12, 1fr)"
          columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
          rowGap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
        >
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 12",
              md: "span 12",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box admin-radio-main input-spacing-remove">
              <Index.RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                id={scheduleType}
                name={scheduleType}
                className="admin-radiogroup"
                value={values?.scheduleType}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(fromTime, null);
                  setFieldValue(toTime, null);
                  setFieldValue(program, undefined);
                  setFieldValue(slots, []);
                }}
              >
                <Index.FormControlLabel
                  value="program"
                  control={<Index.Radio className="admin-radio" />}
                  label="Program"
                />
                <Index.FormControlLabel
                  value="timeBand"
                  control={<Index.Radio className="admin-radio" />}
                  label="Timeband"
                />
              </Index.RadioGroup>
            </Index.Box>
          </Index.Box>
          <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 6",
              lg: "span 6",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box view-order-lable-input">
              <Index.FormHelperText className="admin-form-lable">
                Add Creative
              </Index.FormHelperText>
              <Index.Box className="admin-input-box input-complete-box">
                <Index.Box className="admin-form-group">
                  <Index.Autocomplete
                    className="admin-auto-complete-control"
                    options={
                      creativeList?.filter(
                        (creativeData) =>
                          selectedData?.platform ===
                            creativeData?.platformId?.typeName &&
                          selectedData?.elements ===
                            creativeData?.elementId?.name
                      ) || []
                    }
                    id={creative}
                    disableCloseOnSelect
                    closeText={""}
                    openText={""}
                    clearText={""}
                    value={
                      values?.creative
                        ? creativeList?.find(
                            (data) => data?._id === values?.creative
                          )
                        : null
                    }
                    onChange={(e, selectedOptions) => {
                      setFieldValue(creative, selectedOptions._id);
                      setFieldValue(creativeDuration, selectedOptions.duration);
                    }}
                    getOptionLabel={(option) =>
                      option?.duration
                        ? `${option?.code}-${option?.name}-${option?.duration} Sec`
                        : `${option?.code}-${option?.name}`
                    }
                    renderOption={(props, item) => (
                      <li {...props} key={item?._id}>
                        {item?.duration
                          ? `${item?.code}-${item?.name}-${item?.duration} Sec`
                          : `${item?.code}-${item?.name}`}
                      </li>
                    )}
                    renderInput={(params) => (
                      <Index.TextField
                        fullWidth
                        className="admin-form-control"
                        name="creative"
                        {...params}
                        size="small"
                        variant="outlined"
                        placeholder="Select Creative"
                      />
                    )}
                  />{" "}
                  <Index.FormHelperText className="admin-error-text">
                    {errorCreative && touchedCreative && errorCreative}
                  </Index.FormHelperText>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {values?.scheduleType === "program" ? (
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="admin-input-box view-order-lable-input">
                <Index.FormHelperText className="admin-form-lable">
                  Program
                </Index.FormHelperText>
                <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
                  <Index.Box className="admin-form-group">
                    <Index.Autocomplete
                      className="admin-form-control admin-auto-complete-control"
                      options={programData || []}
                      id={program}
                      closeText={""}
                      openText={""}
                      clearText={""}
                      value={
                        values?.program
                          ? programData?.find(
                              (data) => data?._id === values?.program
                            )
                          : null
                      }
                      onChange={(e, selectedOptions) => {
                        setFieldValue(program, selectedOptions._id);
                        const slotData = [];
                        const start = PagesIndex.moment(
                          selectedOptions?.startTime
                        ).set({
                          date: 1,
                          month: 1,
                        });
                        const end = PagesIndex.moment(
                          selectedOptions?.endTime
                        ).set({
                          date: 1,
                          month: 1,
                        });
                        slotDataArr
                          ?.filter((item) => {
                            const fromTime = PagesIndex.moment(
                              item?.fromTime
                            ).set({ date: 1, month: 1 });
                            const toTime = PagesIndex.moment(item?.toTime).set({
                              date: 1,
                              month: 1,
                            });
                            return (
                              fromTime.isSameOrAfter(start) &&
                              toTime.isSameOrAfter(start) &&
                              fromTime.isSameOrBefore(end) &&
                              toTime.isSameOrBefore(end)
                            );
                          })
                          .map((option) => {
                            slotData.push({
                              fromTime: option?.fromTime,
                              toTime: option.toTime,
                            });
                          });
                        setFieldValue(slots, slotData);
                      }}
                      getOptionLabel={(option) => option?.programName}
                      renderOption={(props, item) => (
                        <li {...props} key={item?._id}>
                          {item?.programName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <Index.TextField
                          fullWidth
                          className="admin-form-control"
                          name="program"
                          {...params}
                          size="small"
                          variant="outlined"
                          placeholder="Select Program"
                        />
                      )}
                    />
                    <Index.FormHelperText className="admin-error-text">
                      {errorProgram && touchedProgram && errorProgram}
                    </Index.FormHelperText>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ) : (
            <></>
          )}

          {values?.scheduleType === "timeBand" ? (
            <Index.Box
              gridColumn={{
                xs: "span 12",
                sm: "span 6",
                md: "span 6",
                lg: "span 6",
              }}
              className="grid-column"
            >
              <Index.Box className="view-date-slot-main">
                <Index.Box className="grid-row">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    columnGap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                        <Index.FormHelperText className="admin-form-lable">
                          From Time
                        </Index.FormHelperText>
                        <Index.FormControl
                          className="admin-form-control admin-drop-form-control"
                          // onClick={() => setFromDateOpen(true)}
                        >
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.TimePicker
                                // open={fromDateOpen}
                                // onClose={() => setFromDateOpen(false)}
                                views={["hours", "minutes", "seconds"]}
                                timeSteps={{
                                  minutes: 1,
                                  seconds: 1,
                                }}
                                className="admin-form-control admin-date-picker-control"
                                value={values?.fromTime}
                                onChange={(date) => {
                                  setFieldValue(fromTime, date);
                                  setFieldValue(toTime, null);
                                  setSlotData(date, values?.toTime);
                                  // setFromDateOpen(false);
                                }}
                                ampm={false}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    // Remove readOnly attribute to allow typing
                                    // readOnly: true,
                                    // onClick: () => setFromDateOpen(false),
                                  },
                                  // actionBar: {
                                  //   actions: [], // Remove the default actions including the "OK" button
                                  // },
                                }}
                              />
                            </PagesIndex.LocalizationProvider>
                            <Index.FormHelperText className="admin-error-text">
                              {errorFromTime &&
                                touchedFromTime &&
                                errorFromTime}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 6",
                        md: "span 6",
                        lg: "span 6",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                        <Index.FormHelperText className="admin-form-lable">
                          To Time
                        </Index.FormHelperText>
                        <Index.FormControl
                          className="admin-form-control admin-drop-form-control"
                          // onClick={() => setToDateOpen(true)}
                        >
                          <Index.Box className="admin-form-group">
                            <PagesIndex.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <PagesIndex.TimePicker
                                // open={toDateOpen}
                                // onClose={() => setToDateOpen(false)}
                                views={["hours", "minutes", "seconds"]}
                                timeSteps={{
                                  minutes: 1,
                                  seconds: 1,
                                }}
                                className="admin-form-control admin-date-picker-control"
                                value={values?.toTime}
                                onChange={(date) => {
                                  setFieldValue(toTime, date);
                                  setSlotData(values?.fromTime, date);
                                  // setToDateOpen(false);
                                }}
                                ampm={false}
                                slotProps={{
                                  popper: {
                                    className: "date-picker-body",
                                  },
                                  textField: {
                                    // Remove readOnly attribute to allow typing
                                    // readOnly: true,
                                    // onClick: () => setToDateOpen(false),
                                  },
                                  // actionBar: {
                                  //   actions: [], // Remove the default actions including the "OK" button
                                  // },
                                }}
                              />
                            </PagesIndex.LocalizationProvider>
                            <Index.FormHelperText className="admin-error-text">
                              {errorToTime && touchedToTime && errorToTime}
                            </Index.FormHelperText>
                          </Index.Box>
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          ) : (
            <></>
          )}
          {/* <Index.Box
            gridColumn={{
              xs: "span 12",
              sm: "span 6",
              md: "span 8",
              lg: "span 12",
            }}
            className="grid-column"
          >
            <Index.Box className="admin-input-box admin-modal-input-box input-complete-box">
              <Index.FormHelperText className="admin-form-lable">
                Slots
                <span className="astrick-sing">*</span>
              </Index.FormHelperText>
              <Index.Box className="admin-form-group">
                <Index.Autocomplete
                  className="admin-auto-complete-control"
                  options={slotDataArr || []}
                  id={slots}
                  multiple
                  disableCloseOnSelect
                  // disabled
                  closeText={""}
                  openText={""}
                  clearText={""}
                  value={
                    values?.slots
                      ? slotDataArr?.filter((data) =>
                          values?.slots?.some(
                            (item) =>
                              item?.fromTime === data?.fromTime &&
                              item?.toTime === data?.toTime
                          )
                        )
                      : []
                  }
                  onChange={(e, selectedOptions) => {
                    setFieldValue(
                      slots,
                      selectedOptions.map((option) => {
                        return {
                          fromTime: option?.fromTime,
                          toTime: option.toTime,
                        };
                      })
                    );
                  }}
                  getOptionLabel={(option) =>
                    `${PagesIndex.moment(option?.fromTime).format(
                      "hh:mm:ss A"
                    )}-${PagesIndex.moment(option?.toTime).format(
                      "hh:mm:ss A"
                    )}`
                  }
                  renderOption={(props, item) => (
                    <li {...props} key={item?._id}>
                      {PagesIndex.moment(item?.fromTime).format("hh:mm:ss A")}-
                      {PagesIndex.moment(item?.toTime).format("hh:mm:ss A")}
                    </li>
                  )}
                  renderInput={(params) => (
                    <Index.TextField
                      fullWidth
                      className="admin-form-control"
                      name="slots"
                      {...params}
                      size="small"
                      variant="outlined"
                      placeholder="Select Slots"
                    />
                  )}
                />
                {touched.slots && errors.slots && (
                  <PagesIndex.FormHelperText className="admin-error-text">
                    {errors.slots}
                  </PagesIndex.FormHelperText>
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>

      {values?.type === "dateRange" ? (
        values?.weekDays?.length ? (
          <Index.Box className="week-main">
            <Index.Box className="week-day-name-flex">
              <Index.Typography className="week-day-text"></Index.Typography>
              <Index.Typography className="week-day-text">Sun</Index.Typography>
              <Index.Typography className="week-day-text">Mon</Index.Typography>
              <Index.Typography className="week-day-text">Tue</Index.Typography>
              <Index.Typography className="week-day-text">Wed</Index.Typography>
              <Index.Typography className="week-day-text">Thu</Index.Typography>
              <Index.Typography className="week-day-text">Fri</Index.Typography>
              <Index.Typography className="week-day-text">Sat</Index.Typography>
              <Index.Typography className="week-day-text">
                Total
              </Index.Typography>
              <Index.Typography className="week-day-text">
                Total Seconds
              </Index.Typography>
            </Index.Box>
            {values?.weekDays.map((data, index) => {
              return (
                <WeekdayTable
                  key={index}
                  creativeDuration={values?.creativeDuration}
                  weekArray={values?.weekDays}
                  index={index}
                  data={data}
                  weekDays={weekDays}
                  setFieldValue={setFieldValue}
                />
              );
            })}
          </Index.Box>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {values?.type === "date" ? (
        values?.selectedDates?.length ? (
          <Index.Box className="week-main">
            <Index.Box className="week-day-name-flex">
              {values?.selectedDates.map((data, index) => {
                return (
                  <Index.Typography className="week-day-text" key={data?.day}>
                    {data?.day}
                  </Index.Typography>
                );
              })}
            </Index.Box>
            <Index.Box className="week-content-main-flex">
              {values?.selectedDates.map((data, index) => {
                return (
                  <Index.Box
                    className="week-box"
                    key={`${data?.day}-${data?.onlyDate}`}
                  >
                    <Index.Typography className="week-date-text">
                      {data?.onlyDate}
                    </Index.Typography>
                    <Index.Box className="admin-input-box week-input-box">
                      <Index.Box className="admin-form-group">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          className="admin-form-control"
                          placeholder={data?.value === 0 ? "0" : ""}
                          value={data?.value === 0 ? "" : data?.value}
                          disabled={data?.disabled}
                          inputProps={{ maxLength: 3 }}
                          onChange={(e) => {
                            const regex = /^[0-9]*$/;
                            if (regex.test(e.target.value)) {
                              const updatedData = [...values.selectedDates];
                              updatedData[index].value = e.target.value;
                              setFieldValue("selectedDates", updatedData);
                            }
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                );
              })}
            </Index.Box>
          </Index.Box>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {/* <Index.Box className="creative-percentage-wrapper">
        <Index.Box className="admin-sub-title-main">
          <Index.Typography className="admin-sub-title">
            Creative Percentage
          </Index.Typography>
        </Index.Box>
        <Index.Box className="creative-percentage-main">
          <Index.Box className="creative-percentage-box">
            <Index.Box className="creative-percentage-flex">
              <Index.Typography className="creative-percentage-title">
                Creative 1
              </Index.Typography>
              <Index.Box className="admin-input-box creative-percentage-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder=""
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="creative-percentage-box">
            <Index.Box className="creative-percentage-flex">
              <Index.Typography className="creative-percentage-title">
                Creative 2
              </Index.Typography>
              <Index.Box className="admin-input-box creative-percentage-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder=""
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="admin-modal-user-btn-flex border-btn-main">
            <Index.Button className="border-btn">Save</Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box> */}
    </Index.Box>
  );
};
