import React, { useCallback, useEffect, useRef, useState } from "react";
import PagesIndex from "../../../PagesIndex";
import Index from "../../../Index";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import axios from "axios";
import VideoThumbnail from "react-video-thumbnail";

// for custom checkbox design

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

// for modal design

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function AddNews() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const { id } = PagesIndex.useParams();
  const videoRef = useRef();
  const { state } = PagesIndex.useLocation();
  const formRef = useRef();
  const internalVideoRef = useRef();
  const formUploadInternalRef = useRef();
  const [zoneData, setZoneData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [openCalender, setOpenCalender] = useState(false);
  const [sectionData, setSectionData] = useState([]);
  const [validationSchema, setValidationSchema] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [openUploadInternal, setOpenUploadInternal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState("Copy");
  const [userPostData, setUserPostData] = useState([]);
  const navigate = PagesIndex.useNavigate();
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [sheduleButtonSpinner, setSheduleButtonSpinner] = useState(false);
  const [draftButtonSpinner, setDraftButtonSpinner] = useState(false);
  const [updateButtonSpinner, setUpdateButtonSpinner] = useState(false);
  const [fieldChecked, setFieldChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadVideoId, setUploadVideoId] = useState(undefined);
  const [internalServerLink, setInternalServerLink] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState("");

  const getCheckedValue = (id, sectionToShow, label) => {
    if (id) {
      if (sectionToShow?.includes(label)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  let initialValues = {
    vertical: id ? state?.data?.vertical : false,
    newsTitle: id ? state?.data?.newsTitle : "",
    description: id ? state?.data?.description : "",
    youtube_link: id ? state?.data?.youtube_link : "",
    internal_link: id
      ? state?.data?.internal_link
        ? `${internalServerLink}/api/images/${state?.data?.internal_link}`
        : ""
      : "",
    video: id ? state?.data?.s3_link : "",
    tags: id ? state?.data?.tags : "",
    location: id ? state?.data?.location : "",
    latitude: id ? state?.data?.latitude : "",
    longitude: id ? state?.data?.longitude : "",
    category: id ? state?.data?.category : "",
    state: id ? state?.data?.state?._id : "",
    zone: id ? state?.data?.zone?._id : "",
    district: id ? state?.data?.district?._id : "",
    postNo: id ? state?.data?.postId : "",
    city: id ? state?.data?.city : "",
    image: "",
    imageUrl: id ? state?.data?.image : "",
    liveNewsImage: "",
    liveNewsImageUrl: id ? state?.data?.liveNewsImage : "",
    isEdit: !!id,
    date: id
      ? state?.data?.scheduledDate
        ? dayjs(state?.data?.scheduledDate)
        : null
      : null,
    action: "",
    checked: sectionData?.length ? sectionData : [],
    notification: id ? state?.data?.notiFlag : false,
    adsPlay: id ? state?.data?.isAdsPlay?.toString() : "true",
    defaultImage: false,
  };
  const initialValuesUploadInternal = {
    video: "",
    videoUrl: id
      ? state?.data?.internal_link
      : state?.data?.internal_link
      ? state?.data?.internal_link
      : "",
  };
  useEffect(() => {
    handleGetState();
    handleGetTag();
    handleGetCategory();
    handleGetSection();
    handleGetUserPosts();
    getSettingData();
  }, []);
  const handleGetState = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_STATE).then((res) => {
      if (res.status === 200) {
        const activeState = res?.data?.filter((state) => state?.isActive);
        setStateData(activeState);
      }
    });
  };
  const handleGetTag = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_TAG).then((res) => {
      if (res.status === 200) {
        const activeTags = res?.data?.filter((tag) => tag?.isActive);
        setTagData(activeTags);
      }
    });
  };
  const handleGetUserPosts = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ALL_POST_VIDEO_LIST).then(
      (res) => {
        if (res?.status === 200) {
          setUserPostData(res?.data.filter((data) => data?.isAccept));
        } else {
          PagesIndex.toasterError(res.message);
        }
      }
    );
  };
  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res.status === 200) {
        const activeCategory = res?.data?.filter((data) => data?.isActive);
        setCategoryData(activeCategory);
      }
    });
  };
  const handleGetSection = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_HOME_CATEGORY).then((res) => {
      if (res.status === 200) {
        const sectionDataList = res.data
          ?.filter(
            (data) =>
              data?._id !== "660cfd2b2005bb0bea620452" &&
              data?._id !== "660cfd1c2005bb0bea62043e" &&
              data?.isActive
          )
          .map((data) => ({
            _id: data?._id,
            label: data?.name,
            vertical: data?.vertical,
            checked: getCheckedValue(
              id,
              state?.data?.sectionToShow?.map((data) => data?._id),
              data?._id
            ),
          }));
        setSectionData(sectionDataList);
      }
    });
  };
  const handleGetZone = (stateId) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("stateId", stateId);

    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_ZONE_BY_STATE,
      urlEncoded
    ).then((res) => {
      if (res.status === 201 || res.status === 200) {
        const activeTags = res?.data?.filter((zone) => zone?.isActive);
        setZoneData(activeTags);
      }
    });
  };
  const handleGetDistrict = (zoneId) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("zoneId", zoneId);

    PagesIndex.apiPostHandler(
      PagesIndex.Api.GET_DISTRICT_BY_ZONE,
      urlEncoded
    ).then((res) => {
      if (res.status === 201 || res.status === 200) {
        setDistrictData(res.data);
      }
    });
  };

  useEffect(() => {
    if (id) {
      handleGetDistrict(initialValues?.zone);
    }
  }, [initialValues?.zone, id]);

  useEffect(() => {
    const defaultState = stateData?.find(
      (state) => state?.stateName?.toLowerCase() === "gujarat"
    );
    if (defaultState?._id) {
      handleGetZone(defaultState?._id);
    }
  }, [stateData]);

  const handleAddEditNews = (values) => {
    if (values?.action === "Publish") {
      setButtonSpinner(true);
    }
    if (values?.action === "Schedule") {
      setSheduleButtonSpinner(true);
    }
    if (values?.action === "Draft") {
      setDraftButtonSpinner(true);
    }
    if (values?.action === "Update") {
      setUpdateButtonSpinner(true);
    }

    let checkedLength = values?.checked?.filter(
      (checked) => checked?.checked
    )?.length;

    const formdata = new FormData();
    if (id) {
      formdata.append("id", id);
    }
    if (values?.date && values?.action === "Schedule") {
      formdata.append("scheduledDate", values?.date);
    }
    if (values?.image) {
      formdata.append("profile", values?.image);
    }
    if (values?.liveNewsImage) {
      formdata.append("background", values?.liveNewsImage);
    }
    if (values?.defaultImage) {
      formdata.append("uploadFlag", true);
    }
    if (values?.video) {
      formdata.append("s3_link", values?.video);
    }
    if (values?.internal_link) {
      formdata.append(
        "internal_link",
        values?.internal_link.replace(`${internalServerLink}/api/images/`, "")
      );
    }
    if (values?.tags?.length) {
      values?.tags?.forEach((tag, index) => {
        formdata.append(`tags[${index}]`, tag);
      });
    } else {
      formdata.append(`tags`, []);
    }
    if (values?.category?.length) {
      values?.category?.forEach((category, index) => {
        formdata.append(`category[${index}]`, category?._id);
      });
    } else {
      formdata.append(`category`, []);
    }

    if (checkedLength) {
      values?.checked
        ?.filter((checked) => checked?.checked)
        ?.forEach((checked, index) => {
          formdata.append(`sectionToShow[${index}]`, checked?._id);
        });
    } else {
      formdata.append(`sectionToShow`, []);
    }

    if (uploadVideoId) {
      formdata.append("uploadVideoId", uploadVideoId);
    }
    formdata.append("newsTitle", values?.newsTitle);
    formdata.append("description", values?.description);
    formdata.append("youtube_link", values?.youtube_link);
    formdata.append("location", values?.location);
    formdata.append("latitude", values?.latitude);
    formdata.append("longitude", values?.longitude);
    formdata.append("isAdsPlay", values?.adsPlay);
    formdata.append("vertical", values?.vertical);
    if (values?.postNo) {
      formdata.append("postId", values?.postNo);
    }

    if (values?.action === "Publish") {
      formdata.append(
        "notiFlag",
        values?.notification ? values?.notification : false
      );
    }

    if (values?.state) {
      formdata.append("state", values?.state);
    } else {
      formdata.append("state", "");
    }
    if (values?.zone) {
      formdata.append("zone", values?.zone);
    } else {
      formdata.append("zone", "");
    }
    if (values?.district) {
      formdata.append("district", values?.district);
    } else {
      formdata.append("district", "");
    }
    if (values?.city) {
      formdata.append("city", values?.city);
    } else {
      formdata.append("city", "");
    }
    formdata.append(
      "flag",
      values?.action === "Draft"
        ? 0
        : values?.action === "Publish"
        ? 1
        : values?.action === "Schedule"
        ? 2
        : 3
    );

    PagesIndex.apiPostHandler(
      `${PagesIndex.Api.ADD_EDIT_NEWS}${
        values?.action === "Publish" || values?.action === "Schedule"
          ? "?publish=true"
          : ""
      }`,
      formdata
    )
      .then((res) => {
        setButtonSpinner(false);
        setSheduleButtonSpinner(false);
        setDraftButtonSpinner(false);
        setUpdateButtonSpinner(false);
        if (res.status === 201 || res.status === 200) {
          PagesIndex.toasterSuccess(res.message);
          navigate("/admin/videos", {
            state: {
              page:
                values?.action === "Draft"
                  ? 0
                  : values?.action === "Publish"
                  ? 1
                  : values?.action === "Schedule"
                  ? 2
                  : state?.page,
            },
          });
        } else {
          PagesIndex.toasterError(res.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err.response.message);
      });
  };

  // Publish with Schedule  modal
  const [openPublishSchedule, setOpenPublishSchedule] = useState(false);
  const handleOpenPublishSchedule = () => setOpenPublishSchedule(true);
  const handleClosePublishSchedule = (setFieldTouched, setFieldValue) => {
    setFieldTouched("date", false);
    setFieldValue("date", id ? dayjs(state?.data?.scheduledDate) : null);
    setOpenPublishSchedule(false);
    setOpenCalender(false);
  };
  const handleOpenUpload = () => setOpenUpload(true);
  const handleCloseUpload = () => {
    setOpenUpload(false);
  };
  const handleOpenUploadInternal = () => setOpenUploadInternal(true);
  const handleCloseUploadInternal = () => {
    setOpenUploadInternal(false);
  };

  // for img
  const renderImage = (values) => {
    const isYoutubeImg = values?.youtube_link?.includes("watch?")
      ? values?.youtube_link?.split("=")[1]
      : values?.youtube_link?.includes("shorts")
      ? values?.youtube_link?.split("shorts/")[1]
      : values?.youtube_link?.split("/")[3];
    const isUploadImg = values?.imageUrl;
    const isImageBlob = values.image instanceof Blob;
    const imageUrl = values?.imageUrl
      ? uploadedVideo
        ? values?.imageUrl
        : `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.imageUrl}`
      : null;
    let src;

    if (
      (!values?.image && !values?.imageUrl && values?.youtube_link) ||
      values?.defaultImage
    ) {
      if (
        values?.checked?.filter((ele) => ele?.checked == true)?.[0]?.label ==
        "Reels"
      ) {
        src = `https://i.ytimg.com/vi/${isYoutubeImg}/frame0.jpg`;
      } else {
        src = `https://img.youtube.com/vi/${isYoutubeImg}/maxresdefault.jpg`;
      }
    } else {
      if (isImageBlob) {
        src = URL.createObjectURL(values.image);
      } else if (
        values?.youtube_link &&
        values?.youtube_link !== state?.data?.youtube_link
      ) {
        // if (
        //   values?.checked?.filter((ele) => ele?.checked == true)?.[0]?.label ==
        //   "Reels"
        // ) {

        if (values?.vertical == true) {
          src = `https://i.ytimg.com/vi/${isYoutubeImg}/frame0.jpg`;
        } else {
          src = `https://img.youtube.com/vi/${isYoutubeImg}/maxresdefault.jpg`;
        }
      } else if (isUploadImg) {
        src = imageUrl;
      } else {
        src = PagesIndex.Svg.fileupload;
      }
    }
    return (
      <img
        src={src}
        className={
          src !== PagesIndex.Svg.fileupload
            ? "upload-img"
            : "upload-profile-img"
        }
        alt="upload img"
      />
    );
  };
  const renderLiveNewsImage = (values) => {
    const isUploadImg = values?.liveNewsImageUrl;
    const isImageBlob = values.liveNewsImage instanceof Blob;
    const liveNewsImageUrl = values?.liveNewsImageUrl
      ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.liveNewsImageUrl}`
      : null;
    let src;
    if (isImageBlob) {
      src = URL.createObjectURL(values?.liveNewsImage);
    } else if (isUploadImg) {
      src = liveNewsImageUrl;
    } else {
      src = PagesIndex.Svg.fileupload;
    }

    return (
      <img
        src={src}
        className={
          src !== PagesIndex.Svg.fileupload
            ? "upload-img-live"
            : "upload-profile-img"
        }
        alt="upload img"
      />
    );
  };
  const handleUploadVideo = (e, setFieldValue, setFieldTouched) => {
    setUploadedVideo("");
    const formdata = new FormData();
    formdata.append("video", e.target.files[0]);
    PagesIndex.apiPostHandlerVideoUpload(
      PagesIndex.Api.UPLOAD_S3_LINK,
      formdata,
      {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      }
    ).then((res) => {
      setUploadProgress(0);
      if (res?.status === 201 || res?.status === 200) {
        PagesIndex.toasterSuccess(res.message);
        videoRef.current?.load();
        setUploadedVideo(`${process.env.REACT_APP_IMAGE_ENDPOINT}${res.data}`);
        setFieldTouched("video", false);
        setFieldValue("video", res.data);
        setOpenUpload(false);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const renderVideo = (values) => {
    return values.video ? (
      <video
        ref={videoRef}
        className="upload-img"
        loop={true}
        autoPlay="autoplay"
        muted
      >
        <source
          src={`${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.video}`}
          type="video/mp4"
        />
      </video>
    ) : (
      <img
        src={PagesIndex.Svg.fileupload}
        className={"upload-profile-img"}
        alt="upload img"
      />
    );
  };

  const renderVideoInternal = (values) => {
    const isVideoBlob = values.video instanceof Blob;
    const videoUrl = values?.videoUrl
      ? `${internalServerLink}/api/images/${values?.videoUrl}`
      : null;
    let src;
    if (isVideoBlob) {
      src = URL.createObjectURL(values?.video);
    } else {
      src = videoUrl;
    }
    internalVideoRef.current?.load();
    return values.video || values?.videoUrl ? (
      <video
        ref={internalVideoRef}
        className="upload-img"
        loop={true}
        autoPlay="autoplay"
        muted
      >
        <source src={src} type="video/mp4" />
      </video>
    ) : (
      <img
        src={PagesIndex.Svg.fileupload}
        className={"upload-profile-img"}
        alt="upload img"
      />
    );
  };
  const getSettingData = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_SETTING_DATA).then((res) => {
      if (res.status === 200) {
        localStorage.setItem("internalLink", res?.data?.[0]?.internalUrl);
        setInternalServerLink(res?.data?.[0]?.internalUrl);
      }
    });
  };
  const handleAddVideo = (values) => {
    setLoading(true);
    setUploadedVideo("");
    const formData = new FormData();
    formData.append("uploadBy", adminDetails?._id);
    formData.append("isThirtyDeleted", fieldChecked);
    formData.append("video", values?.video);
    axios
      .post(`${internalServerLink}/api/upload-video`, formData)
      .then((res) => {
        setLoading(false);
        formRef.current.setFieldValue("internal_link", res?.data?.data?.video);
        setUploadedVideo(res?.data?.data?.video);
        setUploadVideoId(res?.data?.data?._id);
        handleCloseUploadInternal();
        PagesIndex.toasterSuccess(res?.data?.message);
      })
      .catch((err) => {
        setLoading(false);
        PagesIndex.toasterError(err?.response?.data?.message);
      });
  };
  const renderSheduleModal = (
    setFieldValue,
    handleSubmit,
    touched,
    errors,
    values,
    setFieldTouched
  ) => {
    return (
      <Index.Modal
        open={openPublishSchedule}
        onClose={() => {
          handleClosePublishSchedule(setFieldTouched, setFieldValue);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <form onSubmit={handleSubmit} noValidate="novalidate">
          <Index.Box
            sx={style}
            className="admin-add-user-modal-inner-main admin-modal-inner"
          >
            <Index.Box className="admin-modal-header">
              <Index.Typography
                id="modal-modal-title"
                className="admin-modal-title"
                variant="h6"
                component="h2"
              >
                Publish with schedule
              </Index.Typography>
              <Index.Button
                className="modal-close-btn"
                onClick={() => {
                  handleClosePublishSchedule(setFieldTouched, setFieldValue);
                }}
              >
                <img
                  src={PagesIndex.Svg.closeblack}
                  className="admin-modal-close-icon"
                  alt="Close"
                />
              </Index.Button>
            </Index.Box>
            <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
              <Index.Box className="grid-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="admin-input-box admin-modal-input-box admin-date-input-main">
                        <Index.FormHelperText className="admin-form-lable">
                          <span>Select Date And Time</span>
                          <span className="astrick-sing">*</span>
                        </Index.FormHelperText>
                        <Index.Box className="admin-form-group">
                          <PagesIndex.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <PagesIndex.DateTimePicker
                              open={openCalender}
                              onClose={() => {
                                setOpenCalender(false);
                              }}
                              timeSteps={{ minutes: 1 }}
                              className="admin-form-control admin-date-picker-control"
                              disablePast
                              value={values.date} // Use '01/01/2000' as the default value
                              onChange={(date) => {
                                setFieldValue("date", date);
                              }}
                              slotProps={{
                                popper: {
                                  className: "date-picker-body",
                                },
                                textField: {
                                  readOnly: true,
                                  onClick: () => setOpenCalender(true),
                                  placeholder: "DD/MM/YYYY hh:mm:ss",
                                },
                              }}
                              format="DD/MM/YYYY hh:mm A"
                            />
                          </PagesIndex.LocalizationProvider>
                          <Index.FormHelperText className="admin-error-text">
                            {errors.date && touched.date && errors.date}
                          </Index.FormHelperText>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="modal-footer">
                <Index.Box className="admin-modal-user-btn-flex">
                  <Index.Box className="admin-save-btn-main btn-main-primary">
                    <Index.Button
                      className="admin-save-user-btn btn-primary schedule-btn"
                      type="submit"
                      onClick={() => {
                        if (id && state?.data?.isPublish) {
                          setValidationSchema(
                            PagesIndex?.newsEditSchemaWithShedule
                          );
                        } else if (id && !state?.data?.isPublish) {
                          setValidationSchema(
                            PagesIndex?.newsAddSchemaWithShedule
                          );
                        } else {
                          setValidationSchema(
                            PagesIndex?.newsAddSchemaWithShedule
                          );
                        }
                        setFieldValue("action", "Schedule");
                      }}
                      disabled={
                        buttonSpinner ||
                        sheduleButtonSpinner ||
                        draftButtonSpinner
                      }
                    >
                      {sheduleButtonSpinner ? (
                        <PagesIndex.Spinner />
                      ) : (
                        <>
                          <img
                            src={PagesIndex.Svg.save}
                            className="admin-user-save-icon"
                            alt="Save"
                          />
                          <span>Schedule</span>
                        </>
                      )}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      </Index.Modal>
    );
  };

  const ScrollIntoView = () => {
    // Scroll to the first error when errors change
    const { errors, isSubmitting } = PagesIndex.useFormikContext(); // Extracting errors from the Formik context
    useEffect(() => {
      if (errors && Object.keys(errors).length > 0) {
        const firstErrorField = Object.keys(errors)[0];
        const firstErrorElement = document.querySelector(
          `[id="${firstErrorField}"]`
        );
        if (firstErrorElement) {
          firstErrorElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          firstErrorElement.focus();
        }
      }
    }, [isSubmitting]);
  };
  const VideoThumb = useCallback(() => {
    return (
      <VideoThumbnail
        videoUrl={uploadedVideo}
        thumbnailHandler={(thumbnail) => {
          formRef.current.setFieldValue("imageUrl", thumbnail);
        }}
        renderThumbnail={false}
      />
    );
  }, [uploadedVideo]);
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          {id ? "Edit Video" : "Add Video"}
        </Index.Typography>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize={true}
        onSubmit={handleAddEditNews}
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          setFieldError,
        }) => (
          <>
            <Index.Box className="common-card">
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box className="grid-row">
                  {/* <ScrollIntoView /> */}
                  <Index.Box className="grid-main">
                    <Index.Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                    >
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        {" "}
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.Box className="admin-news-check-main admin-form-lable">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={values?.vertical}
                                onChange={() => {
                                  const newValue = !values.vertical;
                                  setFieldValue("vertical", newValue);

                                  let updatedChecked = values?.checked?.map(
                                    (item, idx) => ({
                                      ...item,
                                      checked: false,
                                    })
                                  );
                                  setFieldValue(`checked`, updatedChecked);
                                  setFieldValue("category", "");
                                }}
                                name="vote"
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Vertical
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        {" "}
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Home Category
                          </Index.FormHelperText>
                          <Index.Box
                            className="admin-news-check-main"
                            id="checked"
                          >
                            {values?.checked?.map((item, index) => {
                              if (item?.vertical === values?.vertical) {
                                return (
                                  <Index.Box
                                    className="admin-checkbox-main"
                                    key={item?._id}
                                  >
                                    <BpCheckbox
                                      checked={item?.checked}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const newValue = isChecked
                                          ? item?._id
                                          : false;
                                        let updatedChecked = values.checked.map(
                                          (item, idx) => ({
                                            ...item,
                                            checked:
                                              idx === index
                                                ? e.target.checked
                                                : item.checked,
                                          })
                                        );
                                        // if (
                                        //   isChecked &&
                                        //   (newValue ===
                                        //     "660c00dd0b2c9ea28bd1df33" ||
                                        //     newValue ===
                                        //     "660c00e40b2c9ea28bd1df3b")
                                        // ) {
                                        //   updatedChecked = updatedChecked.map(
                                        //     (item, idx) => ({
                                        //       ...item,
                                        //       checked:
                                        //         idx === index ? true : false,
                                        //     })
                                        //   );
                                        // } else {
                                        //   updatedChecked = updatedChecked.map(
                                        //     (item, idx) => ({
                                        //       ...item,
                                        //       checked:
                                        //         item?._id ===
                                        //           "660c00dd0b2c9ea28bd1df33" ||
                                        //           item?._id ===
                                        //           "660c00e40b2c9ea28bd1df3b"
                                        //           ? false
                                        //           : item.checked,
                                        //     })
                                        //   );
                                        // }
                                        setFieldValue(
                                          `checked`,
                                          updatedChecked
                                        );
                                      }}
                                      name={`checked[${index}]`}
                                    />
                                    <Index.Typography className="admin-checkbox-lable">
                                      {item?.label}
                                    </Index.Typography>
                                  </Index.Box>
                                );
                              }
                            })}
                          </Index.Box>
                          {touched.checked && errors.checked && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.checked}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Video Title<span className="astrick-sing">*</span>{" "}
                            <span className="option-condition-text">
                              (Max character length: 500)
                            </span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              fullWidth
                              id="newsTitle"
                              className="admin-form-control"
                              placeholder="Enter Video Title"
                              name="newsTitle"
                              onChange={handleChange}
                              value={values?.newsTitle}
                              inputProps={{ maxLength: 500 }}
                            />
                            {touched.newsTitle && errors.newsTitle && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.newsTitle}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Description<span className="astrick-sing">*</span>{" "}
                            <span className="option-condition-text">
                              (Max character length: 1000)
                            </span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextareaAutosize
                              // placeholder="About it."
                              id="description"
                              name="description"
                              style={{ resize: "vertical", overflow: "auto" }}
                              className="admin-form-control-textarea"
                              value={values.description}
                              placeholder="Enter Description"
                              onChange={(e) => {
                                setFieldValue("description", e.target.value);
                              }}
                              maxLength={1000}
                            />
                            {touched.description && errors.description && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.description}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Tags<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.FormControl className="admin-form-control admin-auto-complete-control">
                              <Index.Autocomplete
                                id="tags"
                                className="admin-auto-complete-filed"
                                options={tagData || []}
                                multiple
                                disableCloseOnSelect
                                freeSolo
                                closeText={""}
                                openText={""}
                                clearText={""}
                                getOptionSelected={(option, value) =>
                                  option._id === value._id
                                }
                                value={
                                  values?.tags
                                    ? tagData.filter((tag) =>
                                        values?.tags?.includes(tag._id)
                                      )
                                    : []
                                }
                                onChange={(e, values) => {
                                  if (!e.key) {
                                    setFieldValue(
                                      "tags",
                                      values.map((tag) => tag._id)
                                    );
                                  }
                                }}
                                getOptionLabel={(option) => option?.tag}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.tag}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    name="tags"
                                    fullWidth
                                    className="admin-form-control"
                                    {...params}
                                    size="small"
                                    placeholder={
                                      values?.tags?.length ? "" : "Enter Tag"
                                    }
                                    variant="outlined"
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        event.preventDefault(); // Prevent form submission
                                        const inputValue =
                                          event.target.value.trim();
                                        if (
                                          inputValue &&
                                          !values?.tags?.includes(inputValue)
                                        ) {
                                          setFieldValue("tags", [
                                            ...(values?.tags
                                              ? values.tags
                                              : []),
                                            inputValue,
                                          ]);
                                        }
                                        if (
                                          !tagData.find(
                                            (tag) => tag._id === inputValue
                                          )
                                        ) {
                                          setTagData((prev) => [
                                            ...prev,
                                            {
                                              _id: inputValue,
                                              tag: inputValue,
                                            },
                                          ]);
                                        }
                                        event.target.value = "";
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Index.FormControl>
                            {touched.tags && errors.tags && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.tags}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Categories<span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.FormControl className="admin-form-control admin-auto-complete-control">
                              <Index.Autocomplete
                                id="category"
                                className="admin-auto-complete-filed"
                                options={(
                                  categoryData?.filter(
                                    (ele) => ele?.vertical === values?.vertical
                                  ) || []
                                ).sort((a, b) =>
                                  a?.categoryName?.localeCompare(
                                    b?.categoryName
                                  )
                                )}
                                multiple
                                closeText={""}
                                openText={""}
                                clearText={""}
                                disableCloseOnSelect
                                value={
                                  values?.category
                                    ? categoryData.filter((data) =>
                                        values?.category?.some(
                                          (cat) => cat._id === data._id
                                        )
                                      )
                                    : []
                                }
                                onChange={(e, selectedOptions) => {
                                  setFieldValue(
                                    "category",
                                    selectedOptions.map((option) => ({
                                      _id: option?._id,
                                      categoryName: option?.categoryName,
                                    }))
                                  );
                                }}
                                getOptionLabel={(option) =>
                                  option?.categoryName
                                }
                                // getOptionDisabled={(option) => option?.vertical !== values?.vertical}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.categoryName}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    name="category"
                                    fullWidth
                                    className="admin-form-control"
                                    placeholder={
                                      values?.category?.length
                                        ? ""
                                        : "Select Category"
                                    }
                                    {...params}
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />

                              {touched.category && errors.category && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.category}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.FormControl>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Location
                          </Index.FormHelperText>
                          <PagesIndex.AutoCompaleteLocation
                            initialValues={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                          />
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            City
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              fullWidth
                              id="city"
                              className="admin-form-control"
                              placeholder="Enter City"
                              name="city"
                              onChange={handleChange}
                              value={values?.city}
                            />
                            {touched.city && errors.city && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.city}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            State
                          </Index.FormHelperText>
                          <Index.Box className="admin-dropdown-box">
                            <Index.FormControl className="admin-form-control admin-drop-form-control">
                              <Index.Autocomplete
                                id="state"
                                className="admin-auto-complete-control"
                                options={stateData || []}
                                closeText={""}
                                openText={""}
                                clearText={""}
                                // disableClearable={true}
                                value={
                                  values?.state
                                    ? stateData?.find(
                                        (o) => o._id === values.state
                                      ) ?? null
                                    : null
                                }
                                onChange={(e, value) => {
                                  setFieldValue("state", value?._id);
                                  if (value?._id) {
                                    handleGetZone(value?._id);
                                  }
                                }}
                                getOptionLabel={(option) => option?.stateName}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.stateName}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    name="state"
                                    fullWidth
                                    className="admin-form-control"
                                    {...params}
                                    placeholder="Select State"
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Index.FormControl>
                            {touched.state && errors.state && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.state}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Zone
                          </Index.FormHelperText>
                          <Index.Box className="admin-dropdown-box">
                            <Index.FormControl className="admin-form-control admin-drop-form-control">
                              <Index.Autocomplete
                                id="zone"
                                className="admin-auto-complete-control"
                                options={zoneData || []}
                                // disableClearable={true}
                                closeText={""}
                                openText={""}
                                clearText={""}
                                value={
                                  values?.zone
                                    ? zoneData?.find(
                                        (o) => o._id === values.zone
                                      ) ?? null
                                    : null
                                }
                                onChange={(e, value) => {
                                  setFieldValue("zone", value?._id);
                                  if (value?._id) {
                                    handleGetDistrict(value?._id);
                                  }
                                }}
                                getOptionLabel={(option) => option?.name}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    name="zone"
                                    className="admin-form-control"
                                    {...params}
                                    placeholder="Select Zone"
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Index.FormControl>
                            {touched.zone && errors.zone && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.zone}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 3",
                          lg: "span 3",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            District
                          </Index.FormHelperText>
                          <Index.Box className="admin-dropdown-box">
                            <Index.FormControl className="admin-form-control admin-drop-form-control">
                              <Index.Autocomplete
                                id="district"
                                className="admin-auto-complete-control"
                                options={(districtData || []).sort((a, b) =>
                                  a?.name?.localeCompare(b?.name)
                                )}
                                // disableClearable={true}
                                closeText={""}
                                openText={""}
                                clearText={""}
                                value={
                                  values?.district && districtData
                                    ? districtData.find(
                                        (o) => o._id === values.district
                                      ) || null
                                    : null
                                }
                                onChange={(e, value) => {
                                  setFieldValue("district", value?._id);
                                }}
                                getOptionLabel={(option) => option?.name}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.name}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    fullWidth
                                    name="district"
                                    className="admin-form-control"
                                    {...params}
                                    placeholder="Select District"
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Index.FormControl>
                            {touched.district && errors.district && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.district}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 4",
                          md: "span 6",
                          lg: "span 12",
                        }}
                        className="grid-column video-link-colum"
                      >
                        <Index.Box className="admin-sub-title-main admin-input-box">
                          <Index.Typography className="admin-sub-title">
                            Video Link
                            <span className="admin-error-text link-errors-text">
                              {((touched.youtube_link && errors.youtube_link) ||
                                (touched.video && errors.video) ||
                                (touched.internal_link &&
                                  errors.internal_link)) &&
                                "(only one link is required)"}
                            </span>
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 4",
                          md: "span 6",
                          lg: "span 5",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>Youtube Link</span>
                            {/* <span className="admin-span-text">(YouTube)</span> */}
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.TextField
                              fullWidth
                              id="youtube_link"
                              className="admin-form-control"
                              placeholder="Enter Youtube Link"
                              name="youtube_link"
                              onChange={(e) => {
                                setFieldValue("image", "");
                                if (e.target.value) {
                                  setFieldValue("defaultImage", true);
                                  setFieldValue("youtube_link", e.target.value);
                                } else {
                                  setFieldValue("youtube_link", "");
                                  setFieldValue("defaultImage", false);
                                }
                              }}
                              value={values?.youtube_link}
                            />
                            {values?.youtube_link &&
                              touched.youtube_link &&
                              errors.youtube_link && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.youtube_link}
                                </PagesIndex.FormHelperText>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 4",
                          md: "span 4",
                          lg: "span 5",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>Upload Video in AWS</span>
                            {/* <span className="admin-span-text">(Upload)</span> */}
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.OutlinedInput
                              fullWidth
                              id="video"
                              className="admin-form-control admin-form-control-eye"
                              placeholder="Upload Video Link"
                              name="video"
                              disabled
                              value={
                                values?.video
                                  ? `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.video}`
                                  : ""
                              }
                              endAdornment={
                                values?.video ? (
                                  <Index.InputAdornment position="end">
                                    <Index.Tooltip
                                      title={message}
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                      onMouseLeave={() => {
                                        setTimeout(() => {
                                          setMessage("Copy");
                                        }, 1000);
                                      }}
                                    >
                                      <Index.Button
                                        className="copy-btn-main"
                                        onClick={() => {
                                          setMessage("Copied ✓");
                                          navigator.clipboard.writeText(
                                            `${process.env.REACT_APP_IMAGE_ENDPOINT}${values?.video}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.copyicon}
                                          className="copy-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                    <Index.Tooltip
                                      title="Delete"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="copy-btn-main"
                                        onClick={() => {
                                          setFieldValue("video", "");
                                          setUploadVideoId(undefined);
                                          if (!id) {
                                            setFieldValue("imageUrl", "");
                                          }
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.closeBold}
                                          className="admin-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.InputAdornment>
                                ) : (
                                  ""
                                )
                              }
                            />
                            {values?.video && touched.video && errors.video && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.video}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 2",
                          lg: "span 2",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                          <Index.Box className="btn-main-secondary">
                            <Index.Button
                              className="btn-secondary"
                              onClick={handleOpenUpload}
                            >
                              Upload Video
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            <span>Internal Server Link</span>
                            {/* <span className="option-condition-text">
                              (Base Url: {internalServerLink})
                            </span> */}
                          </Index.FormHelperText>
                          <Index.Box className="admin-form-group">
                            <Index.OutlinedInput
                              fullWidth
                              id="internal_link"
                              className="admin-form-control admin-form-control-eye"
                              placeholder="Enter Internal Server Link"
                              name="internal_link"
                              onChange={(e) => {
                                handleChange(e);
                                setUploadedVideo(e.target.value);
                              }}
                              value={values?.internal_link}
                              endAdornment={
                                values?.internal_link ? (
                                  <Index.InputAdornment position="end">
                                    <Index.Tooltip
                                      title={message}
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                      onMouseLeave={() => {
                                        setTimeout(() => {
                                          setMessage("Copy");
                                        }, 1000);
                                      }}
                                    >
                                      <Index.Button
                                        className="copy-btn-main"
                                        onClick={() => {
                                          setMessage("Copied ✓");
                                          navigator.clipboard.writeText(
                                            values?.internal_link
                                          );
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.copyicon}
                                          className="copy-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                    <Index.Tooltip
                                      title="Delete"
                                      arrow
                                      placement="bottom"
                                      className="admin-tooltip"
                                    >
                                      <Index.Button
                                        className="copy-btn-main"
                                        onClick={() => {
                                          setFieldValue("internal_link", "");
                                          setUploadVideoId(undefined);
                                          if (!id) {
                                            setFieldValue("imageUrl", "");
                                          }
                                        }}
                                      >
                                        <img
                                          src={PagesIndex.Svg.closeBold}
                                          className="admin-icon"
                                          alt="copy"
                                        />
                                      </Index.Button>
                                    </Index.Tooltip>
                                  </Index.InputAdornment>
                                ) : (
                                  ""
                                )
                              }
                            />
                            {values?.internal_link &&
                              touched.internal_link &&
                              errors.internal_link && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.internal_link}
                                </PagesIndex.FormHelperText>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 3",
                          md: "span 2",
                          lg: "span 2",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                          <Index.Box className="btn-main-secondary">
                            <Index.Button
                              className="btn-secondary"
                              onClick={handleOpenUploadInternal}
                            >
                              Upload Video
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 4",
                          md: "span 4",
                          lg: "span 4",
                        }}
                        className="grid-column"
                      ></Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Thumbnail<span className="astrick-sing">*</span>
                            <span className="option-condition-text">
                              {values?.vertical === true
                                ? "(Image must be 270px(Width) * 480px(Height))"
                                : "(Image must be 1280px(Width) * 720px(Height))"}
                              {/* {values?.checked?.filter(
                                (ele) => ele?.checked == true
                              )?.[0]?.label == "Reels"
                                ? "(Image must be 270px(Width) * 480px(Height))"
                                : "(Image must be 1280px(Width) * 720px(Height))"} */}
                            </span>
                            {values?.youtube_link && (
                              <Index.Button
                                className="default-img-btn"
                                onClick={() => {
                                  setFieldValue("defaultImage", true);
                                  setFieldValue("image", "");
                                }}
                              >
                                YouTube thumbnail
                              </Index.Button>
                            )}
                          </Index.FormHelperText>

                          {values?.imageUrl ||
                          values?.image ||
                          values?.youtube_link ? (
                            <Index.Box
                              className={`file-upload-main thumbnail-box admin-file-browse-btn-main ${
                                values?.vertical === true
                                  ? "thumbnail-box-reel"
                                  : ""
                              }`}
                              // className={`file-upload-main thumbnail-box admin-file-browse-btn-main ${values?.checked?.filter(
                              //   (ele) => ele?.checked == true
                              // )?.[0]?.label == "Reels"
                              //   ? "thumbnail-box-reel"
                              //   : ""
                              //   }`}
                            >
                              <Index.Box className="upload-news-img-box">
                                {renderImage(values)}
                              </Index.Box>
                              <Index.Button
                                variant="contained"
                                component="label"
                                className={`admin-file-browse-btn ${
                                  values?.vertical === true
                                    ? "admin-file-browse-btn-reel"
                                    : ""
                                }`}
                                // className={`admin-file-browse-btn ${values?.checked?.filter(
                                //   (ele) => ele?.checked == true
                                // )?.[0]?.label == "Reels"
                                //   ? "admin-file-browse-btn-reel"
                                //   : ""
                                //   }`}
                                onClick={() => {
                                  setFieldValue("defaultImage", false);
                                }}
                              >
                                <img
                                  src={PagesIndex.Svg.whiteedit}
                                  className="admin-browse-icon-img"
                                  alt="upload img"
                                ></img>
                                <input
                                  hidden
                                  accept="image/*"
                                  id="image"
                                  name="image"
                                  type="file"
                                  onChange={(e) => {
                                    if (e.target.files?.length !== 0) {
                                      setFieldValue("image", e.target.files[0]);
                                    }
                                  }}
                                />
                              </Index.Button>
                            </Index.Box>
                          ) : (
                            <Index.Box
                              className={`file-upload-main thumbnail-box ${
                                values?.vertical === true
                                  ? "thumbnail-box-reel"
                                  : ""
                              }`}
                              // className={`file-upload-main thumbnail-box ${values?.checked?.filter(
                              //   (ele) => ele?.checked == true
                              // )?.[0]?.label == "Reels"
                              //   ? "thumbnail-box-reel"
                              //   : ""
                              //   }`}
                            >
                              <Index.Button
                                variant="contained"
                                component="label"
                                className={`file-upload-button ${
                                  values?.vertical === true
                                    ? "file-upload-button-reel"
                                    : ""
                                }`}
                                // className={`file-upload-button ${values?.checked?.filter(
                                //   (ele) => ele?.checked == true
                                // )?.[0]?.label == "Reels"
                                //   ? "file-upload-button-reel"
                                //   : ""
                                //   }`}
                              >
                                {renderImage(values)}
                                <input
                                  hidden
                                  accept="image/*"
                                  id="image"
                                  name="image"
                                  type="file"
                                  onChange={(e) => {
                                    if (e.target.files?.length !== 0) {
                                      setFieldValue("image", e.target.files[0]);
                                    }
                                  }}
                                />
                              </Index.Button>
                            </Index.Box>
                          )}
                          {touched.image && errors.image && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.image}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>

                      {values?.checked
                        ?.filter((e) => e.checked == true)?.[0]
                        ?.label?.includes("Live") && (
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 6",
                            md: "span 6",
                            lg: "span 6",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Live News Image
                              <span className="astrick-sing">
                                *{" "}
                                <span className="option-condition-text">
                                  Image must be 512px(Width) * 512px(Height)
                                </span>{" "}
                              </span>
                            </Index.FormHelperText>
                            {values?.liveNewsImage ||
                            values?.liveNewsImageUrl ? (
                              <Index.Box className="file-upload-main thumbnail-box-live admin-file-browse-btn-main admin-file-browse-btn-main-live">
                                <Index.Box className="upload-news-img-box upload-news-img-box-live">
                                  {renderLiveNewsImage(values)}
                                </Index.Box>
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="admin-file-browse-btn"
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteedit}
                                    className="admin-browse-icon-img"
                                    alt="upload img"
                                  ></img>
                                  <input
                                    hidden
                                    accept="image/*"
                                    id="liveNewsImage"
                                    name="liveNewsImage"
                                    type="file"
                                    onChange={(e) => {
                                      if (e.target.files?.length !== 0) {
                                        setFieldValue(
                                          "liveNewsImage",
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </Index.Button>
                              </Index.Box>
                            ) : (
                              <Index.Box className="file-upload-main thumbnail-box-live">
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="file-upload-button"
                                >
                                  {renderLiveNewsImage(values)}
                                  <input
                                    hidden
                                    accept="image/*"
                                    id="liveNewsImage"
                                    name="liveNewsImage"
                                    type="file"
                                    onChange={(e) => {
                                      if (e.target.files?.length !== 0) {
                                        setFieldValue(
                                          "liveNewsImage",
                                          e.target.files[0]
                                        );
                                      }
                                    }}
                                  />
                                </Index.Button>
                              </Index.Box>
                            )}
                            {touched.liveNewsImage && errors.liveNewsImage && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.liveNewsImage}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      )}
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 12",
                          md: "span 12",
                          lg: "span 12",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.Box className="admin-news-check-main admin-form-lable">
                            <Index.Box className="admin-checkbox-main">
                              <BpCheckbox
                                checked={values?.notification}
                                onChange={() => {
                                  const newValue = !values.notification;
                                  setFieldValue("notification", newValue);
                                }}
                                name="notification"
                              />
                              <Index.Typography className="admin-checkbox-lable">
                                Notification
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        {" "}
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Advertise Play
                            <span className="astrick-sing">*</span>
                          </Index.FormHelperText>
                          <Index.Box className="admin-input-box admin-add-event-input-box">
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Select
                                  className="admin-dropdown-select admin-drop-select"
                                  name="adsPlay"
                                  id="adsPlay"
                                  value={values?.adsPlay}
                                  onChange={handleChange}
                                  displayEmpty
                                  inputProps={{
                                    "aria-label": "Without label",
                                  }}
                                  renderValue={
                                    values?.adsPlay
                                      ? undefined
                                      : () => "Select Advertise Play"
                                  }
                                >
                                  <Index.MenuItem
                                    value={"true"}
                                    className="admin-drop-menuitem"
                                  >
                                    Yes
                                  </Index.MenuItem>
                                  <Index.MenuItem
                                    value={"false"}
                                    className="admin-drop-menuitem"
                                  >
                                    No
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.FormControl>
                            </Index.Box>
                            {touched.adsPlay && errors.adsPlay && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.adsPlay}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box
                        gridColumn={{
                          xs: "span 12",
                          sm: "span 6",
                          md: "span 6",
                          lg: "span 6",
                        }}
                        className="grid-column"
                      >
                        <Index.Box className="admin-input-box admin-add-news-input-box">
                          <Index.FormHelperText className="admin-form-lable">
                            Select User Uploaded Video
                          </Index.FormHelperText>
                          <Index.Box className="admin-dropdown-box">
                            <Index.FormControl className="admin-form-control admin-drop-form-control">
                              <Index.Autocomplete
                                id="postNo"
                                className="admin-auto-complete-control"
                                options={userPostData || []}
                                closeText={""}
                                openText={""}
                                clearText={""}
                                // disableClearable={true}
                                value={
                                  values?.postNo
                                    ? userPostData?.find(
                                        (o) => o._id === values.postNo
                                      ) ?? null
                                    : null
                                }
                                onChange={(e, value) => {
                                  setFieldValue("postNo", value?._id);
                                }}
                                getOptionLabel={(option) => option?.postNo}
                                renderOption={(props, item) => (
                                  <li {...props} key={item?._id}>
                                    {item?.postNo}
                                  </li>
                                )}
                                renderInput={(params) => (
                                  <Index.TextField
                                    name="postNo"
                                    fullWidth
                                    className="admin-form-control"
                                    {...params}
                                    placeholder="Select User Uploaded Video"
                                    size="small"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Index.FormControl>
                            {touched.postNo && errors.postNo && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.postNo}
                              </PagesIndex.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>

                <Index.Box className="admin-user-btn-flex add-news-btn-flex">
                  <Index.Box className="border-btn-main">
                    <Index.Button
                      className="border-btn"
                      onClick={() => {
                        navigate("/admin/videos", {
                          state: {
                            page:
                              values?.action === "Draft"
                                ? 0
                                : values?.action === "Publish"
                                ? 1
                                : values?.action === "Schedule"
                                ? 2
                                : state?.page,
                          },
                        });
                      }}
                      disabled={
                        buttonSpinner ||
                        sheduleButtonSpinner ||
                        draftButtonSpinner ||
                        updateButtonSpinner
                      }
                    >
                      Discard
                    </Index.Button>
                  </Index.Box>
                  <Index.Box className="btn-main-primary">
                    {id ? (
                      <Index.Button
                        className="btn-primary"
                        type="submit"
                        onClick={() => {
                          if (!id && !state?.data?.isPublish) {
                            setValidationSchema(PagesIndex?.newsAddDraftSchema);
                          } else if (id && !state?.data?.isPublish) {
                            setValidationSchema(PagesIndex?.newsAddDraftSchema);
                          } else {
                            setValidationSchema(PagesIndex?.newsEditSchema);
                          }
                          setFieldValue("action", "Update");
                        }}
                        disabled={
                          buttonSpinner ||
                          sheduleButtonSpinner ||
                          draftButtonSpinner ||
                          updateButtonSpinner
                        }
                      >
                        {updateButtonSpinner ? (
                          <PagesIndex.Spinner />
                        ) : (
                          "Update"
                        )}
                      </Index.Button>
                    ) : (
                      <Index.Box className="border-btn-main">
                        <Index.Button
                          className="border-btn save-draft-btn"
                          type="submit"
                          onClick={() => {
                            if (!id) {
                              setValidationSchema(
                                PagesIndex?.newsAddDraftSchema
                              );
                            }
                            setFieldValue("action", "Draft");
                          }}
                          disabled={
                            buttonSpinner ||
                            sheduleButtonSpinner ||
                            draftButtonSpinner
                          }
                        >
                          {draftButtonSpinner ? (
                            <PagesIndex.Spinner />
                          ) : (
                            "Save As Draft"
                          )}
                        </Index.Button>
                      </Index.Box>
                    )}
                  </Index.Box>
                  {state?.page !== 1 && (
                    <Index.Box className="btn-main-secondary">
                      <Index.Button
                        className="btn-secondary"
                        type="submit"
                        onClick={() => {
                          if (id && state?.data?.isPublish) {
                            setValidationSchema(PagesIndex?.newsEditSchema);
                          } else {
                            setValidationSchema(PagesIndex?.newsAddSchema);
                          }
                          setFieldValue("action", "Publish");
                        }}
                        disabled={
                          buttonSpinner ||
                          sheduleButtonSpinner ||
                          draftButtonSpinner
                        }
                      >
                        {buttonSpinner ? <PagesIndex.Spinner /> : "Publish"}
                      </Index.Button>
                    </Index.Box>
                  )}
                  {(!id || !state?.data?.isPublish || state?.page === 2) && (
                    <Index.Box className="btn-main-primary">
                      <Index.Button
                        className="btn-primary"
                        onClick={handleOpenPublishSchedule}
                        disabled={
                          buttonSpinner ||
                          sheduleButtonSpinner ||
                          draftButtonSpinner
                        }
                      >
                        {"Publish with Schedule"}
                      </Index.Button>
                    </Index.Box>
                  )}
                </Index.Box>
              </form>
            </Index.Box>

            {renderSheduleModal(
              setFieldValue,
              handleSubmit,
              touched,
              errors,
              values,
              setFieldTouched
            )}
            <Index.Modal
              open={openUpload}
              onClose={() => {
                handleCloseUpload();
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="admin-modal"
            >
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner upload-video-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Upload
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={() => {
                      handleCloseUpload();
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box admin-modal-input-box">
                            {values?.video ? (
                              <Index.Box className="file-upload-main  admin-file-browse-btn-main upload-video-input-box">
                                <Index.Box className="upload-news-img-box">
                                  {uploadProgress > 0 &&
                                  uploadProgress <= 100 ? (
                                    <Index.Box
                                      sx={{
                                        position: "relative",
                                        display: "inline-flex",
                                      }}
                                    >
                                      <Index.CircularProgress
                                        variant="determinate"
                                        value={uploadProgress}
                                      />
                                      <Index.Box
                                        sx={{
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          position: "absolute",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Index.Typography
                                          variant="caption"
                                          component="div"
                                          color="text.secondary"
                                        >{`${Math.round(
                                          uploadProgress
                                        )}%`}</Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  ) : (
                                    renderVideo(values)
                                  )}
                                </Index.Box>
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="admin-file-browse-btn"
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteedit}
                                    className="admin-browse-icon-img"
                                    alt="upload img"
                                  ></img>
                                  <input
                                    hidden
                                    accept="video/*"
                                    name="video"
                                    type="file"
                                    onChange={(e) => {
                                      handleUploadVideo(
                                        e,
                                        setFieldValue,
                                        setFieldTouched
                                      );
                                    }}
                                  />
                                </Index.Button>
                              </Index.Box>
                            ) : (
                              <Index.Box className="file-upload-main admin-file-browse-btn-main upload-video-input-box">
                                <Index.Box className="upload-news-img-box">
                                  {uploadProgress > 0 &&
                                  uploadProgress <= 100 ? (
                                    <Index.Box
                                      sx={{
                                        position: "relative",
                                        display: "inline-flex",
                                      }}
                                    >
                                      <Index.CircularProgress
                                        variant="determinate"
                                        value={uploadProgress}
                                      />
                                      <Index.Box
                                        sx={{
                                          top: 0,
                                          left: 0,
                                          bottom: 0,
                                          right: 0,
                                          position: "absolute",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Index.Typography
                                          variant="caption"
                                          component="div"
                                          color="text.secondary"
                                        >{`${Math.round(
                                          uploadProgress
                                        )}%`}</Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  ) : (
                                    <Index.Button
                                      variant="contained"
                                      component="label"
                                      className="file-upload-button video-upload-btn"
                                    >
                                      {renderVideo(values)}
                                      <input
                                        hidden
                                        accept="video/*"
                                        name="video"
                                        type="file"
                                        onChange={(e) => {
                                          handleUploadVideo(
                                            e,
                                            setFieldValue,
                                            setFieldTouched
                                          );
                                        }}
                                      />
                                    </Index.Button>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            )}
                            <p className="option-condition-text text-center">
                              Video must be Mp4 type
                            </p>
                            <Index.Box className="admin-user-btn-flex add-news-btn-flex">
                              <Index.Box className="btn-main-primary">
                                <Index.Button
                                  className="btn-primary"
                                  onClick={handleCloseUpload}
                                >
                                  Cancel
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Modal>
          </>
        )}
      </PagesIndex.Formik>
      <Index.Modal
        open={openUploadInternal}
        onClose={() => {
          handleCloseUploadInternal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-add-user-modal-inner-main admin-modal-inner upload-video-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              Upload
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={() => {
                handleCloseUploadInternal();
              }}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <PagesIndex.Formik
            enableReinitialize={true}
            onSubmit={handleAddVideo}
            initialValues={initialValuesUploadInternal}
            validationSchema={PagesIndex.uploadInternalVideoSchema}
            innerRef={formUploadInternalRef}
          >
            {({ values, errors, touched, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} noValidate="novalidate">
                <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 12",
                            md: "span 12",
                            lg: "span 12",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box admin-modal-input-box">
                            {values?.video || values?.videoUrl ? (
                              <Index.Box className="file-upload-main  admin-file-browse-btn-main upload-video-input-box">
                                <Index.Box className="upload-news-img-box">
                                  {renderVideoInternal(values)}
                                </Index.Box>
                                <Index.Button
                                  variant="contained"
                                  component="label"
                                  className="admin-file-browse-btn"
                                >
                                  <img
                                    src={PagesIndex.Svg.whiteedit}
                                    className="admin-browse-icon-img"
                                    alt="upload img"
                                  ></img>
                                  <input
                                    hidden
                                    accept="video/*"
                                    name="video"
                                    type="file"
                                    onChange={(e) => {
                                      setFieldValue("video", e.target.files[0]);
                                    }}
                                  />
                                </Index.Button>
                              </Index.Box>
                            ) : (
                              <Index.Box className="file-upload-main admin-file-browse-btn-main upload-video-input-box">
                                <Index.Box className="upload-news-img-box">
                                  <Index.Button
                                    variant="contained"
                                    component="label"
                                    className="file-upload-button video-upload-btn"
                                  >
                                    {renderVideoInternal(values)}
                                    <input
                                      hidden
                                      accept="video/*"
                                      name="video"
                                      type="file"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "video",
                                          e.target.files[0]
                                        );
                                        setFieldValue(
                                          "videoUrl",
                                          URL.createObjectURL(e.target.files[0])
                                        );
                                      }}
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Box>
                            )}
                            <p className="option-condition-text text-center">
                              Video must be Mp4 type
                            </p>
                            {touched.video && errors.video && (
                              <PagesIndex.FormHelperText className="admin-error-text">
                                {errors.video}
                              </PagesIndex.FormHelperText>
                            )}

                            {values?.video && (
                              <Index.Box className="admin-checkbox-main">
                                <BpCheckbox
                                  checked={fieldChecked}
                                  onChange={(e) =>
                                    setFieldChecked(e.target.checked)
                                  }
                                  name="checked"
                                />
                                <Index.Typography className="admin-checkbox-lable">
                                  Delete after 30 days
                                </Index.Typography>
                              </Index.Box>
                            )}
                            <Index.Box className="admin-user-btn-flex add-news-btn-flex">
                              <Index.Box className="btn-main-secondary">
                                <Index.Button
                                  className="btn-secondary"
                                  onClick={handleCloseUploadInternal}
                                >
                                  Cancel
                                </Index.Button>
                              </Index.Box>
                              {values?.video && (
                                <Index.Box className="btn-main-primary">
                                  <Index.Button
                                    className="btn-primary"
                                    type="submit"
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <PagesIndex.Spinner />
                                    ) : (
                                      "Submit"
                                    )}
                                  </Index.Button>
                                </Index.Box>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </PagesIndex.Formik>
        </Index.Box>
      </Index.Modal>
      <VideoThumb />
    </Index.Box>
  );
}
