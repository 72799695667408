import React, { useEffect, useRef, useState } from "react";
import Index from "../../Index";
import PagesIndex from "../../PagesIndex";
import "./defaultLayout.css";
import "./defaultLayout.responsive.css";
import PropTypes from "prop-types";
import { messaging } from "../../../utils/firebase/firebase";
import { onMessage } from "firebase/messaging";
import DataService from "../../../config/DataService";

export default function Header(props) {
  // for page redirect
  const { adminDetails, token } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  DataService.defaults.headers.common.auth = token
  const dispatch = PagesIndex.useDispatch();
  const navigate = PagesIndex.useNavigate();
  // for open in header profile menu
  const [notificationList, setNotificationList] = useState([]);
  const notifRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const open = Boolean(anchorEl);
  const openNotification = Boolean(anchorEl2);
  useEffect(() => {
    onMessage(messaging, (payload) => {
      PagesIndex.toasterInfo(payload.data);
      handleGetNotification();
    });
  });
  useEffect(() => {
    handleGetProfile();
    handleGetNotification();
  }, []);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickNotification = () => {
    setAnchorEl2(true);
    handleGetNotification();
  };
  const handleCloseNotification = () => {
    setAnchorEl2(false);
  };
  const handleClickOutside = (e) => {
    if (notifRef.current && !notifRef.current?.contains(e.target)) {
      handleCloseNotification();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const handleGetProfile = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.PROFILE).then((res) => {
      if (res.status === 200) {
        dispatch(PagesIndex.AdminLogInAction(res.data));
      } else if (res.status === 401) {
        dispatch(PagesIndex.AdminLogOutAction());
        navigate("/", { replace: true });
        PagesIndex.toasterError(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleGetNotification = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.NOTIFICATION_LIST).then((res) => {
      if (res.status === 200) {
        setNotificationList(res?.data);
        setUnreadNotificationCount(
          res?.data?.filter((data) => !data?.isRead)?.length
        );
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleReadNotification = (id) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.NOTIFICATION_READ,
      urlEncoded
    ).then((res) => {
      if (res.status === 200) {
        handleGetNotification();
        navigate("/admin/user-posted-videos");
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  const handleLogOut = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.USER_LOGOUT).then((res) => {
      if (res.status === 200) {
        navigate("/");
        dispatch(PagesIndex.AdminLogOutAction());
        handleClose();
        localStorage.removeItem("internalLink");
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };

  let periods = {
    month: 30 * 24 * 60 * 60 * 1000,
    week: 7 * 24 * 60 * 60 * 1000,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
  };
  const formatTime = (timeCreated) => {
    let IST = PagesIndex.moment.utc(timeCreated).local();
    //@ts-ignore
    let diff = Date.now() - PagesIndex.moment(IST)?.format("x");
    if (diff > periods.day) {
      if (Math.floor(diff / periods.day) == 1) {
        return Math.floor(diff / periods.day) + " day ago";
      } else {
        return Math.floor(diff / periods.day) + " days ago";
      }
    } else if (diff > periods.hour) {
      if (Math.floor(diff / periods.hour) == 1) {
        return Math.floor(diff / periods.hour) + " hour ago";
      } else {
        return Math.floor(diff / periods.hour) + " hours ago";
      }
    } else if (diff > periods.minute) {
      if (Math.floor(diff / periods.minute) == 1) {
        return Math.floor(diff / periods.minute) + " min ago";
      } else {
        return Math.floor(diff / periods.minute) + " mins ago";
      }
    } else if (diff > periods.week) {
      if (Math.floor(diff / periods.week) == 1) {
        return Math.floor(diff / periods.week) + " week ago";
      } else {
        return Math.floor(diff / periods.week) + " weeks ago";
      }
    } else {
      return "Few seconds ago";
    }
  };
  return (
    <Index.Box
      className={`admin-header-main ${props.open ? "active" : "admin-header-deactive"
        }`}
    >
      <Index.Box className="admin-header-left">
        <Index.Box className="admin-header-logo-main">
          <img
            src={PagesIndex.Gif.logoGif}
            className="admin-sidebar-logo"
            alt="logo"
          />
          <Index.Button
            className="admin-bergur-button"
            onClick={() => {
              props.setOpen(!props.open);
              document.body.classList[props.open ? "remove" : "add"](
                "admin-body-overflow"
              );
            }}
          >
            <img
              src={PagesIndex.Svg.bergurmenu}
              className="bergurmenu-icon"
              alt="dashboard logo"
            ></img>
          </Index.Button>
        </Index.Box>
        <Index.Box className="admin-header-right">
          <Index.Box className="admin-header-drop-main" ref={notifRef}>
            <Index.Button
              className="admin-header-icon-box"
              id="basic-button"
              aria-controls={openNotification ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openNotification ? "true" : undefined}
              onClick={handleClickNotification}
            >
              <Index.Box className="admin-flex-drop-main">
                <img
                  src={PagesIndex.Svg.notification}
                  className="admin-header-icon"
                  alt="dashboard bell icon"
                ></img>
              </Index.Box>
              {unreadNotificationCount ? (
                <Index.Box className="admin-noti-badge-main">
                  <Index.Typography className="admin-noti-badge-text">
                    {unreadNotificationCount}
                  </Index.Typography>
                </Index.Box>
              ) : (
                <></>
              )}
            </Index.Button>
          </Index.Box>
          <Index.Menu
            className="admin-noti-drop-menu"
            id="basic-menu"
            open={openNotification}
            onClose={handleCloseNotification}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {notificationList?.map((data) => {
              return (
                <Index.MenuItem
                  className="admin-drop-header-menuitem"
                  key={data?._id}
                  onClick={() => {
                    handleReadNotification(data?._id);
                  }}
                >
                  <Index.Box className="noti-content-flex">
                    <Index.Box className="noti-img-flex">
                      <Index.Box className="noti-content-box">
                        <Index.Box className="noti-img-box">
                          <img
                            src={PagesIndex.Svg.notification}
                            className="noti-icons"
                            alt="Notification"
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="noti-content-box">
                        <Index.Typography className="noti-content-title">
                          A user has uploaded a video.
                        </Index.Typography>
                        <Index.Typography className="noti-content-by-text">
                          Uploaded by{" "}
                          <Index.Tooltip
                            title={
                              data?.objectId?.userId?.firstName &&
                                data?.objectId?.userId?.lastName
                                ? `${data?.objectId?.userId?.firstName} ${data?.objectId?.userId?.lastName}`
                                : data?.objectId?.userId?.mobile ??
                                data?.objectId?.userId?.userName
                            }
                            arrow
                            placement="top"
                            className="admin-tooltip"
                          >
                            <span>
                              {data?.objectId?.userId?.firstName &&
                                data?.objectId?.userId?.lastName
                                ? `${data?.objectId?.userId?.firstName} ${data?.objectId?.userId?.lastName}`
                                : data?.objectId?.userId?.mobile ??
                                data?.objectId?.userId?.userName}
                            </span>
                          </Index.Tooltip>
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="noti-content-box">
                      <Index.Typography className="noti-time-text">
                        {PagesIndex.moment(data?.createdAt)
                          .utcOffset("+05:30")
                          .format("hh:mm A")}
                      </Index.Typography>
                      <Index.Typography className="noti-time-ago-text">
                        {formatTime(data?.createdAt)}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.MenuItem>
              );
            })}
          </Index.Menu>
          {adminDetails?.roleId?.permissions?.includes("site_setting_view") && (
            <Index.Button
              className="admin-header-icon-box"
              onClick={() => {
                navigate("/admin/site-settings");
                handleClose();
              }}
            >
              <img
                src={PagesIndex.Svg.settings}
                className="admin-header-icon"
                alt="dashboard bell icon"
              ></img>
            </Index.Button>
          )}
          <Index.Box className="admin-header-drop-main">
            <Index.Button
              className="admin-drop-header-btn"
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Index.Box className="admin-flex-drop-main">
                <img
                  src={
                    adminDetails?.profile
                      ? process.env.REACT_APP_IMAGE_ENDPOINT +
                      adminDetails?.profile
                      : PagesIndex.Png.usericon
                  }
                  className="admin-header-profile-icon"
                  alt="dashboard bell icon"
                ></img>
                <Index.Box className="title-admin-drop">
                  <Index.Typography
                    variant="h5"
                    component="h5"
                    className="admin-header-drop"
                  >
                    {adminDetails?.name}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Button>
          </Index.Box>
          <Index.Menu
            className="admin-drop-header-menu"
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Index.MenuItem
              className="admin-drop-header-menuitem"
              onClick={() => {
                navigate("/admin/account");
                handleClose();
              }}
            >
              {" "}
              <img
                src={PagesIndex.Svg.profilegrey}
                className="admin-drop-header-icon admin-icon"
                alt="Profile"
              />{" "}
              Profile
            </Index.MenuItem>

            <Index.MenuItem
              className="admin-drop-header-menuitem"
              onClick={handleLogOut}
            >
              {" "}
              <img
                src={PagesIndex.Svg.logout}
                className="admin-drop-header-icon admin-icon"
                alt="Logout"
              />{" "}
              Sign Out
            </Index.MenuItem>
          </Index.Menu>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
Header.propTypes = {
  open: PropTypes.bool.isRequired, // Assuming open is a required boolean prop
  setOpen: PropTypes.func.isRequired, // Assuming setOpen is a required function prop
};
