import React from "react";
import Index from "../../../Index";

function WeekdayTable(props) {
  const { weekArray, index, data, setFieldValue, weekDays, creativeDuration } =
    props;
  const handleValueChange = (dateIndex, newValue) => {
    const updatedWeeks = [...weekArray];
    updatedWeeks[index].dates[dateIndex].value = parseInt(newValue) || 0;
    const newTotal = updatedWeeks[index].dates.reduce(
      (acc, curr) => acc + (curr.disabled ? 0 : curr.value),
      0
    );
    updatedWeeks[index].total = newTotal;
    setFieldValue(weekDays, updatedWeeks);
  };
  return (
    <Index.Box className="week-main-wapper">
      <Index.Box className="week-content-main-flex">
        <Index.Box className="week-box">
          <Index.Box className="week-title-main">
            <Index.Typography className="week-title">
              {data?.name}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        {data?.dates?.map((values, dateIndex) => {
          return (
            <Index.Box className="week-box" key={values?.date}>
              <Index.Typography className="week-date-text">
                {values?.date}
              </Index.Typography>
              <Index.Box className="admin-input-box week-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder={
                      !values?.disabled && values?.value === 0 ? "0" : ""
                    }
                    value={
                      values?.disabled || values?.value === 0
                        ? ""
                        : values?.value
                    }
                    disabled={values?.disabled}
                    inputProps={{ maxLength: 3 }}
                    onChange={(e) => {
                      const regex = /^[0-9]*$/;
                      if (regex.test(e.target.value)) {
                        handleValueChange(dateIndex, e.target.value);
                      }
                    }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          );
        })}
        <Index.Box className="week-box">
          <Index.Typography className="week-date-text"></Index.Typography>
          <Index.Box className="admin-input-box week-input-box">
            <Index.Box className="admin-form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="admin-form-control"
                placeholder=""
                disabled
                value={data?.total}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="week-box">
          <Index.Typography className="week-date-text"></Index.Typography>
          <Index.Box className="admin-input-box week-input-box">
            <Index.Box className="admin-form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="admin-form-control"
                placeholder=""
                disabled
                value={data?.total * creativeDuration}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default WeekdayTable;
