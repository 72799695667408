import React from "react";
import {
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";

//#start region Admin
import { useSelector } from "react-redux";
import ForgotPassword from "../component/admin/auth/forgotPassword/ForgotPassword";
import Login from "../component/admin/auth/login/Login";
import Otp from "../component/admin/auth/otp/Otp";
import ResetPassword from "../component/admin/auth/resetPassword/ResetPassword";
import SignIn from "../component/admin/auth/signIn/SignIn";
import ComingSoon from "../component/common/comingSoon/ComingSoon.js";
import WebView from "../component/webView/WebView.js";
import AccountLayout from "../container/admin/pages/accountLayout/AccountLayout";
import AdZoneDetails from "../container/admin/pages/adZoneDetails/AdZoneDetails.js";
import AdminLayOut from "../container/admin/pages/adminLayout/AdminLayOut";
import AddAdsManagement from "../container/admin/pages/adsManagement/AddAdsManagement.js";
import AdsManagement from "../container/admin/pages/adsManagement/AdsManagement.js";
import ViewAdsManagement from "../container/admin/pages/adsManagement/ViewAdsManagement.js";
import AddAdvertiser from "../container/admin/pages/advertiser/AddAdvertiser.js";
import AdvertiseCategory from "../container/admin/pages/advertiser/AdvertiseCategory.js";
import AdvertiseSubCategory from "../container/admin/pages/advertiser/AdvertiseSubCategory.js";
import Advertiser from "../container/admin/pages/advertiser/Advertiser.js";
import MediaPlatform from "../container/admin/pages/advertiser/MediaPlatform.js";
import ViewAdvertiser from "../container/admin/pages/advertiser/ViewAdvertiser.js";
import Advertising from "../container/admin/pages/advertising/Advertising.js";
import CastAndCrewDetails from "../container/admin/pages/castAndCrewDetails/CastAndCrewDetails";
import CategoryManagement from "../container/admin/pages/categoryManagement/CategoryManagement";
import ChannelManagement from "../container/admin/pages/channelManagement/ChannelManagement.js";
import CMSManagement from "../container/admin/pages/cmsManagement/CMSManagement";
import CreativeManagement from "../container/admin/pages/creativeManagement/CreativeManagement.js";
import DashBoard from "../container/admin/pages/dashBoard/DashBoard";
import DistrictDetails from "../container/admin/pages/district/districtDetails.js";
import ElementManagement from "../container/admin/pages/elementManagement/ElementManagement.js";
import Episode from "../container/admin/pages/episode/Episode";
import ViewEpisode from "../container/admin/pages/episode/ViewEpisode";
import EventManagement from "../container/admin/pages/eventManagement/EventManagement.js";
import FaqManagement from "../container/admin/pages/faqManagement/FaqManagement";
import FeedBack from "../container/admin/pages/feedBack/FeedBack.js";
import HomeCategoryManagement from "../container/admin/pages/homeCategoryManagement/HomeCategoryManagement.js";
import ManagementUser from "../container/admin/pages/manageUser/ManagementUser.js";
import ViewUser from "../container/admin/pages/manageUser/ViewUser.js";
import MovieAndWebSeriesTagMaster from "../container/admin/pages/movieAndWebSeriesTagMaster/MovieAndWebSeriesTagMaster.js";
import AddMovie from "../container/admin/pages/movies/AddMovie.js";
import MovieActivityLog from "../container/admin/pages/movies/MovieActivityLog..js";
import MovieCategoryManagement from "../container/admin/pages/movies/MovieCategoryManagement.js";
import Movies from "../container/admin/pages/movies/Movies";
import ViewMovie from "../container/admin/pages/movies/ViewMovie";
import NCShotsScheduleManagement from "../container/admin/pages/ncShotsScheduleManagement/NCShotsScheduleManagement.js";
import AddNews from "../container/admin/pages/news/AddNews.js";
import NewsActivityLog from "../container/admin/pages/news/NewsActivityLog.js";
import NewsTab from "../container/admin/pages/news/NewsTab.js";
import ViewNews from "../container/admin/pages/news/ViewNews.js";
import PhoneDrectory from "../container/admin/pages/phoneDirectory/PhoneDrectory.js";
import ProfileDetails from "../container/admin/pages/profileDetails/ProfileDetails";
import ProgramManagement from "../container/admin/pages/programManagement/ProgramManagement.js";
import ProgramType from "../container/admin/pages/programType/ProgramType.js";
import ReportsAndAnalytics from "../container/admin/pages/reportsAndAnalytics/ReportsAndAnalytics.js";
import RoleManagement from "../container/admin/pages/roleManagement/RoleManagement";
import AddSalesOrder from "../container/admin/pages/salesOrderManagment/AddSalesOrder.js";
import AddSalesSubOrder from "../container/admin/pages/salesOrderManagment/AddSalesSubOrder.js";
import SalesOrderList from "../container/admin/pages/salesOrderManagment/SalesOrderList.js";
import SalesSubOrderList from "../container/admin/pages/salesOrderManagment/SalesSubOrderList.js";
import AddScheduleTable from "../container/admin/pages/schduleManagement/AddScheduleTable.js";
import SchduleManagement from "../container/admin/pages/schduleManagement/SchduleManagement.js";
import ViewScheduleTable from "../container/admin/pages/schduleManagement/ViewScheduleTable.js";
import Seasons from "../container/admin/pages/seasons/Seasons";
import ViewSeasons from "../container/admin/pages/seasons/ViewSeasons";
import SiteSettings from "../container/admin/pages/siteSettings/SiteSettings";
import AddSlot from "../container/admin/pages/slotManagement/AddSlot.js";
import Slot from "../container/admin/pages/slotManagement/Slots.js";
import SponsorActivityLog from "../container/admin/pages/sponserManagement/SponsorActivityLog.js";
import SponsorManagement from "../container/admin/pages/sponserManagement/SponsorManagement.js";
import ViewSponser from "../container/admin/pages/sponserManagement/ViewSponser.js";
import StateManagement from "../container/admin/pages/stateManagement/StateManagement";
import AdminActivityLog from "../container/admin/pages/subAdminManagement/AdminActivityLog.js";
import SubAdminManagement from "../container/admin/pages/subAdminManagement/SubAdminManagement";
import UserLoginLog from "../container/admin/pages/subAdminManagement/UserLoginLog.js";
import Support from "../container/admin/pages/support/Support.js";
import TagMaster from "../container/admin/pages/tagMaster/TagMaster";
import UserPostVideo from "../container/admin/pages/userPostVideo/UserPostVideo.js";
import VotersList from "../container/admin/pages/votersList/VotersList.js";
import ViewWebSeries from "../container/admin/pages/webSeries/ViewWebSeries";
import WebSeries from "../container/admin/pages/webSeries/WebSeries";
import WebsiteScheduleManagement from "../container/admin/pages/websiteScheduleManagement/WebsiteScheduleManagement.js";
import ZoneDetails from "../container/admin/pages/zoneDetails/ZoneDetails";
import PrivateRoutes from "./PrivateRoutes";
import UploadVideo from "../container/admin/pages/uploadVideo/UploadVideo.js";
import AgencyManagement from "../container/admin/pages/agencyManagement/AgencyManagement.js";
import InternalServerLinkManagement from "../container/admin/pages/internalServerLinkManagement/InternalServerLinkManagement.js";
import NotAllowPermission from "../component/common/comingSoon/NotAllowPermission.js";

//#endregion

function ErrorBoundary() {
  const error = useRouteError();
  return (
    <div className="container">
      <h1>Oh Dang!!</h1> <p>{error?.data}</p>
    </div>
  );
}
export default function Routes() {
  const { adminDetails } = useSelector((state) => state.AdminReducer);
  const appRoutes = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/otp-verify",
      element: <Otp />,
    },
    {
      path: "/sign-in",
      element: <SignIn />,
    },
    {
      path: "/:path",
      element: <WebView />,
    },
    {
      path: "/admin",
      element: <AdminLayOut />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          path: "/admin/comingSoon/:comingSoon",
          element: (
            <PrivateRoutes>
              <ComingSoon />
            </PrivateRoutes>
          ),
        },
        {
          path: "dashboard",
          element: adminDetails?.roleId?.permissions?.includes(
            "dashboard_view"
          ) ? (
            <PrivateRoutes>
              <DashBoard />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "account",
          element: (
            <PrivateRoutes>
              <AccountLayout />
            </PrivateRoutes>
          ),
        },
        {
          path: "cms/:path",
          element: adminDetails?.roleId?.permissions?.includes("cms_view") ? (
            <PrivateRoutes>
              <CMSManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "phone-directory",
          element: adminDetails?.roleId?.permissions?.includes(
            "phone_directory_view"
          ) ? (
            <PrivateRoutes>
              <PhoneDrectory />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "subAdmin-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "admin_user_view"
          ) ? (
            <PrivateRoutes>
              <SubAdminManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "user-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "admin_user_view"
          ) ? (
            <PrivateRoutes>
              <UserLoginLog />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "subAdmin-management/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "admin_user_log"
          ) ? (
            <PrivateRoutes>
              <AdminActivityLog />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "sponsor-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) ? (
            <PrivateRoutes>
              <SponsorManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "platform-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) ? (
            <PrivateRoutes>
              <ChannelManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-sponsor/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) ? (
            <PrivateRoutes>
              <ViewSponser />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "sponsor/sponsor-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "sponsor_view"
          ) ? (
            <PrivateRoutes>
              <SponsorActivityLog />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "manage-user",
          element: adminDetails?.roleId?.permissions?.includes("user_view") ? (
            <PrivateRoutes>
              <ManagementUser />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "state-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "state_view"
          ) ? (
            <PrivateRoutes>
              <StateManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "category-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "category_view"
          ) ? (
            <PrivateRoutes>
              <CategoryManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "faq-management",
          element: adminDetails?.roleId?.permissions?.includes("faq_view") ? (
            <PrivateRoutes>
              <FaqManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "role-management",
          element: adminDetails?.roleId?.permissions?.includes("role_view") ? (
            <PrivateRoutes>
              <RoleManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "site-settings",
          element: adminDetails?.roleId?.permissions?.includes(
            "site_setting_view"
          ) ? (
            <PrivateRoutes>
              <SiteSettings />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "profile-details",
          element: (
            <PrivateRoutes>
              <ProfileDetails />
            </PrivateRoutes>
          ),
        },
        {
          path: "zone-details",
          element: adminDetails?.roleId?.permissions?.includes("zone_view") ? (
            <PrivateRoutes>
              <ZoneDetails />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "ad-zone-details",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_zone_view"
          ) ? (
            <PrivateRoutes>
              <AdZoneDetails />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "district",
          element: adminDetails?.roleId?.permissions?.includes(
            "district_view"
          ) ? (
            <PrivateRoutes>
              <DistrictDetails />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "tag-master",
          element: adminDetails?.roleId?.permissions?.includes("tag_view") ? (
            <PrivateRoutes>
              <TagMaster />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "movie-tag-master",
          element: adminDetails?.roleId?.permissions?.includes(
            "movie_tag_view"
          ) ? (
            <PrivateRoutes>
              <MovieAndWebSeriesTagMaster />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "cast-and-crew-master",
          element: (
            <PrivateRoutes>
              <CastAndCrewDetails />
            </PrivateRoutes>
          ),
        },
        {
          path: "movies",
          element: adminDetails?.roleId?.permissions?.includes(
            "movies_view"
          ) ? (
            <PrivateRoutes>
              <Movies />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "movie-category-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "movie_category_view"
          ) ? (
            <PrivateRoutes>
              <MovieCategoryManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "movies/movie-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "movies_log"
          ) ? (
            <PrivateRoutes>
              <MovieActivityLog />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "videos",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_view"
          ) ? (
            <PrivateRoutes>
              <NewsTab />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "videos/video-activity-log",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_view"
          ) ? (
            <PrivateRoutes>
              <NewsActivityLog />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "home-category-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "home_category_view"
          ) ? (
            <PrivateRoutes>
              <HomeCategoryManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-video",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_add"
          ) ? (
            <PrivateRoutes>
              <AddNews />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-video/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "videos_edit"
          ) ? (
            <PrivateRoutes>
              <AddNews />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "event-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "event_view"
          ) ? (
            <PrivateRoutes>
              <EventManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "category",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_category_view"
          ) ? (
            <PrivateRoutes>
              <AdvertiseCategory />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "sub-category",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_sub_category_view"
          ) ? (
            <PrivateRoutes>
              <AdvertiseSubCategory />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "media-platform",
          element: adminDetails?.roleId?.permissions?.includes(
            "media_platform_view"
          ) ? (
            <PrivateRoutes>
              <MediaPlatform />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "clients",
          element: adminDetails?.roleId?.permissions?.includes(
            "clients_view"
          ) ? (
            <PrivateRoutes>
              <Advertiser />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-client",
          element: adminDetails?.roleId?.permissions?.includes(
            "clients_add"
          ) ? (
            <PrivateRoutes>
              <AddAdvertiser />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-client/:id",
          element: (
            <PrivateRoutes>
              <ViewAdvertiser />
            </PrivateRoutes>
          ),
        },
        {
          path: "edit-client/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "clients_edit"
          ) ? (
            <PrivateRoutes>
              <AddAdvertiser />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "web-series",
          element: adminDetails?.roleId?.permissions?.includes(
            "series_view"
          ) ? (
            <PrivateRoutes>
              <WebSeries />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "seasons",
          element: adminDetails?.roleId?.permissions?.includes(
            "season_view"
          ) ? (
            <PrivateRoutes>
              <Seasons />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "episode",
          element: adminDetails?.roleId?.permissions?.includes(
            "episode_view"
          ) ? (
            <PrivateRoutes>
              <Episode />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-web-series/:id",
          element: (
            <PrivateRoutes>
              <ViewWebSeries />
            </PrivateRoutes>
          ),
        },
        {
          path: "view-season",
          element: adminDetails?.roleId?.permissions?.includes("role_view") ? (
            <PrivateRoutes>
              <ViewSeasons />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-episode/:id",
          element: adminDetails?.roleId?.permissions?.includes("role_view") ? (
            <PrivateRoutes>
              <ViewEpisode />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-movie/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "movies_view"
          ) ? (
            <PrivateRoutes>
              <ViewMovie />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-movie",
          element: (adminDetails?.roleId?.permissions?.includes("movies_add") ||
            adminDetails?.roleId?.permissions?.includes("movies_edit")) ? (
            <PrivateRoutes>
              <AddMovie />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-video/:id",
          element: (
            <PrivateRoutes>
              <ViewNews />
            </PrivateRoutes>
          ),
        },
        {
          path: "feedback",
          element: adminDetails?.roleId?.permissions?.includes("role_view") ? (
            <PrivateRoutes>
              <FeedBack />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "support",
          element: adminDetails?.roleId?.permissions?.includes(
            "support_view"
          ) ? (
            <PrivateRoutes>
              <Support />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "advertising",
          element: adminDetails?.roleId?.permissions?.includes(
            "advertising_view"
          ) ? (
            <PrivateRoutes>
              <Advertising />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-user/:id",
          element: adminDetails?.roleId?.permissions?.includes("user_view") ? (
            <PrivateRoutes>
              <ViewUser />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-ads-management",
          element: adminDetails?.roleId?.permissions?.includes("ads_add") ? (
            <PrivateRoutes>
              <AddAdsManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "edit-ads-management/:id",
          element: adminDetails?.roleId?.permissions?.includes("ads_edit") ? (
            <PrivateRoutes>
              <AddAdsManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "ads-management",
          element: adminDetails?.roleId?.permissions?.includes("ads_view") ? (
            <PrivateRoutes>
              <AdsManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "inventory-ads-management",
          element: adminDetails?.roleId?.permissions?.includes("ads_view") ? (
            <PrivateRoutes>
              <AdsManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-ads-management/:id",
          element: adminDetails?.roleId?.permissions?.includes("ads_view") ? (
            <PrivateRoutes>
              <ViewAdsManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "user-posted-videos",
          element: adminDetails?.roleId?.permissions?.includes(
            "user_posted_videos_view"
          ) ? (
            <PrivateRoutes>
              <UserPostVideo />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "program-type-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "program_type_view"
          ) ? (
            <PrivateRoutes>
              <ProgramType />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "program-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "program_view"
          ) ? (
            <PrivateRoutes>
              <ProgramManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "report-and-analytics",
          element: adminDetails?.roleId?.permissions?.includes(
            "report_analytics_view"
          ) ? (
            <PrivateRoutes>
              <ReportsAndAnalytics />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "element-management/:page",
          element: adminDetails?.roleId?.permissions?.includes(
            "element_view"
          ) ? (
            <PrivateRoutes>
              <ElementManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-sales-order",
          element: true && (
            <PrivateRoutes>
              <AddSalesOrder />
            </PrivateRoutes>
          ),
        },
        {
          path: "add-sales-sub-order/:id",
          element: true && (
            <PrivateRoutes>
              <AddSalesSubOrder />
            </PrivateRoutes>
          ),
        },
        {
          path: "creative-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "creative_view"
          ) ? (
            <PrivateRoutes>
              <CreativeManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "slot-management/:page",
          element: adminDetails?.roleId?.permissions?.includes("slot_view") ? (
            <PrivateRoutes>
              <Slot />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "slot-schedule-management/:page",
          element: adminDetails?.roleId?.permissions?.includes(
            "slot_schedule_view"
          ) ? (
            <PrivateRoutes>
              <SchduleManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-slot-schedule/:page/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "slot_schedule_view"
          ) ? (
            <PrivateRoutes>
              <AddScheduleTable />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-slot/:page",
          element: adminDetails?.roleId?.permissions?.includes("slot_add") ? (
            <PrivateRoutes>
              <AddSlot />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-slot-schedule/:page/:id",
          element: adminDetails?.roleId?.permissions?.includes("slot_add") ? (
            <PrivateRoutes>
              <ViewScheduleTable />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "edit-slot/:page/:id",
          element: adminDetails?.roleId?.permissions?.includes("slot_edit") ? (
            <PrivateRoutes>
              <AddSlot />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "sales-order-list",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_view"
          ) ? (
            <PrivateRoutes>
              <SalesOrderList />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "sales-sub-order-list/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_view"
          ) ? (
            <PrivateRoutes>
              <SalesSubOrderList />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-sales-order",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_add"
          ) ? (
            <PrivateRoutes>
              <AddSalesOrder />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "add-sales-order/:id",
          element: adminDetails?.roleId?.permissions?.includes(
            "sales_order_edit"
          ) ? (
            <PrivateRoutes>
              <AddSalesOrder />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "view-slot",
          element: adminDetails?.roleId?.permissions?.includes("slot_edit") ? (
            <PrivateRoutes>
              <AddSalesOrder />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "voter-list",
          element: true && (
            <PrivateRoutes>
              <VotersList />
            </PrivateRoutes>
          ),
        },
        {
          path: "website-schedule-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "website_ad_view"
          ) ? (
            <PrivateRoutes>
              <WebsiteScheduleManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "ncshots-schedule-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "nc_slot_schedule_view"
          ) ? (
            <PrivateRoutes>
              <NCShotsScheduleManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "upload-video",
          element: (adminDetails?.roleId?.permissions?.includes(
            "upload_video_view"
          ) ||
            adminDetails?.roleId?.permissions?.includes(
              "upload_video_view_all"
            )) ? (
            <PrivateRoutes>
              <UploadVideo />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "agency-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_category_view"
          ) ? (
            <PrivateRoutes>
              <AgencyManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
        {
          path: "server-link-management",
          element: adminDetails?.roleId?.permissions?.includes(
            "client_category_view"
          ) ? (
            <PrivateRoutes>
              <InternalServerLinkManagement />
            </PrivateRoutes>
          ) : (<NotAllowPermission />),
        },
      ],
    },
  ];
  const router = createBrowserRouter(appRoutes);
  return <RouterProvider router={router} />;
}
