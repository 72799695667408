import React, { useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./news.css";
import "./news.responsive.css";
import { useNavigate } from "react-router-dom/dist";
import PropTypes from "prop-types";

const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#3c0000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

// for modal design
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const News = ({
  filteredData,
  handleGetNews,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  valueTabs,
  loading,
  dataLength,
}) => {
  const navigate = useNavigate();

  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const [openDelete, setOpenDelete] = useState(false);
  const [openAd, setOpenAd] = useState(false);
  const [id, setId] = useState("");

  const [activityLog, setActivityLog] = useState([]);
  const [statusButtonSpinner, setStatusButtonSpinner] = useState(false);
  const [activityLoading, setActivityLoading] = useState(true);
  const [deleteButtonSpinner, setDeleteButtonSpinner] = useState(false);

  const [openLogModal, setOpenLogModal] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const handleCloseLogModal = () => setOpenLogModal(false);
  const handleOpenLogModal = (id) => {
    setOpenLogModal(true);
    setActivityLoading(true);
    PagesIndex.apiGetHandler(
      PagesIndex.Api.GET_NEWS_ACTIVITY_LOG + "/" + id
    ).then((res) => {
      setActivityLoading(false);
      if (res.status === 200) {
        setActivityLog(res.data?.activityLog);
      }
    });
  };
  // delete modal
  const handleOpenAd = (id) => {
    setId(id);
    setOpenAd(true);
  };
  const handleCloseAd = () => {
    setId("");
    setOpenAd(false);
  };
  const handleOpenDelete = (id) => {
    setOpenDelete(true);
    setId(id);
  };
  const handleCloseDelete = () => {
    setId("");
    setOpenDelete(false);
  };

  const handleNewsDelete = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.DELETE_NEWS, urlEncoded).then(
      (res) => {
        setDeleteButtonSpinner(false);
        if (res.status === 200) {
          handleCloseDelete();
          handleGetNews();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const handleChangeAdStatus = () => {
    setDeleteButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(
      PagesIndex.Api.CHANGE_AD_STATUS_OF_NEWS,
      urlEncoded
    ).then((res) => {
      setDeleteButtonSpinner(false);
      if (res.status === 200) {
        handleCloseAd();
        handleGetNews();
        PagesIndex.toasterSuccess(res?.message);
      } else {
        PagesIndex.toasterError(res?.message);
      }
    });
  };
  const handleOpenStatusModal = (id) => {
    setId(id);
    setOpenStatusModal(true);
  };
  const handleCloseStatusModal = () => {
    setId("");
    setOpenStatusModal(false);
  };
  const handleStatus = () => {
    setStatusButtonSpinner(true);
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("id", id);
    PagesIndex.apiPostHandler(PagesIndex.Api.ISACTIVE_NEWS, urlEncoded).then(
      (res) => {
        setStatusButtonSpinner(false);
        if (res.status === 200) {
          handleGetNews();
          handleCloseStatusModal();
          PagesIndex.toasterSuccess(res?.message);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const renderTableRows = () => {
    return filteredData?.map((data) => {
      let category = data?.category?.map((ele) => ele?.categoryName);
      return (
        <Index.TableRow
          sx={{
            "&:last-child td, &:last-child th": {
              border: 0,
            },
          }}
          key={data?._id}
        >
          <Index.TableCell
            component="td"
            variant="td"
            scope="row"
            className="table-td"
          >
            <Index.Typography className="admin-table-data-text">
              <Index.Tooltip
                title={data?.newsTitle}
                arrow
                placement="top"
                className="admin-tooltip admin-news-tooltip"
              >
                {" "}
                <div>
                  <p
                    className="news-id-text"
                    onClick={() => {
                      navigate(`/admin/view-video/${data?._id}`, {
                        state: { page: valueTabs },
                      });
                    }}
                  >
                    {data?.newsNo}
                  </p>{" "}
                  <p className="news-title-name"> {data?.newsTitle}</p>
                </div>
              </Index.Tooltip>
            </Index.Typography>
          </Index.TableCell>

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Box className="color-box">
              {data?.sectionToShow?.length
                ? data?.sectionToShow?.map((item, index) => {
                    return (
                      <Index.Box
                        key={item?._id}
                        className={"lable-btn"}
                        style={{ backgroundColor: item?.colorCode }}
                      >
                        {item?.name}
                      </Index.Box>
                    );
                  })
                : "-"}
            </Index.Box>
          </Index.TableCell>
          {/* <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.zone?.name}
              </Index.Typography>
            </Index.TableCell> */}

          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Typography className="admin-table-data-text">
              {" "}
              {data?.district?.name || "-"}
            </Index.Typography>
          </Index.TableCell>
          <Index.TableCell component="td" variant="td" className="table-td">
            <Index.Tooltip
              title={category?.join(", ") || "-"}
              arrow
              placement="top"
              className="admin-tooltip"
            >
              <Index.Typography className="admin-table-data-text">
                {" "}
                {category?.join(", ") || "-"}
              </Index.Typography>
            </Index.Tooltip>
          </Index.TableCell>

          <Index.TableCell
            component="td"
            variant="td"
            scope="row"
            className="table-td"
          >
            <Index.Box className="admin-table-social-btn-flex">
              {data?.youtube_link && (
                <Index.Button className="admin-table-social-btn">
                  <img
                    src={PagesIndex.Svg.youtubeicons}
                    className="admin-social-icon-img"
                    alt="upload img"
                  ></img>
                </Index.Button>
              )}
              {data?.s3_link && (
                <Index.Button className="admin-table-social-btn">
                  <img
                    src={PagesIndex.Svg.awsicons}
                    className="admin-social-icon-img"
                    alt="upload img"
                  ></img>
                </Index.Button>
              )}{" "}
              {data?.internal_link && (
                <Index.Button className="admin-table-social-btn">
                  <img
                    src={PagesIndex.Svg.internalservericons}
                    className="admin-social-icon-img"
                    alt="upload img"
                  ></img>
                </Index.Button>
              )}
            </Index.Box>
          </Index.TableCell>

          {valueTabs === 0 ? (
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {" "}
                {data?.createdAt
                  ? `${data?.createdBy?.name}-${PagesIndex.moment(
                      data?.createdAt
                    )?.format("DD/MM/YYYY hh:mm A")}`
                  : "-"}
              </Index.Typography>
            </Index.TableCell>
          ) : (
            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Typography className="admin-table-data-text">
                {data?.scheduledDate
                  ? `${data?.createdBy?.name}-${PagesIndex.moment(
                      data?.scheduledDate
                    )?.format("DD/MM/YYYY hh:mm A")}`
                  : "-"}
              </Index.Typography>
            </Index.TableCell>
          )}
          {(adminDetails?.roleId?.permissions?.includes("videos_edit") ||
            adminDetails?.roleId?.permissions?.includes("videos_delete")) && (
            <Index.TableCell
              component="td"
              variant="td"
              className="table-td"
              align="right"
            >
              <Index.Tooltip
                title={data?.isActive ? "Active" : "Deactive"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Box className="admin-switch-main">
                  <Index.FormControlLabel
                    control={<IOSSwitch sx={{ m: 1 }} />}
                    className="admin-switch-lable"
                    checked={data?.isActive}
                    onChange={() => {
                      handleOpenStatusModal(data?._id);
                    }}
                  />
                </Index.Box>
              </Index.Tooltip>
            </Index.TableCell>
          )}

          <Index.TableCell
            component="td"
            variant="td"
            align="right"
            className="table-td"
          >
            <Index.Box className="admin-table-data-btn-flex ">
              {adminDetails?.roleId?.permissions?.includes("videos_edit") && (
                <Index.Tooltip
                  title={data?.isAdsPlay ? "Ads Show" : "Ads Hidden"}
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenAd(data?._id)}
                  >
                    {data?.isAdsPlay ? (
                      <img
                        src={PagesIndex.Svg.advertise}
                        className="admin-icon"
                        alt="block ad"
                      ></img>
                    ) : (
                      <img
                        src={PagesIndex.Svg.blockad}
                        className="admin-icon"
                        alt="show ad"
                      ></img>
                    )}
                  </Index.Button>
                </Index.Tooltip>
              )}
              <Index.Tooltip
                title={data?.notiFlag == true ? "ON" : "OFF"}
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <span
                  className={
                    data?.notiFlag == true ? "active noti-box" : "noti-box"
                  }
                >
                  <img
                    src={
                      data?.notiFlag == true
                        ? PagesIndex.Svg.notificationActive
                        : PagesIndex.Svg.notification
                    }
                    className="admin-icons"
                    alt="upload img"
                  ></img>
                </span>
              </Index.Tooltip>

              {adminDetails?.roleId?.permissions?.includes("videos_edit") && (
                <Index.Tooltip
                  title="Edit"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => {
                      navigate(`/admin/add-video/${data?._id}`, {
                        state: {
                          data: data,
                          page: valueTabs,
                        },
                      });
                      localStorage.setItem("currentPage", currentPage);
                    }}
                  >
                    <img
                      src={PagesIndex.Svg.blueedit}
                      className="admin-icon"
                      alt="Edit"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              )}
              <Index.Tooltip
                title="View"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() => {
                    navigate(`/admin/view-video/${data?._id}`, {
                      state: { page: valueTabs },
                    });
                  }}
                >
                  <img
                    src={PagesIndex.Svg.yelloweye}
                    className="admin-icon"
                    alt="View"
                  ></img>
                </Index.Button>
              </Index.Tooltip>

              {adminDetails?.roleId?.permissions?.includes("videos_log") && (
                <Index.Tooltip
                  title="Log"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenLogModal(data?._id)}
                  >
                    <img
                      src={PagesIndex.Svg.logicon}
                      className="admin-icon"
                      alt="Log"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              )}

              {adminDetails?.roleId?.permissions?.includes("videos_delete") && (
                <Index.Tooltip
                  title="Delete"
                  arrow
                  placement="bottom"
                  className="admin-tooltip"
                >
                  <Index.Button
                    className="admin-table-data-btn"
                    onClick={() => handleOpenDelete(data?._id)}
                  >
                    <img
                      src={PagesIndex.Svg.trash}
                      className="admin-icon"
                      alt="Delete"
                    ></img>
                  </Index.Button>
                </Index.Tooltip>
              )}
            </Index.Box>
          </Index.TableCell>
        </Index.TableRow>
      );
    });
  };

  const renderContent = () => {
    if (filteredData?.length) {
      return renderTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  const renderActivityTableRows = () => {
    return activityLog
      .slice()
      .reverse()
      .map((data, index) => {
        let updatedInfo = "";
        if (data?.updatedBy?.name) {
          updatedInfo = `${data?.updatedBy?.name}-${
            data?.dateTime
              ? PagesIndex.moment(data?.dateTime).format(
                  "DD/MM/YYYY hh:mm:ss A"
                )
              : "-"
          }`;
        } else {
          updatedInfo = "-";
        }
        return (
          <Index.TableRow
            sx={{
              "&:last-child td, &:last-child th": {
                border: 0,
              },
            }}
            key={data?._id}
          >
            <Index.TableCell
              component="td"
              variant="td"
              scope="row"
              className="table-td"
            >
              <Index.Typography className="admin-table-data-text">
                {data?.actionType === "Updated"
                  ? "Video updated"
                  : data?.actionType}
              </Index.Typography>
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              {updatedInfo}
            </Index.TableCell>

            <Index.TableCell component="td" variant="td" className="table-td">
              <Index.Tooltip
                title="View"
                arrow
                placement="bottom"
                className="admin-tooltip"
              >
                <Index.Button
                  className="admin-table-data-btn"
                  onClick={() =>
                    navigate(`/admin/videos/video-activity-log`, {
                      state: {
                        log: activityLog,
                        index: index,
                      },
                    })
                  }
                >
                  <img
                    src={PagesIndex.Svg.yelloweye}
                    className="admin-icon"
                    alt="View"
                  ></img>
                </Index.Button>
              </Index.Tooltip>
            </Index.TableCell>
          </Index.TableRow>
        );
      });
  };

  const renderActivityLogContent = () => {
    if (activityLog?.length) {
      return renderActivityTableRows();
    } else {
      return <PagesIndex.DataNotFound />;
    }
  };

  return (
    <Index.Box className="common-card">
      <Index.Box className={`admin-news-table-main page-table-main`}>
        <Index.TableContainer
          component={Index.Paper}
          className="table-container"
        >
          {loading ? (
            <PagesIndex.Loader />
          ) : (
            <Index.Table aria-label="simple table" className="table">
              <Index.TableHead className="table-head">
                <Index.TableRow className="table-row">
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="30%"
                  >
                    Video Title
                  </Index.TableCell>
                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="15%"
                  >
                    Section To Show
                  </Index.TableCell>

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="5%"
                  >
                    District
                  </Index.TableCell>

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="5%"
                  >
                    Category
                  </Index.TableCell>

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="5%"
                  >
                    Video Link
                  </Index.TableCell>

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="16%"
                  >
                    {valueTabs === 0
                      ? "Created Date & Time"
                      : "Publish Date & Time"}
                  </Index.TableCell>

                  {(adminDetails?.roleId?.permissions?.includes(
                    "videos_edit"
                  ) ||
                    adminDetails?.roleId?.permissions?.includes(
                      "videos_delete"
                    )) && (
                    <Index.TableCell
                      component="th"
                      variant="th"
                      className="table-th"
                      width="2%"
                      align="right"
                    >
                      Status
                    </Index.TableCell>
                  )}

                  <Index.TableCell
                    component="th"
                    variant="th"
                    className="table-th"
                    width="4%"
                    align="right"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody className="table-body">
                {renderContent()}
              </Index.TableBody>
            </Index.Table>
          )}
        </Index.TableContainer>
      </Index.Box>
      <Index.Box className="admin-pagination-main">
        {dataLength ? (
          <Index.TablePagination
            page={currentPage - 1}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 50, 100]}
            count={Math.ceil(dataLength)}
            onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setCurrentPage(1);
            }}
            variant="outlined"
            shape="rounded"
            className="admin-pagination"
          />
        ) : (
          ""
        )}
      </Index.Box>

      <Index.Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to delete this video? This process cannot be
              undone.
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseDelete}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleNewsDelete}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Delete</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <Index.Modal
        open={openAd}
        onClose={handleCloseAd}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-delete-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="admin-modal-circle-main">
              <img
                src={PagesIndex.Svg.closecircle}
                className="admin-user-circle-img"
                alt="Close"
              />
            </Index.Box>
            <Index.Typography
              className="admin-delete-modal-title"
              component="h2"
              variant="h2"
            >
              Are you sure?
            </Index.Typography>
            <Index.Typography
              className="admin-delete-modal-para admin-common-para"
              component="p"
              variant="p"
            >
              Do you really want to change status of play ads in this video?
            </Index.Typography>
            <Index.Box className="admin-delete-modal-btn-flex border-btn-main btn-main">
              <Index.Button
                className="admin-modal-cancel-btn border-btn"
                onClick={handleCloseAd}
                disabled={deleteButtonSpinner}
              >
                Cancel
              </Index.Button>
              <Index.Button
                className="delete-user admin-modal-delete-btn btn"
                onClick={handleChangeAdStatus}
                disabled={deleteButtonSpinner}
              >
                {deleteButtonSpinner ? (
                  <PagesIndex.Spinner />
                ) : (
                  <span>Change</span>
                )}
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

      <Index.Modal
        open={openLogModal}
        onClose={handleCloseLogModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="admin-modal"
      >
        <Index.Box
          sx={style}
          className="admin-log-modal-inner-main admin-modal-inner"
        >
          <Index.Box className="admin-modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="admin-modal-title"
              variant="h6"
              component="h2"
            >
              {" "}
              Activity Log
            </Index.Typography>
            <Index.Button
              className="modal-close-btn"
              onClick={handleCloseLogModal}
            >
              <img
                src={PagesIndex.Svg.closeblack}
                className="admin-modal-close-icon"
                alt="Close"
              />
            </Index.Button>
          </Index.Box>
          <Index.Box className="admin-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="common-card">
              <Index.Box
                className={`admin-news-activity-table-main ${
                  activityLoading ? "" : "page-table-main"
                }`}
              >
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  {activityLoading ? (
                    <PagesIndex.Loader />
                  ) : (
                    <Index.Table aria-label="simple table" className="table">
                      <Index.TableHead className="table-head">
                        <Index.TableRow className="table-row">
                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="50%"
                          >
                            Activity Log
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="40%"
                          >
                            Updated Date & Time
                          </Index.TableCell>

                          <Index.TableCell
                            component="th"
                            variant="th"
                            className="table-th"
                            width="10%"
                            align="right"
                          >
                            Action
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody className="table-body">
                        {renderActivityLogContent()}
                      </Index.TableBody>
                    </Index.Table>
                  )}
                </Index.TableContainer>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
      <PagesIndex.ChangeStatusModal
        data={"video"}
        statusButtonSpinner={statusButtonSpinner}
        openStatusModal={openStatusModal}
        handleCloseStatusModal={handleCloseStatusModal}
        handleChangeStatus={handleStatus}
      />
    </Index.Box>
  );
};

export default News;

News.propTypes = {
  filteredData: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  dataLength: PropTypes.number.isRequired,
  handleGetNews: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  valueTabs: PropTypes.any.isRequired, // Adjust the type according to your requirement
  loading: PropTypes.bool.isRequired,
};
