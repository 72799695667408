import dayjs from "dayjs";
import moment from "moment";
import * as Yup from "yup";
import { apiGetHandler } from "../../config/ApiHandler";
import { Api } from "../../config/Api";
import { useSelector } from "react-redux";

const fullNameRegex = /^(?=[a-zA-Z])[a-zA-Z]+([',.\- ][a-zA-Z]?[a-zA-Z]*)*$/;
const emailRegex =
  /\b^[a-zA-Z0-9.]+@[A-Za-z0-9-]+\.[A-Za-z]{2,3}(?:\.[A-Za-z]{2,3})?\b$/;
// Dispossable email addresses not allowed ↓
// /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(((?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]))(?<!mailinator\.com)(?<!trbvm\.com)(?<!guerrillamail\.com)(?<!guerrillamailblock\.com)(?<!sharklasers\.com)(?<!guerrillamail\.net)(?<!guerrillamail\.org)(?<!guerrillamail\.biz)(?<!spam4\.me)(?<!grr\.la)(?<!guerrillamail\.de)(?<!grandmasmail\.com)(?<!zetmail\.com)(?<!vomoto\.com)(?<!abyssmail\.com)(?<!anappthat\.com)(?<!eelmail\.com)(?<!yopmail\.com)(?<!fakeinbox\.com))$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%,*?&~`#()[\]{}<>^/\\|+=_-]).{8,}$/;
const otpRegex = /^\d{4}$/;
const tagRegex = /^[a-zA-Z0-9_&-]+$/;
const nameWithSpaceAndSpecialCaracterAnd = /^(?! )(?!.*\s{2})[A-Za-z& ]*$/;
const companyNameRagex =
  /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[\p{L}\p{M}\p{N}\p{P}\p{S} !@#$%^&*()_+\-=,.<>?;:'"{}|\\/~`]+$/u;
const designationNameRagex =
  /^(?! )(?=.*[A-Za-z])(?!\d+$)(?![^A-Za-z0-9_().]+$)[-A-Za-z0-9_().]+(?:\s[-A-Za-z0-9_().]+)*(?<! )$/;
const firstLastNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};
const valueValidationError = (fieldName, str) => {
  return `${fieldName} can not contain ${str}`;
};

const textAndSpaceOnly = (value) =>
  /^(?! )(?=.*\S)(?!.*\s\s)[\w& ]+$/.test(value) || value.length === 0;
const isSameOrAfter = (startTime, endTime) => {
  return moment(startTime, "HH:mm").isSameOrAfter(moment(endTime, "HH:mm"));
};
const isSameOrAfterDate = (startTime, endTime) => {
  return moment(startTime, "HH:mm").isSameOrAfter(moment(endTime, "HH:mm"));
};
let siteSetting = localStorage.getItem("internalLink");
export const loginValidationSchema = Yup.object({
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
  password: Yup.string()
    .required("Please enter password")
    .matches(passwordRegex, "Please enter valid password"),
});

export const addUserValidationSchema = Yup.object({
  firstName: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("First name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "First Name",
        "more than one space between two words"
      )
    )
    .matches(
      firstLastNameRegex,
      valueValidationError("First name", "numbers and special characters")
    )
    .test(
      "len",
      "First name can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .required("Please enter first name"),
  lastName: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Last name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Last Name", "more than one space between two words")
    )
    .matches(
      firstLastNameRegex,
      valueValidationError("Last name", "numbers and special characters")
    )
    .test(
      "len",
      "Last name can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .required("Please enter last name"),
  number: Yup.string()
    .nullable()
    .matches(
      /^(?!(\d)\1+$)[1-9]\d{9,14}$/,
      "Please enter a valid mobile number"
    )
    .test(
      "len",
      "Mobile number can not be more than 10 characters",
      (val) => !val || val?.length <= 10
    )
    .test(
      "len",
      "Mobile number can not be less than 10 characters",
      (val) => !val || val?.length >= 10
    ),
  email: Yup.string()
    .nullable()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter a valid email id"),
  dateOfBirth: Yup.date().required("Please enter date of birth"),
  gender: Yup.string().required("Please select gender"),
  location: Yup.string().required("Please enter location"),
}).test(
  "at-least-one",
  "Please enter either a mobile number or email id",
  function (value) {
    return value.number || value.email;
  }
);

export const dashbordFilterDate = Yup.object().shape({
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),
});

export const settingValidationSchema = Yup.object().shape({
  liveNews: Yup.array()
    .of(
      Yup.object().shape({
        videoUrl: Yup.string()
          // .matches(
          //   /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
          //   "Invalid URL"
          // )
          .required("Please enter URL"),
        isActive: Yup.boolean().required("isActive is required"),
      })
    )
    .required("At least one video link is required"),
  internalUrl: Yup.string()
    .required("Please enter internal server link")
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid internal server link"
    ),
  adsPlayTime: Yup.string()
    .required("Please enter ad play time")
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/gm, "Please enter valid ad play time"),
  emailList: Yup.array()
    .of(Yup.string().email("Please enter valid email id"))
    .required("Please enter at least one email id"),

  salesOrderEmailApproval: Yup.array()
    .of(Yup.string().email("Please enter valid approval email id"))
    .required("Please enter at least one approval email id"),

  // eventNotifyBeforeTime: Yup.number()
  //   .typeError("Time must be a number")
  //   .min(0.1, "Minimum value is 0.1")
  //   .max(24, "Maximum value is 24")
  //   .test("decimal-places", "Only one decimal place is allowed", (value) =>
  //     /^-?\d+(\.\d{0,1})?$/.test(value)
  //   ),

  image: Yup.mixed()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
  bgImage: Yup.mixed()
    .when("isEdit", {
      is: false,
      then: () => Yup.mixed().required("Please upload app background image"),
      otherwise: () => Yup.mixed().nullable(),
    })
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 375 && img.height == 812) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
  bannerImage: Yup.mixed()
    .when("isEdit", {
      is: false,
      then: () => Yup.mixed().required("Please upload app Banner image"),
      otherwise: () => Yup.mixed().nullable(),
    })
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 342 && img.height == 55) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
  fontTitleColor: Yup.string().required("Please select font First Title Color"),
  fontMainColor: Yup.string().required("Please select font Second Title Color"),
  drawerMenuColor: Yup.string().required("Please select Drawer Menu Color"),
  voteColor: Yup.string().required("Please select Vote Color"),
  ratingWatchListIconColor: Yup.string().required(
    "Please select Rating WatchList Icon Color"
  ),

  fontSecondaryColor: Yup.string().required(
    "Please select font Secondary Color"
  ),
  fontMostViewedColor: Yup.string().required(
    "Please select font Most Viewed Color"
  ),
  statusbar: Yup.string().required("Please select statusbar"),
  fontSize: Yup.string().required("Please select fontSize"),
  mainCategoryFontSize: Yup.string().required(
    "Please select Main Category Font Size"
  ),
  categoryfontSize: Yup.string().required("Please select Category Font Size"),
  placeholderFontColor: Yup.string().required(
    "Please select Placeholder Font Color"
  ),
});

export const settingEditValidationSchema = Yup.object().shape({
  liveNews: Yup.array()
    .of(
      Yup.object().shape({
        videoUrl: Yup.string()
          // .matches(
          //   /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
          //   "Please enter valid URL"
          // )
          .required("Please enter URL"),
        isActive: Yup.boolean().required("isActive is required"),
      })
    )
    .required("At least one video link is required"),
  internalUrl: Yup.string()
    .required("Please enter internal server link")
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid internal server link"
    ),
  adsPlayTime: Yup.string()
    .required("Please enter ad play time")
    .matches(/^(0|[1-9]\d*)(\.\d+)?$/gm, "Please enter valid ad play time"),
  emailList: Yup.array()
    .of(Yup.string().email("Please enter valid email id"))
    .required("Please enter at least one email id"),
  salesOrderEmailApproval: Yup.array()
    .of(Yup.string().email("Please enter valid approval email id"))
    .required("Please enter at least one approval email id"),

  // eventNotifyBeforeTime: Yup.number()
  //   .typeError("Time must be a number")
  //   .min(0.1, "Minimum value is 0.1")
  //   .max(24, "Maximum value is 24")
  //   .test("decimal-places", "Only one decimal place is allowed", (value) =>
  //     /^-?\d+(\.\d{0,1})?$/.test(value)
  //   ),

  image: Yup.mixed()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
  bgImage: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 375 && img.height == 812) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
  bannerImage: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 342 && img.height == 55) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
  fontTitleColor: Yup.string().required("Please select font First Title Color"),
  fontMainColor: Yup.string().required("Please select font Second Title Color"),
  drawerMenuColor: Yup.string().required("Please select Drawer Menu Color"),
  voteColor: Yup.string().required("Please select Vote Color"),
  ratingWatchListIconColor: Yup.string().required(
    "Please select Rating WatchList Icon Color"
  ),

  fontSecondaryColor: Yup.string().required(
    "Please select font Secondary Color"
  ),
  fontMostViewedColor: Yup.string().required(
    "Please select font Most Viewed Color"
  ),
  statusbar: Yup.string().required("Please select statusbar"),
  fontSize: Yup.string().required("Please select fontSize"),
  mainCategoryFontSize: Yup.string().required(
    "Please select Main Category Font Size"
  ),
  categoryfontSize: Yup.string().required("Please select Category Font Size"),
  placeholderFontColor: Yup.string().required(
    "Please select Placeholder Font Color"
  ),
});

export const forgotPasswordValidationSchema = Yup.object({
  newPassword: Yup.string("Please enter new password")
    .required("Please enter new password")
    .min(8, "New password must be at least 8 characters")
    .max(16, "New password must be at most 16 characters")
    .matches(/\d/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm passwords should be same as new password"
    )
    .required("Please enter confirm password"),
});

export const resetPasswordValidationSchema = Yup.object({
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
});

export const otpSchema = Yup.object().shape({
  otp: Yup.string("Please enter your OTP")
    .required("Please enter your OTP")
    .matches(otpRegex, "Please enter 4 digit OTP"),
});

export const editProfileValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Name", "more than one space between two words")
    )
    .matches(
      fullNameRegex,
      valueValidationError("Name", "numbers and special characters")
    ),
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
  profile: Yup.mixed()
    .required("Please upload profile image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});

export const editProfileWithProfileValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Name", "more than one space between two words")
    )
    .matches(
      fullNameRegex,
      valueValidationError("Name", "numbers and special characters")
    ),
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
  profile: Yup.mixed()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});

export const changePasswordValidationSchema = Yup.object({
  oldPassword: Yup.string()
    .required("Please enter old password")
    .matches(passwordRegex, "Please enter valid old password"),
  newPassword: Yup.string("Please enter new password")
    .required("Please enter new Password")
    .min(6, "Password must be at least 8 characters")
    .max(16, "Password must be at most 16 characters")
    .matches(/\d/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Confirm password should be same as new password"
    )
    .required("Please enter confirm password"),
});

export const rolePermissionSchema = Yup.object().shape({
  role: Yup.string("Please enter role")
    .required("Please enter role")
    .test(
      "len",
      "Role can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .test(
      "len",
      "Role can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Role", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Role", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Role",
        "numbers and special characters except for &"
      )
    ),
});
export const rolePermissionEditSchema = Yup.object().shape({
  role: Yup.string("Enter role")
    .required("Please enter role")
    .test(
      "len",
      "Role can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .test(
      "len",
      "Role can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Role", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Role", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Role",
        "numbers and special characters except for &"
      )
    ),
});

export const cmsSchema = Yup.object().shape({
  cmsText: Yup.string()
    .required("Please add")
    .matches(companyNameRagex, "Please enter valid text"),
});

export const subAdminSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter name")
    .test(
      "len",
      "Name can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .test(
      "len",
      "Name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Name", "more than one space between two words")
    )
    .matches(
      fullNameRegex,
      valueValidationError("Name", "numbers and special characters")
    ),
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
  password: Yup.string()
    .required("Please enter password")
    .matches(/\d/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
  roleId: Yup.string().required("Please select role"),
});

export const subAdminEditSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter name")
    .test(
      "len",
      "Name can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .test(
      "len",
      "Name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Name", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Name",
        "numbers and special characters except for &"
      )
    ),
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
  password: Yup.string()
    .matches(/\d/, getCharacterValidationError("digit"))
    .matches(/[a-z]/, getCharacterValidationError("lowercase"))
    .matches(/[A-Z]/, getCharacterValidationError("uppercase"))
    .matches(/[^\w\s]/, getCharacterValidationError("special")),
  roleId: Yup.string().required("Please select role"),
});

export const userBlockedSchema = Yup.object().shape({
  reason: Yup.string()
    .trim()
    .required("Please enter reason")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Reason", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Reason", "more than one space between two words")
    )
    .matches(companyNameRagex, "Please enter valid reason"),
  // .test(
  //   "len",
  //   "Maximum 20 characters allowed",
  //   (val) => val?.length <= 20
  // )
  // .test("len", "Minimum 3 characters required", (val) => val?.length >= 3)
  // .matches(
  //   nameWithSpaceAndSpecialCaracterAnd,
  //   "Please provide valid Reason name"
  // ),
});

export const clientCategorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter category name")
    .test(
      "len",
      "Category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Category name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Category name",
        "numbers and special characters except for &"
      )
    ),
});
export const clientSubCategorySchema = Yup.object().shape({
  categoryId: Yup.string().required("Please select category name"),
  subCategoryName: Yup.string()
    .required("Please enter sub category name")
    .test(
      "len",
      "Sub category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Sub category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Sub category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "sub Category name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Sub Category name",
        "numbers and special characters except for &"
      )
    ),
});
export const clientMediaPatformSchema = Yup.object().shape({
  mediaPlatform: Yup.string()
    .required("Please enter media platform name")
    .test(
      "len",
      "Media platform name can not be more than 40 characters",
      (val) => val?.length <= 100
    )
    .matches(
      companyNameRagex,
      valueValidationError(
        "Media platform name",
        "Please enter valid media platform name"
      )
    ),
});
export const categorySchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter category name")
    .test(
      "len",
      "Category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Category name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Category name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .required("Please uplaod image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 165 && img.height == 143) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});
export const categoryEditSchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter category name")
    .test(
      "len",
      "Category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Category name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Category name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 165 && img.height == 143) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});
export const billingInformation = Yup.object().shape({
  companyName: Yup.string()
    .required("Please enter company name")
    .test(
      "len",
      "Company name can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Company name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Company name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid company name"),

  address: Yup.string()
    .nullable()
    .test(
      "len",
      "Address can not be more than 500 characters",
      (val) => !val || (val && val.length <= 500) // Pass validation if address is empty or its length is less than or equal to 500
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),

  number: Yup.string()
    .nullable()
    .matches(
      /^(?!([0-9])\1+$)[1-9][0-9]{9,14}$/,
      "Please enter valid mobile number"
    )
    .test(
      "len",
      "Mobile number must be exactly 10 characters",
      (val) => (val ? val.length === 10 : true) // Pass validation if number is empty or null
    ),

  email: Yup.string()
    .nullable()
    .test("valid-email", "Please enter valid email id", function (value) {
      if (!value || value.trim() === "") {
        return true;
      }
      if (/\.{2,}/.test(value)) {
        return false;
      }
      if (!value.match(emailRegex)) {
        return false;
      }
      return value.length <= 320;
    }),

  GSTNo: Yup.string()
    .nullable()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "Please enter valid GST no."
    )
    .test("optional", "GST no. is required", function (value) {
      if (!value || value.trim() === "") {
        return true; // Pass validation if GST number is empty or null
      }
      return this.parent.GSTNo && this.parent.GSTNo.trim() !== "";
    }),

  PANNo: Yup.string()
    .nullable()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid PAN no.")
    .test(
      "len",
      "PAN no. must be exactly 10 characters",
      (val) => (val ? val.length === 10 : true) // Pass validation if PAN number is empty or null
    )
    .test(
      "matchGSTNo",
      "PAN number should match a portion of GST number",
      function (val) {
        const GSTNo = this.parent.GSTNo;
        if (!val || !GSTNo) {
          return true; // Pass validation if either PAN or GST number is empty or null
        }
        const extractedPartOfGST = GSTNo.slice(2, 12);
        return val === extractedPartOfGST;
      }
    ),
});

export const stateSchema = Yup.object().shape({
  stateName: Yup.string()
    .required("Please enter state name")
    .test(
      "len",
      "State name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "State name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("State name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "State name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "State name",
        "numbers and special characters except for &"
      )
    ),
  // .matches(fullNameRegex, "Please enter valid state name"),
});

export const FAQSchema = Yup.object().shape({
  question: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Question", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Question", "more than one space between two words")
    )
    .matches(companyNameRagex, "Please enter valid question")
    .required("Please enter question")
    .max(100, "Question can not be more than 100 characters"),
  answer: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Answer", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Answer", "more than one space between two words")
    )
    .matches(companyNameRagex, "Please enter valid answer")
    .required("Please enter answer")
    .max(500, "Answer can not be more than 500 characters"),
});

export const zoneAddSchema = Yup.object().shape({
  state: Yup.string().required("Please select state"),
  zoneName: Yup.string()
    .required("Please enter zone name")
    .test(
      "len",
      "Zone name can not be more than 25 characters",
      (val) => val?.length <= 25
    )
    .test(
      "len",
      "Zone name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Zone name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Zone name", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Zone name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .required("Please upload image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});

export const zoneEditSchema = Yup.object().shape({
  state: Yup.string().required("Please select state"),
  zoneName: Yup.string()
    .required("Please enter zone name")
    .test(
      "len",
      "Zone name can not be more than 25 characters",
      (val) => val?.length <= 25
    )
    .test(
      "len",
      "Zone name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Zone name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Zone name", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Zone name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});

export const districtAddSchema = Yup.object().shape({
  zone: Yup.string().required("Please select zone"),
  districtName: Yup.string()
    .required("Please enter district name")
    .test(
      "len",
      "District name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "District name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("District name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "District name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "District name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .required("Please upload image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});

export const districtEditSchema = Yup.object().shape({
  zone: Yup.string().required("Please select zone"),
  districtName: Yup.string()
    .required("Please enter district name")
    .test(
      "len",
      "District name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "District name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("District name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "District name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "District name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});

export const tagSchema = Yup.object().shape({
  tag: Yup.string()
    .required("Please enter tag name")
    .test(
      "len",
      "Tag name can not be more than 40 characters",
      (val) => !val || val?.length <= 40
    )
    .test(
      "len",
      "Tag name can not be less than 2 characters",
      (val) => !val || val?.length >= 2
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Tag name", "spaces at start and end")
    )
    .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
    .matches(
      tagRegex,
      valueValidationError(
        "Tag name",
        "numbers, spaces and special characters except for _ & and -"
      )
    ),
});

export const newsAddSchema = Yup.object().shape({
  checked: Yup.mixed().transform((value, originalValue) => {
    if (typeof originalValue === "string") {
      return originalValue === "true";
    }
    return value;
  }),
  // .test(
  //   "at-least-one-checked",
  //   "At least one checkbox must be checked",
  //   function (value) {
  //     const checked = value?.filter((item) => item?.checked !== false);
  //     return checked?.length !== 0;
  //   }
  // )
  // .required(),
  newsTitle: Yup.string()
    .required("Please enter video title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Video title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Video title",
        "more than one space between two words"
      )
    ),
  // .matches(companyNameRagex, "Please enter valid video title")
  description: Yup.string()
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(1000, "Description can not be more than 1000 characters"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  location: Yup.string(),
  category: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string().required(),
        categoryName: Yup.string().required(),
      })
    )
    .required("Please select category")
    .min(1, "Please select at least one category"),
  postNo: Yup.string().nullable(),
  state: Yup.string(),
  zone: Yup.string(),
  district: Yup.string(),
  city: Yup.string()
    .notRequired()
    .nullable()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),

  youtube_link: Yup.string().when(
    "checked",
    (checked, schema, youtube_link) => {
      // const reelsChecked = checked.map((item) =>
      //   item.some((checkedData) => checkedData.checked === "Reels")
      // );
      if (
        youtube_link?.context?.video === undefined &&
        youtube_link?.context?.internal_link === undefined
      ) {
        // if (reelsChecked[0]) {
        //   return schema.notRequired();
        // } else {
        return schema
          .matches(
            /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
            "Please enter valid URL"
          )
          .required("Please enter youtube link");
        // }
      } else if (youtube_link) {
        return schema.matches(
          /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
          "Please enter valid URL"
        );
      } else {
        return schema.notRequired();
      }
    }
  ),
  video: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.youtube_link === undefined)
    );
    const internal_link = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.internal_link === undefined)
    );
    if (reelsChecked?.[0] && internal_link?.[0]) {
      return schema.required("Please upload video");
    } else {
      return schema.notRequired().nullable();
    }
  }),

  internal_link: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.youtube_link === undefined)
    );
    const videoLink = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.video === undefined)
    );
    if (reelsChecked?.[0] && videoLink?.[0]) {
      return schema
        .required("Please enter internal server link")
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            return value && value.includes(`${siteSetting}/api/images`);
          }
        );
    } else {
      return schema
        .notRequired()
        .nullable()
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            if (value) {
              return value && value.includes(`${siteSetting}/api/images`);
            } else {
              return true;
            }
          }
        );
    }
  }),
  image: Yup.mixed().when("youtube_link", (youtube_link, schema, checked) => {
    let checkedReels = checked?.context?.checked?.filter(
      (ele) => ele?.checked == true
    )?.[0]?.label;
    if (
      youtube_link[0] ||
      checked?.context?.internal_link ||
      checked?.context?.video
    ) {
      return schema.notRequired().nullable();
    } else {
      return schema
        .required("Please upload thumbnail")
        .test("FILE_FORMAT", "Only jpg, jpeg, png files allowed", (value) => {
          return (
            !value ||
            (["jpg", "jpeg", "png"].includes(
              value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
            ) &&
              ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
          );
        })
        .test(
          "fileSize",
          "File size too large, max file size is 2.048 Mb",
          (file) => {
            if (file) {
              return file.size <= 2048000;
            } else {
              return true;
            }
          }
        );
    }
  }),
  liveNewsImage: Yup.mixed().when("checked", (checked, schema) => {
    let checkedReels = checked?.context?.checked?.filter(
      (ele) => ele?.checked == true
    )?.[0]?.label;
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Live");
    if (reelsChecked) {
      return (
        schema
          .required("Please upload live news image")
          .test("FILE_FORMAT", "Only jpg, jpeg, png files allowed", (value) => {
            return (
              !value ||
              (["jpg", "jpeg", "png"].includes(
                value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
              ) &&
                ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
            );
          })
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (checkedReels == "Reels") {
          //           if (img.width == 108 && img.height == 139) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         } else {
          //           if (img.width == 135 && img.height == 100) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 2.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 2048000;
              } else {
                return true;
              }
            }
          )
      );
    } else {
      return schema.notRequired();
    }
  }),
  adsPlay: Yup.string().required("Select advertisement play"),
  // adsPart: Yup.mixed()
  //   .test(
  //     "at-least-one-checked",
  //     "At least one checkbox must be checked",
  //     function (value) {
  //       const checked = value?.filter((item) => item?.checked !== false);
  //       return checked?.length !== 0;
  //     }
  //   )
  //   .required(),
});

export const newsEditSchema = Yup.object().shape({
  checked: Yup.mixed().transform((value, originalValue) => {
    if (typeof originalValue === "string") {
      return originalValue === "true";
    }
    return value;
  }),
  // .test(
  //   "at-least-one-checked",
  //   "At least one checkbox must be checked",
  //   function (value) {
  //     const checked = value?.filter((item) => item?.checked !== false);
  //     return checked?.length !== 0;
  //   }
  // )
  // .required(),
  newsTitle: Yup.string()
    .required("Please enter video title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Video title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Video title",
        "more than one space between two words"
      )
    ),
  // .matches(companyNameRagex, "Please enter valid Video title")
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(1000, "Description can not be more than 1000 characters"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  location: Yup.string(),
  category: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string().required(),
        categoryName: Yup.string().required(),
      })
    )
    .required("Please select category")
    .min(1, "Please select at least one category"),
  postNo: Yup.string().nullable(),
  state: Yup.string(),
  zone: Yup.string(),
  district: Yup.string(),
  city: Yup.string()
    .notRequired()
    .nullable()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),

  youtube_link: Yup.string().when(
    "checked",
    (checked, schema, youtube_link) => {
      // const reelsChecked = checked.map((item) =>
      //   item.some((checkedData) => checkedData.checked === "Reels")
      // );
      if (
        !youtube_link?.context?.video &&
        !youtube_link?.context?.internal_link
      ) {
        // if (reelsChecked[0]) {
        //   return schema.notRequired();
        // } else {
        return schema
          .matches(
            /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
            "Please enter valid URL"
          )
          .required("Please enter youtube link");
        // }
      } else if (youtube_link) {
        return schema.matches(
          /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
          "Please enter valid URL"
        );
      } else {
        return schema.notRequired();
      }
    }
  ),
  video: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.youtube_link)
    );
    const internal_link = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.internal_link)
    );
    if (reelsChecked?.[0] && internal_link?.[0]) {
      return schema.required("Please upload video");
    } else {
      return schema.notRequired().nullable();
    }
  }),

  internal_link: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.youtube_link)
    );
    const videoLink = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.video)
    );
    if (reelsChecked?.[0] && videoLink?.[0]) {
      return schema
        .required("Please enter internal server link")
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            return value && value.includes(`${siteSetting}/api/images`);
          }
        );
    } else {
      return schema
        .notRequired()
        .nullable()
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            if (value) {
              return value && value.includes(`${siteSetting}/api/images`);
            } else {
              return true;
            }
          }
        );
    }
  }),
  image: Yup.mixed().when("checked", (checked, schema) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    schema.nullable();
    schema
      .notRequired()
      .test(
        "FILE_FORMAT",
        "Only jpg, jpeg, png files allowed",
        (value) =>
          !value ||
          (["jpg", "jpeg", "png"].includes(
            value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
          ) &&
            ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
      )
      // .test(
      //   "imageDimensions",
      //   "Image dimensions are not within the allowed range",
      //   (value) => {
      //     if (!value) return true;
      //     const img = new Image();
      //     img.src = window.URL.createObjectURL(value);
      //     return new Promise((resolve, reject) => {
      //       img.onload = () => {
      //         if (reelsChecked) {
      //           if (img.width == 108 && img.height == 139) {
      //             resolve(true); // dimensions are within the allowed range
      //           } else {
      //             resolve(false); // dimensions are not within the allowed range
      //           }
      //         } else {
      //           if (img.width == 135 && img.height == 100) {
      //             resolve(true); // dimensions are within the allowed range
      //           } else {
      //             resolve(false); // dimensions are not within the allowed range
      //           }
      //         }
      //       };
      //       img.onerror = reject;
      //     });
      //   }
      // )
      .test(
        "fileSize",
        "File size too large, max file size is 2.048 Mb",
        (file) => {
          if (file) {
            return file.size <= 2048000;
          } else {
            return true;
          }
        }
      );
  }),
  liveNewsImage: Yup.mixed().when("checked", (checked, schema) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Live");
    const checkedReels = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    if (reelsChecked) {
      return (
        schema
          .nullable()
          .notRequired()
          .test(
            "FILE_FORMAT",
            "Only jpg, jpeg, png files allowed",
            (value) =>
              !value ||
              (["jpg", "jpeg", "png"].includes(
                value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
              ) &&
                ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (checkedReels) {
          //           if (img.width == 108 && img.height == 139) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         } else {
          //           if (img.width == 135 && img.height == 100) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 2.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 2048000;
              } else {
                return true;
              }
            }
          )
      );
    } else {
      return schema.notRequired();
    }
  }),
  adsPlay: Yup.string().required("Select advertisement play"),
  // adsPart: Yup.mixed()
  //   .test(
  //     "at-least-one-checked",
  //     "At least one checkbox must be checked",
  //     function (value) {
  //       const checked = value?.filter((item) => item?.checked !== false);
  //       return checked?.length !== 0;
  //     }
  //   )
  //   .required(),
});
export const newsAddDraftSchema = Yup.object().shape({
  checked: Yup.mixed().transform((value, originalValue) => {
    if (typeof originalValue === "string") {
      return originalValue === "true";
    }
    return value;
  }),
  newsTitle: Yup.string()
    .required("Please enter video title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Video title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Video title",
        "more than one space between two words"
      )
    ),
  // .matches(companyNameRagex, "Please enter valid Video title")
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .min(20, "Description can not be less than 20 characters")
    .max(1000, "Description can not be more than 1000 characters"),

  tags: Yup.array().of(
    Yup.string()
      .test(
        "len",
        "Tag name can not be more than 40 characters",
        (val) => !val || val?.length <= 40
      )
      .test(
        "len",
        "Tag name can not be less than 2 characters",
        (val) => !val || val?.length >= 2
      )
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Tag name", "spaces at start and end")
      )
      .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
      .matches(
        tagRegex,
        valueValidationError("Tag", "special characters except for _ & and -")
      )
  ),
  location: Yup.string(),
  category: Yup.array().of(
    Yup.object().shape({
      _id: Yup.string().required(),
      categoryName: Yup.string().required(),
    })
  ),
  postNo: Yup.string().nullable(),
  state: Yup.string(),
  zone: Yup.string(),
  district: Yup.string(),
  city: Yup.string()
    .notRequired()
    .nullable()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),

  youtube_link: Yup.string().when(
    "checked",
    (checked, schema, youtube_link) => {
      // const reelsChecked = checked.map((item) =>
      //   item.some((checkedData) => checkedData.checked === "Reels")
      // );
      if (
        youtube_link?.context?.action !== "Draft" &&
        youtube_link?.context?.video === undefined &&
        youtube_link?.context?.internal_link === undefined
      ) {
        // if (reelsChecked[0]) {
        //   return schema.notRequired();
        // } else {
        return schema
          .matches(
            /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
            "Please enter valid URL"
          )
          .required("Please enter youtube link");
        // }
      } else if (youtube_link) {
        return schema.matches(
          /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
          "Please enter valid URL"
        );
      } else {
        return schema.notRequired();
      }
    }
  ),
  video: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.youtube_link === undefined)
    );
    const internal_link = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.internal_link === undefined)
    );
    if (
      video?.context?.action !== "Draft" &&
      reelsChecked?.[0] &&
      internal_link?.[0]
    ) {
      return schema.required("Please upload video");
    } else {
      return schema.notRequired().nullable();
    }
  }),
  internal_link: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.youtube_link === undefined)
    );
    const videoLink = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.video === undefined)
    );
    if (
      video?.context?.action !== "Draft" &&
      reelsChecked?.[0] &&
      videoLink?.[0]
    ) {
      return schema
        .required("Please enter internal server link")
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            return value && value.includes(`${siteSetting}/api/images`);
          }
        );
    } else {
      return schema
        .notRequired()
        .nullable()
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            if (value) {
              return value && value.includes(`${siteSetting}/api/images`);
            } else {
              return true;
            }
          }
        );
    }
  }),
  image: Yup.mixed().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    schema.nullable();
    schema
      .notRequired()
      .test(
        "FILE_FORMAT",
        "Only jpg, jpeg, png files allowed",
        (value) =>
          !value ||
          (["jpg", "jpeg", "png"].includes(
            value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
          ) &&
            ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
      )
      // .test(
      //   "imageDimensions",
      //   "Image dimensions are not within the allowed range",
      //   (value) => {
      //     if (!value) return true;
      //     const img = new Image();
      //     img.src = window.URL.createObjectURL(value);
      //     return new Promise((resolve, reject) => {
      //       img.onload = () => {
      //         if (reelsChecked) {
      //           if (img.width == 108 && img.height == 139) {
      //             resolve(true); // dimensions are within the allowed range
      //           } else {
      //             resolve(false); // dimensions are not within the allowed range
      //           }
      //         } else {
      //           if (img.width == 135 && img.height == 100) {
      //             resolve(true); // dimensions are within the allowed range
      //           } else {
      //             resolve(false); // dimensions are not within the allowed range
      //           }
      //         }
      //       };
      //       img.onerror = reject;
      //     });
      //   }
      // )
      .test(
        "fileSize",
        "File size too large, max file size is 2.048 Mb",
        (file) => {
          if (file) {
            return file.size <= 2048000;
          } else {
            return true;
          }
        }
      );
  }),
  liveNewsImage: Yup.mixed().when("checked", (checked, schema) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Live");
    const checkedReels = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    if (reelsChecked) {
      return (
        schema
          .required("Please upload live news image")
          .test(
            "FILE_FORMAT",
            "Only jpg, jpeg, png files allowed",
            (value) =>
              !value ||
              (["jpg", "jpeg", "png"].includes(
                value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
              ) &&
                ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (checkedReels) {
          //           if (img.width == 108 && img.height == 139) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         } else {
          //           if (img.width == 135 && img.height == 100) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 2.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 2048000;
              } else {
                return true;
              }
            }
          )
      );
    } else {
      return schema.notRequired();
    }
  }),
  adsPlay: Yup.string().required("Select advertisement play"),
  // adsPart: Yup.mixed()
  //   .test(
  //     "at-least-one-checked",
  //     "At least one checkbox must be checked",
  //     function (value) {
  //       const checked = value?.filter((item) => item?.checked !== false);
  //       return checked?.length !== 0;
  //     }
  //   )
  //   .required(),
});

export const newsAddSchemaWithShedule = Yup.object().shape({
  checked: Yup.mixed().transform((value, originalValue) => {
    if (typeof originalValue === "string") {
      return originalValue === "true";
    }
    return value;
  }),
  // .test(
  //   "at-least-one-checked",
  //   "At least one checkbox must be checked",
  //   function (value) {
  //     const checked = value?.filter((item) => item?.checked !== false);
  //     return checked?.length !== 0;
  //   }
  // )
  // .required(),
  newsTitle: Yup.string()
    .required("Please enter video title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Video title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Video title",
        "more than one space between two words"
      )
    ),
  // .matches(companyNameRagex, "Please enter valid Video title")
  description: Yup.string()
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(1000, "Description can not be more than 1000 characters"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  location: Yup.string(),
  category: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string().required(),
        categoryName: Yup.string().required(),
      })
    )
    .required("Please select category")
    .min(1, "Please select at least one category"),
  postNo: Yup.string().nullable(),
  state: Yup.string(),
  zone: Yup.string(),
  district: Yup.string(),
  city: Yup.string()
    .notRequired()
    .nullable()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),

  youtube_link: Yup.string().when(
    "checked",
    (checked, schema, youtube_link) => {
      // const reelsChecked = checked.map((item) =>
      //   item.some((checkedData) => checkedData.checked === "Reels")
      // );
      if (
        youtube_link?.context?.video === undefined &&
        youtube_link?.context?.internal_link === undefined
      ) {
        // if (reelsChecked[0]) {
        //   return schema.notRequired();
        // } else {
        return schema
          .matches(
            /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
            "Please enter valid URL"
          )
          .required("Please enter youtube link");
        // }
      } else if (youtube_link) {
        return schema.matches(
          /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
          "Please enter valid URL"
        );
      } else {
        return schema.notRequired();
      }
    }
  ),
  video: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.youtube_link === undefined)
    );
    const internal_link = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.internal_link === undefined)
    );
    if (reelsChecked?.[0] && internal_link?.[0]) {
      return schema.required("Please upload video");
    } else {
      return schema.notRequired().nullable();
    }
  }),

  internal_link: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.youtube_link === undefined)
    );
    const videoLink = checked?.map((item) =>
      item?.some((checkedData) => video?.parent?.video === undefined)
    );
    if (reelsChecked?.[0] && videoLink?.[0]) {
      return schema
        .required("Please enter internal server link")
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            return value && value.includes(`${siteSetting}/api/images`);
          }
        );
    } else {
      return schema
        .notRequired()
        .nullable()
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            if (value) {
              return value && value.includes(`${siteSetting}/api/images`);
            } else {
              return true;
            }
          }
        );
    }
  }),
  image: Yup.mixed().when("youtube_link", (youtube_link, schema, checked) => {
    let checkedReels = checked?.context?.checked?.filter(
      (ele) => ele?.checked == true
    )?.[0]?.label;
    if (
      youtube_link[0] ||
      checked?.context?.internal_link ||
      checked?.context?.video
    ) {
      return schema.notRequired().nullable();
    } else {
      return (
        schema
          .required("Please upload thumbnail")
          .test(
            "FILE_FORMAT",
            "Only jpg, jpeg, png files allowed",
            (value) =>
              !value ||
              (["jpg", "jpeg", "png"].includes(
                value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
              ) &&
                ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (checkedReels == "Reels") {
          //           if (img.width == 108 && img.height == 139) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         } else {
          //           if (img.width == 135 && img.height == 100) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 2.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 2048000;
              } else {
                return true;
              }
            }
          )
      );
    }
  }),

  liveNewsImage: Yup.mixed().when("checked", (checked, schema) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Live");
    const checkedReels = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    if (reelsChecked) {
      return (
        schema
          .required("Please upload live news image")
          .test(
            "FILE_FORMAT",
            "Only jpg, jpeg, png files allowed",
            (value) =>
              !value ||
              (["jpg", "jpeg", "png"].includes(
                value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
              ) &&
                ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (checkedReels) {
          //           if (img.width == 108 && img.height == 139) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         } else {
          //           if (img.width == 135 && img.height == 100) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 2.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 2048000;
              } else {
                return true;
              }
            }
          )
      );
    } else {
      return schema.notRequired();
    }
  }),
  adsPlay: Yup.string().required("Select advertisement play"),
  // adsPart: Yup.mixed()
  //   .test(
  //     "at-least-one-checked",
  //     "At least one checkbox must be checked",
  //     function (value) {
  //       const checked = value?.filter((item) => item?.checked !== false);
  //       return checked?.length !== 0;
  //     }
  //   )
  //   .required(),
  date: Yup.date()
    .required("Please select date and time")
    .min(new Date(), "Date and time must be in the future")
    .when(["action"], (action, schema) => {
      return action === "Schedule" // Changed from === to ==
        ? schema.min(new Date(), "Selected date and time must be in the future")
        : schema;
    }),
});

export const newsEditSchemaWithShedule = Yup.object().shape({
  checked: Yup.mixed().transform((value, originalValue) => {
    if (typeof originalValue === "string") {
      return originalValue === "true";
    }
    return value;
  }),
  // .test(
  //   "at-least-one-checked",
  //   "At least one checkbox must be checked",
  //   function (value) {
  //     const checked = value?.filter((item) => item?.checked !== false);
  //     return checked?.length !== 0;
  //   }
  // )
  // .required(),
  newsTitle: Yup.string()
    .required("Please enter video title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Video title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Video title",
        "more than one space between two words"
      )
    ),
  // .matches(companyNameRagex, "Please enter valid Video title")
  description: Yup.string()
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(1000, "Description can not be more than 1000 characters"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  location: Yup.string(),
  category: Yup.array()
    .of(
      Yup.object().shape({
        _id: Yup.string().required(),
        categoryName: Yup.string().required(),
      })
    )
    .required("Please select category")
    .min(1, "Please select at least one category"),
  postNo: Yup.string().nullable(),
  state: Yup.string(),
  zone: Yup.string(),
  district: Yup.string(),
  city: Yup.string()
    .notRequired()
    .nullable()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),

  youtube_link: Yup.string().when(
    "checked",
    (checked, schema, youtube_link) => {
      // const reelsChecked = checked.map((item) =>
      //   item.some((checkedData) => checkedData.checked === "Reels")
      // );
      if (
        !youtube_link?.context?.video &&
        !youtube_link?.context?.internal_link
      ) {
        // if (reelsChecked[0]) {
        //   return schema.notRequired();
        // } else {
        return schema
          .matches(
            /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
            "Please enter valid URL"
          )
          .required("Please enter youtube link");
        // }
      } else if (youtube_link) {
        return schema.matches(
          /^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|shorts\/)|youtu\.be\/)[a-zA-Z0-9_-]{11}$/,
          "Please enter valid URL"
        );
      } else {
        return schema.notRequired();
      }
    }
  ),
  video: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.youtube_link)
    );
    const internal_link = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.internal_link)
    );
    if (reelsChecked?.[0] && internal_link?.[0]) {
      return schema.required("Please upload video");
    } else {
      return schema.notRequired().nullable();
    }
  }),

  internal_link: Yup.string().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.youtube_link)
    );
    const videoLink = checked?.map((item) =>
      item?.some((checkedData) => !video?.parent?.video)
    );
    if (reelsChecked?.[0] && videoLink?.[0]) {
      return schema
        .required("Please enter internal server link")
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            return value && value.includes(`${siteSetting}/api/images`);
          }
        );
    } else {
      return schema
        .notRequired()
        .nullable()
        .test(
          "is-valid-url",
          `Internal server link must include ${siteSetting}/api/images`,
          (value) => {
            if (value) {
              return value && value.includes(`${siteSetting}/api/images`);
            } else {
              return true;
            }
          }
        );
    }
  }),
  image: Yup.mixed().when("checked", (checked, schema, video) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    schema.nullable();
    schema
      .notRequired()
      .test(
        "FILE_FORMAT",
        "Only jpg, jpeg, png files allowed",
        (value) =>
          !value ||
          (["jpg", "jpeg", "png"].includes(
            value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
          ) &&
            ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
      )
      // .test(
      //   "imageDimensions",
      //   "Image dimensions are not within the allowed range",
      //   (value) => {
      //     if (!value) return true;
      //     const img = new Image();
      //     img.src = window.URL.createObjectURL(value);
      //     return new Promise((resolve, reject) => {
      //       img.onload = () => {
      //         if (reelsChecked) {
      //           if (img.width == 108 && img.height == 139) {
      //             resolve(true); // dimensions are within the allowed range
      //           } else {
      //             resolve(false); // dimensions are not within the allowed range
      //           }
      //         } else {
      //           if (img.width == 135 && img.height == 100) {
      //             resolve(true); // dimensions are within the allowed range
      //           } else {
      //             resolve(false); // dimensions are not within the allowed range
      //           }
      //         }
      //       };
      //       img.onerror = reject;
      //     });
      //   }
      // )
      .test(
        "fileSize",
        "File size too large, max file size is 2.048 Mb",
        (file) => {
          if (file) {
            return file.size <= 2048000;
          } else {
            return true;
          }
        }
      );
  }),
  liveNewsImage: Yup.mixed().when("checked", (checked, schema) => {
    const reelsChecked = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Live");
    const checkedReels = checked?.[0]
      ?.filter((e) => e.checked == true)?.[0]
      ?.label?.includes("Reels");
    if (reelsChecked) {
      return (
        schema
          .nullable()
          .notRequired()
          .required("Please upload live news image")
          .test(
            "FILE_FORMAT",
            "Only jpg, jpeg, png files allowed",
            (value) =>
              !value ||
              (["jpg", "jpeg", "png"].includes(
                value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
              ) &&
                ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (checkedReels) {
          //           if (img.width == 108 && img.height == 139) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         } else {
          //           if (img.width == 135 && img.height == 100) {
          //             resolve(true); // dimensions are within the allowed range
          //           } else {
          //             resolve(false); // dimensions are not within the allowed range
          //           }
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 2.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 2048000;
              } else {
                return true;
              }
            }
          )
      );
    } else {
      return schema.notRequired();
    }
  }),
  adsPlay: Yup.string().required("Select advertisement play"),
  // adsPart: Yup.mixed()
  //   .test(
  //     "at-least-one-checked",
  //     "At least one checkbox must be checked",
  //     function (value) {
  //       const checked = value?.filter((item) => item?.checked !== false);
  //       return checked?.length !== 0;
  //     }
  //   )
  //   .required(),
  date: Yup.date()
    .min(new Date(), "Date and time must be in the future")
    .when(["action"], (action, schema) => {
      return action === "Schedule" // Changed from === to ==
        ? schema.min(new Date(), "Selected date and time must be in the future")
        : schema;
    })
    .when(["action"], (action, schema) => {
      return action === "Schedule" // Changed from === to ==
        ? schema.required("Please select date and time")
        : schema;
    }),
});

export const actorSchema = Yup.object().shape({
  actorName: Yup.string()
    .required("Please enter cast/crew member name")
    .test(
      "len",
      "Cast/Crew member name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Cast/Crew member name can not be less than 2 characters",
      (val) => val?.length >= 2
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Cast/Crew member name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Cast/Crew member name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Cast/Crew member name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .required("Please upload image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});
export const actorEditSchema = Yup.object().shape({
  actorName: Yup.string()
    .required("Please enter cast/crew member name")
    .test(
      "len",
      "Cast/Crew member name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Cast/Crew member name can not be less than 2 characters",
      (val) => val?.length >= 2
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Cast/Crew member name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Cast/Crew member name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Cast/Crew member name",
        "numbers and special characters except for &"
      )
    ),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});
export const movieSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter movie name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Movie name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Movie name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid movie name")
    .test(
      "len",
      "Movie name can not be more than 40 characters",
      (val) => val?.length <= 40
    ),
  releaseDate: Yup.date()
    .required("Please select release date")
    .typeError("Please enter valid date"),
  duration: Yup.string()
    .required("Please enter duration")
    .matches(/^\d{1,4}$/, "Please enter valid duration"),
  censorRating: Yup.string().required("Please enter censor rating"),
  language: Yup.string()
    .required("Please enter language")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Language", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Language", "more than one space between two words")
    )
    .matches(
      textAndSpaceOnly,
      valueValidationError("Language", "numbers and special characters")
    ),
  movieType: Yup.string().required("Please select movie type"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  genreCategory: Yup.array()
    .required("Please select genre/category")
    .min(1, "Please select at least one genre/category"),
  cast: Yup.array()
    .of(
      Yup.object().shape({
        castId: Yup.string().required(),
      })
    )
    .required("Please select cast")
    .min(1, "Please select at least one cast"),
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(250, "Description can not be more than 250 characters"),
  movieLink: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter movie link"),
  movieTrailerLink: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter movie trailer link"),
  image: Yup.mixed()
    .required("Please upload image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});
export const movieEditSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter movie name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Movie name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Movie name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid movie name")
    .test(
      "len",
      "Movie name can not be more than 40 characters",
      (val) => val?.length <= 40
    ),
  duration: Yup.string()
    .required("Please enter duration")
    .matches(/^\d{1,4}$/, "Please enter valid duration"),
  censorRating: Yup.string().required("Please enter censor rating"),
  language: Yup.string()
    .required("Please enter language")
    .matches(textAndSpaceOnly, "Please enter valid language"),
  movieType: Yup.string().required("Please select movie type"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  genreCategory: Yup.array()
    .required("Please select genre/category")
    .required("Please enter genre/category")
    .min(1, "Please select at least one genre/category"),
  cast: Yup.array()
    .of(
      Yup.object().shape({
        castId: Yup.string().required(),
      })
    )
    .required("Please select cast")
    .min(1, "Please select at least one cast"),
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(250, "Description can not be more than 250 characters"),
  movieLink: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter movie link"),
  movieTrailerLink: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter movie trailer link"),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
  releaseDate: Yup.date()
    .required("Please select release date")
    .typeError("Please enter valid date"),
});
export const seriesSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Title", "more than one space between two words")
    )
    .matches(companyNameRagex, "Please enter valid Title")
    .test(
      "len",
      "Title can not be less than 2 characters",
      (val) => val?.length >= 2
    )
    .test(
      "len",
      "Title can not be more than 40 characters",
      (val) => val?.length <= 40
    ),
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(250, "Description can not be more than 250 characters"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  genreCategory: Yup.array()
    .required("Please select categories")
    .min(1, "Please select at least one category"),
  movieTrailerLink: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter web series trailer link"),
  image: Yup.mixed()
    .required("Please upload image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});
export const seriesEditSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Title", "more than one space between two words")
    )
    .matches(companyNameRagex, "Please enter valid title")
    .test(
      "len",
      "Title can not be less than 2 characters",
      (val) => val?.length >= 2
    )
    .test(
      "len",
      "Title can not be more than 50 characters",
      (val) => val?.length <= 50
    ),
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(250, "Description can not be more than 250 characters"),
  tags: Yup.array()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    )
    .required("Please select/enter tag")
    .min(1, "Please select at least one tag"),
  genreCategory: Yup.array()
    .required("Please select categories")
    .min(1, "Please select at least one category"),
  movieTrailerLink: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Invalid URL"
    )
    .required("Please enter web series trailer link"),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    // .test(
    //   "imageDimensions",
    //   "Image dimensions are not within the allowed range",
    //   (value) => {
    //     if (!value) return true;
    //     const img = new Image();
    //     img.src = window.URL.createObjectURL(value);
    //     return new Promise((resolve, reject) => {
    //       img.onload = () => {
    //         if (img.width == 135 && img.height == 100) {
    //           resolve(true); // dimensions are within the allowed range
    //         } else {
    //           resolve(false); // dimensions are not within the allowed range
    //         }
    //       };
    //       img.onerror = reject;
    //     });
    //   }
    // )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});
export const seasonSchema = Yup.object().shape({
  season: Yup.string()
    .required("Please enter season name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Season name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Season name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid season name")
    .test(
      "len",
      "Season name can not be less than 2 characters",
      (val) => val?.length >= 2
    )
    .test(
      "len",
      "Season name can not be more than 50 characters",
      (val) => val?.length <= 50
    ),
  cast: Yup.array()
    .of(
      Yup.object().shape({
        castId: Yup.string().required(),
      })
    )
    .required("Please select cast")
    .min(1, "Please select at least one cast"),
});

export const episodeSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter episode name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Episode name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Episode name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid web episode name")
    .test(
      "len",
      "Episode name can not be more than 50 characters",
      (val) => val?.length <= 50
    )
    .test(
      "len",
      "Episode name can not be less than 2 characters",
      (val) => val?.length >= 2
    ),
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(250, "Description can not be more than 250 characters"),
  link: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter episode link"),
  image: Yup.mixed()
    .required("Please upload image")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});
export const episodeEditSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter episode name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Episode name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Episode name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid episode name")
    .test(
      "len",
      "Episode name can not be more than 50 characters",
      (val) => val?.length <= 50
    )
    .test(
      "len",
      "Episode name can not be less than 2 characters",
      (val) => val?.length >= 2
    ),
  description: Yup.string()
    // .matches(
    //   /^(?!\s)(.*\S)?(?<!\s)$/,
    //   valueValidationError("Description", "spaces at start and end")
    // )
    // .matches(
    //   /^(?:(?!\s{2,}).)+$/,
    //   valueValidationError(
    //     "Description",
    //     "more than one space between two words"
    //   )
    // )
    // .matches(/^\S+$/, valueValidationError("Description", "spaces"))
    // .matches(companyNameRagex, "Please enter valid description")
    .required("Please enter description")
    .min(20, "Description can not be less than 20 characters")
    .max(250, "Description can not be more than 250 characters"),
  link: Yup.string()
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid URL"
    )
    .required("Please enter episode link"),
  image: Yup.mixed()
    .nullable()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["image/jpg", "image/jpeg", "image/png"].includes(value?.type))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 2.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 2048000;
        } else {
          return true;
        }
      }
    ),
});

export const exportNewsSchema = Yup.object().shape({
  startDate: Yup.date()
    .required("Please select from date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select to date")
    .typeError("Please enter valid date"),
});

export const adSchema = Yup.object().shape({
  accountDirector: Yup.string().notRequired().nullable(),
  groupHead: Yup.string().notRequired().nullable(),
  manager: Yup.string().notRequired().nullable(),
  companyName: Yup.string()
    .required("Please enter company name")
    .test(
      "len",
      "Company name can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(companyNameRagex, "Please enter valid company name"),
  address1: Yup.string()
    .required("Please enter address 1")
    .test(
      "len",
      "Address 1 can not be more than 500 characters",
      (val) => val?.length && val?.length <= 500
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address 1", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address 1", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),
  address2: Yup.string()
    .notRequired()
    .test("len", "Address 2 can not be more than 500 characters", (val) =>
      val?.length ? val?.length <= 500 : true
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address 2", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address 2", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),
  state: Yup.string().required("Please select state"),
  zone: Yup.string().required("Please select zone"),
  city: Yup.string()
    .required("Please select/enter city")
    .test(
      "len",
      "City can not be more than 200 characters",
      (val) => val?.length <= 200
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),
  brands: Yup.array().of(
    Yup.object().shape({
      brandName: Yup.string()
        .required("Please select/enter brand name")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Brand name", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Brand name",
            "more than one space between two words"
          )
        )
        .matches(companyNameRagex, "Please enter valid brand name")
        .test(
          "len",
          "Brand name can not be more than 100 characters",
          (val) => val?.length <= 100
        ),
      brandTagline: Yup.string()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError(
            "Brand slogan / tagline",
            "spaces at start and end"
          )
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Brand slogan / tagline",
            "more than one spaces between two words"
          )
        )
        // .required("Please select/enter brand slogan / tagline")
        .test(
          "len",
          "Brand slogan / tagline can not be more than 200 characters",
          (val) => (val?.length ? val?.length <= 200 : true)
        ),
      brandAmbassador: Yup.string()
        // .required("Please select/enter brand ambassador")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Brand ambassador", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Brand ambassador",
            "more than one spaces between two words"
          )
        )
        .matches(
          fullNameRegex,
          valueValidationError(
            "Brand ambassador",
            "numbers and special characters"
          )
        )
        .test(
          "len",
          "Brand ambassador can not be more than 50 characters",
          (val) => (val?.length ? val?.length <= 50 : true)
        ),
      mediaToUse: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().required(),
            name: Yup.string().required(),
          })
        )
        .required("Please select/enter media platform use")
        .min(1, "Please select at least one media platform use"),
    })
  ),
  category: Yup.string().required("Please select category"),

  subCategory: Yup.string().required("Please select sub category"),

  adPeriod: Yup.array(),
  firstName: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("First name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "First Name",
        "more than one space between two words"
      )
    )
    .matches(
      firstLastNameRegex,
      valueValidationError("First name", "numbers and special characters")
    )
    .test(
      "len",
      "First name can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .required("Please enter first name"),
  lastName: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Last name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Last Name", "more than one space between two words")
    )
    .matches(
      firstLastNameRegex,
      valueValidationError("Last name", "numbers and special characters")
    )
    .test(
      "len",
      "Last name can not be more than 30 characters",
      (val) => val?.length <= 30
    )
    .required("Please enter last name"),
  designation: Yup.string()
    .required("Please enter designation")
    .matches(designationNameRagex, "Please enter valid designation")
    .test(
      "len",
      "Designation can not be more than 50 characters",
      (val) => val?.length <= 50
    ),
  contactNumber: Yup.string()
    .required("Please enter mobile number")
    .matches(
      /^(?!([0-9])\1+$)[1-9][0-9]{9,14}$/,
      "Please enter valid mobile number"
    )
    .test(
      "len",
      "Mobile number can not be more than 10 characters",
      (val) => val?.length <= 10
    )
    .test(
      "len",
      "Mobile number can not be less than 10 characters",
      (val) => val?.length >= 10
    ),
  emailId: Yup.string()
    .required("Please enter email id")
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .test(
      "len",
      "Email id can not be more than 320 characters",
      (val) => val?.length <= 320
    ),
  fct: Yup.string(),
  nfct: Yup.string(),
  favCelebrity: Yup.array().of(
    Yup.string()
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Celebrity name", "spaces at start and end")
      )
      .matches(
        /^(?:(?!\s{2,}).)+$/,
        valueValidationError(
          "Celebrity name",
          "more than one spaces between two words"
        )
      )
      .matches(
        fullNameRegex,
        valueValidationError("Celebrity name", "numbers and special characters")
      )
  ),
  passion: Yup.array().of(
    Yup.string()
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Passion", "spaces at start and end")
      )
      .matches(
        /^(?:(?!\s{2,}).)+$/,
        valueValidationError(
          "Passion",
          "more than one spaces between two words"
        )
      )
  ),
  socialCause: Yup.array().of(
    Yup.string()
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Social cause", "spaces at start and end")
      )
      .matches(
        /^(?:(?!\s{2,}).)+$/,
        valueValidationError(
          "Social cause",
          "more than one spaces between two words"
        )
      )
  ),
  impactPartnerShip: Yup.string(),

  familyDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required("Please enter name")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Name", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError("Name", "more than one spaces between two words")
        )
        .matches(
          fullNameRegex,
          valueValidationError("Name", "numbers and special characters")
        ),
      relation: Yup.string()
        .required("Please enter relation")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Relation", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Relation",
            "more than one spaces between two words"
          )
        )
        .matches(
          fullNameRegex,
          valueValidationError("Relation", "numbers and special characters")
        ),
      comment: Yup.string()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Comment", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Comment",
            "more than one spaces between two words"
          )
        ),
    })
  ),

  billingCompName: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "len",
      "Company name can not be more than 100 characters",
      (val) => !val || (val && val.length <= 100)
    )
    .matches(companyNameRagex, "Please enter valid company name"),

  address: Yup.string()
    .nullable()
    .test(
      "len",
      "Address can not be more than 500 characters",
      (val) => !val || (val && val.length <= 500) // Pass validation if address is empty or its length is less than or equal to 500
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),

  // number: Yup.string()
  //   .nullable()
  //   .matches(
  //     /^(?!([0-9])\1+$)[1-9][0-9]{9,14}$/,
  //     "Please enter a valid mobile number"
  //   )
  //   .test(
  //     "len",
  //     "Mobile number must be exactly 10 characters",
  //     (val) => (val ? val.length === 10 : true) // Pass validation if number is empty or null
  //   ),

  email: Yup.string()
    .nullable()
    .test("valid-email", "Please enter valid email id", function (value) {
      if (!value || value.trim() === "") {
        return true;
      }
      if (/\.{2,}/.test(value)) {
        return false;
      }
      if (!value.match(emailRegex)) {
        return false;
      }
      return value.length <= 320;
    }),

  GSTNo: Yup.string()
    .nullable()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "Please enter valid GST no."
    )
    .test("optional", "GST no. is required", function (value) {
      if (!value || value.trim() === "") {
        return true; // Pass validation if GST number is empty or null
      }
      return this.parent.GSTNo && this.parent.GSTNo.trim() !== "";
    }),

  PANNo: Yup.string()
    .nullable()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid PAN no.")
    .test(
      "len",
      "PAN no. must be exactly 10 characters",
      (val) => (val ? val.length === 10 : true) // Pass validation if PAN number is empty or null
    )
    .test(
      "matchGSTNo",
      "PAN no. should match a portion of GST no.",
      function (val) {
        const GSTNo = this.parent.GSTNo;
        if (!val || !GSTNo) {
          return true; // Pass validation if either PAN or GST number is empty or null
        }
        const extractedPartOfGST = GSTNo.slice(2, 12);
        return val === extractedPartOfGST;
      }
    ),
  agencyName: Yup.string()
    .nullable()
    .notRequired()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Agency name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Agency name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid agency name")
    .test(
      "len",
      "Agency name can not be more than 100 characters",
      (val) => !val || val?.length <= 100
    ),
  agencyDetails: Yup.array().of(
    Yup.object().shape({
      // role: Yup.string().required("Please select role"),
      name: Yup.string()
        .nullable()
        .notRequired()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Name", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError("Name", "more than one spaces between two words")
        )
        .matches(
          fullNameRegex,
          valueValidationError("Name", "numbers and special characters")
        ),
      contact: Yup.string()
        .nullable()
        .notRequired()
        .matches(
          /^(?!(\d)\1+$)[1-9]\d{9,14}$/,
          "Please enter valid mobile number"
        )
        .test(
          "len",
          "Mobile number can not be more than 10 characters",
          (val) => !val || val?.length <= 10
        )
        .test(
          "len",
          "Mobile number can not be less than 10 characters",
          (val) => !val || val?.length >= 10
        ),
      email: Yup.string()
        .nullable()
        .notRequired()
        .test(
          "consecutive-dots",
          valueValidationError("Email id", "consecutive dots(.)"),
          (value) => !/\.{2,}/.test(value)
        )
        .matches(emailRegex, "Please enter valid email id"),
    })
  ),

  events: Yup.array().of(
    Yup.object().shape({
      eventName: Yup.string().required("Please select event"),
      date: Yup.string()
        .required("Please select date")
        .typeError("Please enter valid date"),
    })
  ),
});
export const adDraftSchema = Yup.object().shape({
  accountDirector: Yup.string().notRequired().nullable(),
  groupHead: Yup.string().notRequired().nullable(),
  manager: Yup.string().notRequired().nullable(),
  companyName: Yup.string()
    .required("Please enter company name")
    .test(
      "len",
      "Company name can not be more than 100 characters",
      (val) => !val || (val && val.length <= 100)
    )
    .matches(companyNameRagex, "Please enter valid company name"),
  address1: Yup.string()
    .notRequired()
    .test(
      "len",
      "Address 1 can not be more than 500 characters",
      (val) => !val || (val && val.length <= 500)
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address 1", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address 1", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),
  address2: Yup.string()
    .notRequired()
    .test(
      "len",
      "Address 2 can not be more than 500 characters",
      (val) => (val) => !val || (val && val.length <= 500)
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address 2", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address 2", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),
  state: Yup.string().notRequired(),
  zone: Yup.string().notRequired(),
  city: Yup.string()
    .notRequired()
    .test(
      "len",
      "City can not be more than 200 characters",
      (val) => !val || (val && val.length <= 200)
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("City", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("City", "more than one space between two words")
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "City",
        "numbers and special characters except for &"
      )
    ),
  brands: Yup.array().of(
    Yup.object().shape({
      brandName: Yup.string()
        .required("Please select/enter brand name")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Brand name", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Brand name",
            "more than one space between two words"
          )
        )
        .matches(companyNameRagex, "Please enter valid brand name")
        .test(
          "len",
          "Brand name can not be more than 100 characters",
          (val) => val?.length <= 100
        ),
      brandTagline: Yup.string()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError(
            "Brand slogan / tagline",
            "spaces at start and end"
          )
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Brand slogan / tagline",
            "more than one spaces between two words"
          )
        )
        // .required("Please select/enter brand slogan / tagline")
        .test(
          "len",
          "Brand slogan / tagline can not be more than 200 characters",
          (val) => (val?.length ? val?.length <= 200 : true)
        ),
      brandAmbassador: Yup.string()
        // .required("Please select/enter brand ambassador")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Brand ambassador", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Brand ambassador",
            "more than one spaces between two words"
          )
        )
        .matches(
          fullNameRegex,
          valueValidationError(
            "Brand ambassador",
            "numbers and special characters"
          )
        )
        .test(
          "len",
          "Brand ambassador can not be more than 50 characters",
          (val) => (val?.length ? val?.length <= 50 : true)
        ),
      mediaToUse: Yup.array()
        .of(
          Yup.object().shape({
            _id: Yup.string().notRequired(),
            name: Yup.string().notRequired(),
          })
        )
        .notRequired(),
    })
  ),
  category: Yup.string().notRequired(),

  subCategory: Yup.string().notRequired(),

  adPeriod: Yup.array(),
  firstName: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("First name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "First Name",
        "more than one space between two words"
      )
    )
    .matches(
      firstLastNameRegex,
      valueValidationError("First name", "numbers and special characters")
    )
    .test(
      "len",
      "First name can not be more than 30 characters",
      (val) => !val || (val && val.length <= 30)
    )
    .notRequired(),
  lastName: Yup.string()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Last name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Last Name", "more than one space between two words")
    )
    .matches(
      firstLastNameRegex,
      valueValidationError("Last name", "numbers and special characters")
    )
    .test(
      "len",
      "Last name can not be more than 30 characters",
      (val) => !val || (val && val.length <= 30)
    )
    .notRequired(),
  designation: Yup.string()
    .notRequired()
    .matches(designationNameRagex, "Please enter valid designation")
    .test(
      "len",
      "Designation can not be more than 50 characters",
      (val) => !val || (val && val.length <= 50)
    ),
  contactNumber: Yup.string()
    .notRequired()
    .matches(
      /^(?!([0-9])\1+$)[1-9][0-9]{9,14}$/,
      "Please enter valid mobile number"
    )
    .test(
      "len",
      "Mobile number can not be more than 10 characters",
      (val) => !val || (val && val.length <= 10)
    )
    .test(
      "len",
      "Mobile number can not be less than 10 characters",
      (val) => !val || (val && val.length >= 10)
    ),
  emailId: Yup.string()
    .notRequired()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (val) => !val || (val && !/\.{2,}/.test(val))
    )
    .matches(emailRegex, "Please enter valid email id")
    .test(
      "len",
      "Email id can not be more than 320 characters",
      (val) => !val || (val && val.length <= 320)
    ),
  fct: Yup.string(),
  nfct: Yup.string(),
  favCelebrity: Yup.array().of(
    Yup.string()
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Celebrity name", "spaces at start and end")
      )
      .matches(
        /^(?:(?!\s{2,}).)+$/,
        valueValidationError(
          "Celebrity name",
          "more than one spaces between two words"
        )
      )
      .matches(
        fullNameRegex,
        valueValidationError("Celebrity name", "numbers and special characters")
      )
  ),
  passion: Yup.array().of(
    Yup.string()
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Passion", "spaces at start and end")
      )
      .matches(
        /^(?:(?!\s{2,}).)+$/,
        valueValidationError(
          "Passion",
          "more than one spaces between two words"
        )
      )
  ),
  socialCause: Yup.array().of(
    Yup.string()
      .matches(
        /^(?!\s)(.*\S)?(?<!\s)$/,
        valueValidationError("Social cause", "spaces at start and end")
      )
      .matches(
        /^(?:(?!\s{2,}).)+$/,
        valueValidationError(
          "Social cause",
          "more than one spaces between two words"
        )
      )
  ),
  impactPartnerShip: Yup.string(),

  familyDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required("Please enter name")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Name", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError("Name", "more than one spaces between two words")
        )
        .matches(
          fullNameRegex,
          valueValidationError("Name", "numbers and special characters")
        ),
      relation: Yup.string()
        .required("Please enter relation")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Relation", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Relation",
            "more than one spaces between two words"
          )
        )
        .matches(
          fullNameRegex,
          valueValidationError("Relation", "numbers and special characters")
        ),
      comment: Yup.string()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Comment", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Comment",
            "more than one spaces between two words"
          )
        ),
    })
  ),
  billingCompName: Yup.string()
    .nullable()
    .notRequired()
    .test(
      "len",
      "Company name can not be more than 100 characters",
      (val) => !val || (val && val.length <= 100)
    )
    .matches(companyNameRagex, "Please enter valid company name"),

  address: Yup.string()
    .nullable()
    .test(
      "len",
      "Address can not be more than 500 characters",
      (val) => !val || (val && val.length <= 500) // Pass validation if address is empty or its length is less than or equal to 500
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Address", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Address", "more than one space between two words")
    )
    .matches(
      /^(?=.*[a-zA-Z])(\w*\s*[#,\-/().&]*)+/,
      "Please enter valid address"
    ),

  // number: Yup.string()
  //   .nullable()
  //   .matches(
  //     /^(?!([0-9])\1+$)[1-9][0-9]{9,14}$/,
  //     "Please enter a valid mobile number"
  //   )
  //   .test(
  //     "len",
  //     "Mobile number must be exactly 10 characters",
  //     (val) => (val ? val.length === 10 : true) // Pass validation if number is empty or null
  //   ),

  email: Yup.string()
    .nullable()
    .test("valid-email", "Please enter valid email id", function (value) {
      if (!value || value.trim() === "") {
        return true;
      }
      if (/\.{2,}/.test(value)) {
        return false;
      }
      if (!value.match(emailRegex)) {
        return false;
      }
      return value && value.length <= 320;
    }),

  GSTNo: Yup.string()
    .nullable()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "Please enter valid GST no."
    )
    .test("optional", "GST no. is required", function (value) {
      if (!value || value.trim() === "") {
        return true; // Pass validation if GST number is empty or null
      }
      return this.parent.GSTNo && this.parent.GSTNo.trim() !== "";
    }),

  PANNo: Yup.string()
    .nullable()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Please enter valid PAN no.")
    .test(
      "len",
      "PAN no. must be exactly 10 characters",
      (val) => (val ? val.length === 10 : true) // Pass validation if PAN number is empty or null
    )
    .test(
      "matchGSTNo",
      "PAN no. should match a portion of GST no.",
      function (val) {
        const GSTNo = this.parent.GSTNo;
        if (!val || !GSTNo) {
          return true; // Pass validation if either PAN or GST number is empty or null
        }
        const extractedPartOfGST = GSTNo.slice(2, 12);
        return val === extractedPartOfGST;
      }
    ),
  agencyName: Yup.string()
    .nullable()
    .notRequired()
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Agency name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Agency name",
        "more than one space between two words"
      )
    )
    .matches(companyNameRagex, "Please enter valid agency name")
    .test(
      "len",
      "Agency name can not be more than 100 characters",
      (val) => !val || val?.length <= 100
    ),
  agencyDetails: Yup.array().of(
    Yup.object().shape({
      // role: Yup.string().required("Please select role"),
      name: Yup.string()
        .nullable()
        .notRequired()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Name", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError("Name", "more than one spaces between two words")
        )
        .matches(
          fullNameRegex,
          valueValidationError("Name", "numbers and special characters")
        ),
      contact: Yup.string()
        .nullable()
        .notRequired()
        .matches(
          /^(?!(\d)\1+$)[1-9]\d{9,14}$/,
          "Please enter valid mobile number"
        )
        .test(
          "len",
          "Mobile number can not be more than 10 characters",
          (val) => !val || val?.length <= 10
        )
        .test(
          "len",
          "Mobile number can not be less than 10 characters",
          (val) => !val || val?.length >= 10
        ),
      email: Yup.string()
        .nullable()
        .notRequired()
        .test(
          "consecutive-dots",
          valueValidationError("Email id", "consecutive dots(.)"),
          (value) => !/\.{2,}/.test(value)
        )
        .matches(emailRegex, "Please enter valid email id"),
    })
  ),

  events: Yup.array().of(
    Yup.object().shape({
      eventName: Yup.string().required("Please select event"),
      date: Yup.string()
        .required("Please select date")
        .typeError("Please enter valid date"),
    })
  ),
});

export const eventSchema = Yup.object().shape({
  eventName: Yup.string()
    .required("Please enter event name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Event name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Event name",
        "more than one spaces between two words"
      )
    )
    .test(
      "len",
      "Event name can not be more than 20 characters",
      (val) => val?.length <= 20
    )
    .test(
      "len",
      "Event name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Event name",
        "numbers and special characters except for &"
      )
    ),
  days: Yup.string()
    .required("Please enter reminder days")
    .test(
      "len",
      "Reminder days can not be more than 2 characters",
      (val) => val?.length <= 2
    )
    .matches(/^\d+$/, "Please enter valid reminder days"),
});

export const advertisementSchema = Yup.object().shape({
  advertiser: Yup.string().required("Please select client"),
  type: Yup.string().required("Please select type"),
  amount: Yup.string().matches(
    /^(0|[1-9]\d*)(\.\d+)?/gm,
    "Please enter valid amount"
  ),
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),
  weekDay: Yup.array()
    .required("Please select weekdays")
    .min(1, "Please select at least one weekday"),
  slot: Yup.array()
    .required("Please select time band")
    .min(1, "Please select at least one time band"),
  perDayAdCount: Yup.string()
    .required("Please enter per day ad")
    .matches(/^[1-9]\d*/, "Please enter valid per day ad"),
  totalAdsCount: Yup.string().matches(
    /^[1-9]\d*/,
    "Please enter valid total ads to play"
  ),
  tags: Yup.array()
    .nullable()
    .of(
      Yup.string()
        .test(
          "len",
          "Tag name can not be more than 40 characters",
          (val) => !val || val?.length <= 40
        )
        .test(
          "len",
          "Tag name can not be less than 2 characters",
          (val) => !val || val?.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(/^\S+$/, valueValidationError("Tag", "spaces"))
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    ),
  // .required("Please select/enter tag")
  // .min(1, "Please select at least one tag"),
  mediaType: Yup.string().required("Please select media type"),
  imageVideo: Yup.mixed().when("mediaType", (mediaType) => {
    if (mediaType[0] === "video") {
      return Yup.mixed()
        .required("Please upload video")
        .test("FILE_TYPE", "Only .mp4 file allowed", (values) => {
          const validFile = values.type === "video/mp4";
          return validFile;
        });
    } else if (mediaType[0] === "image") {
      return (
        Yup.mixed()
          .required("Please upload image")
          .test(
            "FILE_TYPE",
            "Allowed only .jpeg, .jpg, .png file",
            (values) => {
              const validFile =
                values.type === "image/jpeg" ||
                values.type === "image/jpg" ||
                values.type === "image/png";
              return validFile;
            }
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (img.width == 135 && img.height == 100) {
          //           resolve(true); // dimensions are within the allowed range
          //         } else {
          //           resolve(false); // dimensions are not within the allowed range
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 5.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 5048000;
              } else {
                return true;
              }
            }
          )
      );
    }
  }),
  adMobId: Yup.mixed().when("mediaType", {
    is: "adMob",
    then: () =>
      Yup.string()
        .required("Please enter ad mob id")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Ad mob id", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Ad mob id",
            "more than one spaces between two words"
          )
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  link: Yup.mixed().when("mediaType", {
    is: (mediaType) => mediaType !== "adMob",
    then: () =>
      Yup.string()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError(
            "Advertisement redirect link",
            "spaces at start and end"
          )
        )
        .matches(
          /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
          "Please enter valid advertisement redirect link"
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  gender: Yup.array()
    .required("Please select gender")
    .min(1, "Please select at least one gender"),
  age: Yup.array()
    .required("Please select age group")
    .min(1, "Please select at least one age group"),
  // state: Yup.string().required("Please select state"),
  // location: Yup.string().required("Please enter location"),
  // district: Yup.string().required("Please select district"),
  // category: Yup.array()
  //   .required("Please select genre/category")
  //   .min(1, "Please select at least one genre/category"),
  adsPart: Yup.mixed()
    .test(
      "at-least-one-checked",
      "At least one checkbox must be checked",
      function (value) {
        const checked = value?.filter((item) => item?.checked !== false);
        return checked?.length !== 0;
      }
    )
    .required(),
});

export const advertisementEditSchema = Yup.object().shape({
  advertiser: Yup.string().required("Please select client"),
  type: Yup.string().required("Please select type"),
  amount: Yup.string().matches(
    /^(0|[1-9]\d*)(\.\d+)?/gm,
    "Please enter valid amount"
  ),
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),
  weekDay: Yup.array()
    .required("Please select weekdays")
    .min(1, "Please select at least one weekday"),
  slot: Yup.array()
    .required("Please select time band")
    .min(1, "Please select at least one time band"),
  perDayAdCount: Yup.string()
    .required("Please enter per day ad")
    .matches(/^[1-9]\d*/, "Please enter valid per day ad"),
  totalAdsCount: Yup.string().matches(
    /^[1-9]\d*/,
    "Please enter valid total ads to play"
  ),
  tags: Yup.array()
    .nullable()
    .of(
      Yup.string()
        .test(
          "len-max",
          "Tag name cannot be more than 40 characters",
          (val) => !val || val.length <= 40
        )
        .test(
          "len-min",
          "Tag name cannot be less than 2 characters",
          (val) => !val || val.length >= 2
        )
        .matches(
          /^(?!\s)(.*\S)?$/,
          valueValidationError("Tag name", "spaces at start and end")
        )
        .matches(
          tagRegex,
          valueValidationError("Tag", "special characters except for _ & and -")
        )
    ),
  // .required("Please select/enter tag")
  // .min(1, "Please select at least one tag"),
  mediaType: Yup.string().required("Please select media type"),
  imageVideo: Yup.mixed().when(["mediaType", "imageUrl"], (mediaType) => {
    if (mediaType[0] === "video" && mediaType[1])
      return Yup.mixed().test(
        "FILE_TYPE",
        "Only .mp4 file allowed",
        (values) => {
          if (values) {
            const validFile = values && values?.type === "video/mp4";
            return validFile;
          } else {
            return true;
          }
        }
      );
    // .test(
    //   "fileSize",
    //   "File size too large, max file size is 10.096 Mb",
    //   (file) => {
    //     if (file) {
    //       return file.size && file.size <= 10096000;
    //     } else {
    //       return true;
    //     }
    //   }
    // );
    if (mediaType[0] === "image" && mediaType[1]) {
      return (
        Yup.mixed()
          .test(
            "FILE_TYPE",
            "Allowed only .jpeg, .jpg, .png file",
            (values) => {
              if (values) {
                const validFile =
                  values.type === "image/jpeg" ||
                  values.type === "image/jpg" ||
                  values.type === "image/png";
                return validFile;
              } else {
                return true;
              }
            }
          )
          // .test(
          //   "imageDimensions",
          //   "Image dimensions are not within the allowed range",
          //   (value) => {
          //     if (!value) return true;
          //     const img = new Image();
          //     img.src = window.URL.createObjectURL(value);
          //     return new Promise((resolve, reject) => {
          //       img.onload = () => {
          //         if (img.width == 135 && img.height == 100) {
          //           resolve(true); // dimensions are within the allowed range
          //         } else {
          //           resolve(false); // dimensions are not within the allowed range
          //         }
          //       };
          //       img.onerror = reject;
          //     });
          //   }
          // )
          .test(
            "fileSize",
            "File size too large, max file size is 5.048 Mb",
            (file) => {
              if (file) {
                return file.size <= 5048000;
              } else {
                return true;
              }
            }
          )
      );
    }
    if (mediaType[0] === "video" && !mediaType[1])
      return Yup.mixed()
        .required("Please upload video")
        .test("FILE_TYPE", "Only .mp4 file allowed", (values) => {
          if (values) {
            const validFile = values && values?.type === "video/mp4";
            return validFile;
          } else {
            return true;
          }
        });
    // .test(
    //   "fileSize",
    //   "File size too large, max file size is 10.096 Mb",
    //   (file) => {
    //     if (file) {
    //       return file.size && file.size <= 10096000;
    //     } else {
    //       return true;
    //     }
    //   }
    // );
    if (mediaType[0] === "image" && !mediaType[1]) {
      return Yup.mixed()
        .required("Please upload image")
        .test("FILE_TYPE", "Allowed only .jpeg, .jpg, .png file", (values) => {
          if (values) {
            const validFile =
              values.type === "image/jpeg" ||
              values.type === "image/jpg" ||
              values.type === "image/png";
            return validFile;
          } else {
            return true;
          }
        })
        .test(
          "fileSize",
          "File size too large, max file size is 5.048 Mb",
          (file) => {
            if (file) {
              return file.size <= 5048000;
            } else {
              return true;
            }
          }
        );
    }
  }),
  adMobId: Yup.mixed().when("mediaType", {
    is: "adMob",
    then: () =>
      Yup.string()
        .required("Please enter ad mob id")
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError("Ad mob id", "spaces at start and end")
        )
        .matches(
          /^(?:(?!\s{2,}).)+$/,
          valueValidationError(
            "Ad mob id",
            "more than one spaces between two words"
          )
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  gender: Yup.array()
    .required("Please select gender")
    .min(1, "Please select at least one gender"),
  age: Yup.array()
    .required("Please select age group")
    .min(1, "Please select at least one age group"),
  // state: Yup.string().required("Please select state"),
  // location: Yup.string().required("Please enter location"),
  // district: Yup.string().required("Please select district"),
  // category: Yup.array()
  // .required("Please select category")
  // .min(1, "Please select at least one category"),

  link: Yup.mixed().when("mediaType", {
    is: (mediaType) => mediaType !== "adMob",
    then: () =>
      Yup.string()
        .matches(
          /^(?!\s)(.*\S)?(?<!\s)$/,
          valueValidationError(
            "Advertisement redirect link",
            "spaces at start and end"
          )
        )
        .matches(
          /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
          "Please enter valid advertisement redirect link"
        ),
    otherwise: () => Yup.string().nullable(),
  }),
  adsPart: Yup.mixed()
    .test(
      "at-least-one-checked",
      "At least one checkbox must be checked",
      function (value) {
        const checked = value?.filter((item) => item?.checked !== false);
        return checked?.length !== 0;
      }
    )
    .required(),
});

export const responseSchema = Yup.object().shape({
  response: Yup.string()
    .required("Please enter response")
    .test(
      "len",
      "Response can not be more than 250 characters",
      (val) => val?.length <= 250
    )
    .test(
      "len",
      "Response can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Response", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Response", "more than one spaces between two words")
    )
    .matches(companyNameRagex, "Please enter valid response"),
});
export const advertiserFilterSchema = Yup.object().shape({
  startDate: Yup.date().nullable().typeError("Please select from date"),
  endDate: Yup.date().when("startDate", {
    is: (startDate) => startDate,
    then: () =>
      Yup.date()
        .required("Please select to date")
        .typeError("Please select to date"),
    otherwise: () => Yup.date().nullable(),
  }),
  status: Yup.string(),
  category: Yup.string().nullable().typeError("Please select from date"),
  subCategory: Yup.string().when("category", {
    is: (category) => category,
    then: () => Yup.string().required("Please select sub category"),
    otherwise: () => Yup.string().nullable(),
  }),
});
export const userActivityListFilterSchema = Yup.object().shape({
  startDate: Yup.date().nullable().typeError("Please select from date"),
  endDate: Yup.date().when("startDate", {
    is: (startDate) => startDate,
    then: () =>
      Yup.date()
        .required("Please select to date")
        .typeError("Please select to date"),
    otherwise: () => Yup.date().nullable(),
  }),
  type: Yup.string(),
});

// export const newsListFilterSchema = Yup.object().shape({
//   district: Yup.string().required("Please select district"),
//   category: Yup.string().required("Please select categorie"),
//   sectionToShow: Yup.string().required("Please select Section To Show"),
//   search: Yup.string().required("Please enter Search"),
// });

export const adFilterSchema = Yup.object().shape({
  startDate: Yup.date().nullable().typeError("Please select from date"),
  endDate: Yup.date().when("startDate", {
    is: (startDate) => startDate,
    then: () =>
      Yup.date()
        .required("Please select to date")
        .typeError("Please enter valid date"),
    otherwise: () => Yup.date().nullable(),
  }),
  status: Yup.string(),
  gender: Yup.array(),
  category: Yup.array(),
  ageGroups: Yup.array(),
});

export const MovieCategoryAddSchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter movie category name")
    .test(
      "len",
      "Movie category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Movie category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Movie category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Movie category name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Movie category name",
        "numbers and special characters except for &"
      )
    ),
});
export const MovieCategoryEditSchema = Yup.object().shape({
  categoryName: Yup.string()
    .required("Please enter movie category name")
    .test(
      "len",
      "Movie category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Movie category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Movie category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Movie category name",
        "more than one space between two words"
      )
    )
    .matches(
      nameWithSpaceAndSpecialCaracterAnd,
      valueValidationError(
        "Movie category name",
        "numbers and special characters except for &"
      )
    ),
});

export const homeCategorySchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter home category name")
    .test(
      "len",
      "Home category name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Home category name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Home category name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Home category name",
        "more than one space between two words"
      )
    ),
  // .matches(
  //   nameWithSpaceAndSpecialCaracterAnd,
  //   valueValidationError(
  //     "Home category name",
  //     "numbers and special characters except for &"
  //   )
  // )
  color: Yup.string().required("Please select color"),
});

export const sponsorAddSchema = Yup.object().shape({
  name: Yup.string().required("Please select sponsor name"),
  email: Yup.string()
    .test(
      "consecutive-dots",
      valueValidationError("Email id", "consecutive dots(.)"),
      (value) => !/\.{2,}/.test(value)
    )
    .matches(emailRegex, "Please enter valid email id")
    .required("Please enter email id"),
  number: Yup.string()
    .required("Please enter mobile number")
    .matches(/^(?!(\d)\1+$)[1-9]\d{9,14}$/, "Please enter valid mobile number")
    .test(
      "len",
      "Mobile number can not be more than 10 characters",
      (val) => val?.length <= 10
    )
    .test(
      "len",
      "Mobile number can not be less than 10 characters",
      (val) => val?.length >= 10
    ),
  categories: Yup.array(),
  district: Yup.array(),
  news: Yup.array(),
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),

  sponsorLogo: Yup.mixed().when("isEdit", {
    is: (isEdit) => isEdit,
    then: () =>
      Yup.mixed()
        .test(
          "FILE_FORMAT",
          "Only jpg, jpeg, png or gif files allowed",
          (value) =>
            !value ||
            (["jpg", "jpeg", "png", "gif"].includes(
              value?.name?.split(".").pop().toLowerCase()
            ) &&
              ["image/jpg", "image/jpeg", "image/png", "image/gif"].includes(
                value?.type
              ))
        )
        // .test(
        //   "imageDimensions",
        //   "Image dimensions are not within the allowed range",
        //   (value) => {
        //     if (!value) return true;
        //     const img = new Image();
        //     img.src = window.URL.createObjectURL(value);
        //     return new Promise((resolve, reject) => {
        //       img.onload = () => {
        //         if (img.width == 100 && img.height == 50) {
        //           resolve(true); // dimensions are within the allowed range
        //         } else {
        //           resolve(false); // dimensions are not within the allowed range
        //         }
        //       };
        //       img.onerror = reject;
        //     });
        //   }
        // )
        .test(
          "fileSize",
          "File size too large, max file size is 5.048 Mb",
          (file) => {
            if (file) {
              return file.size <= 5048000;
            } else {
              return true;
            }
          }
        ),
    otherwise: () =>
      Yup.mixed()
        .required("Please upload sponsor logo")
        .test(
          "FILE_FORMAT",
          "Only jpg, jpeg, png or gif files allowed",
          (value) =>
            !value ||
            (["jpg", "jpeg", "png", "gif"].includes(
              value?.name?.split(".").pop().toLowerCase()
            ) &&
              ["image/jpg", "image/jpeg", "image/png", "image/gif"].includes(
                value?.type
              ))
        )
        .test(
          "fileSize",
          "File size too large, max file size is 5.048 Mb",
          (file) => {
            if (file) {
              return file.size <= 5048000;
            } else {
              return true;
            }
          }
        ),
  }),
});

export const channelSchema = Yup.object().shape({
  channelName: Yup.string()
    .required("Please enter platform name")
    .test(
      "len",
      "Platform name can not be more than 50 characters",
      (val) => val?.length <= 50
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Platform name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Platform name",
        "more than one space between two words"
      )
    ),
  // .matches(
  //   nameWithSpaceAndSpecialCaracterAnd,
  //   valueValidationError(
  //     "Platform name",
  //     "numbers and special characters except for &"
  //   )
  // )
});

export const programTypeAddSchema = Yup.object().shape({
  channel: Yup.string().required("Please select platform"),
  typeName: Yup.string()
    .required("Please enter program type name")
    .test(
      "len",
      "Program type name can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Program type name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Program type name",
        "more than one space between two words"
      )
    ),
});

export const programAddSchema = Yup.object().shape({
  // programTypeId: Yup.string().required("Please select program type"),
  programName: Yup.string()
    .required("Please enter program name")
    .test(
      "len",
      "Program name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Program name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Program name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Program name",
        "more than one space between two words"
      )
    ),
  // date: Yup.date()
  //   .required("Please select date")
  //   .test("date_test", "Date must be in the future", function (value) {
  //     return moment(value).isSameOrAfter(moment().startOf("day"));
  //   }),
  startTime: Yup.date().required("Please select start time"),
  endTime: Yup.date()
    .required("Please select end time")
    .test(
      "end_time_test",
      "End time must be after start time",
      function (value) {
        const { startTime } = this.parent;
        return isSameOrAfter(value, startTime);
      }
    ),
});
export const programEditSchema = Yup.object().shape({
  // programTypeId: Yup.string().required("Please select program type"),
  programName: Yup.string()
    .required("Please enter program name")
    .test(
      "len",
      "Program name can not be more than 40 characters",
      (val) => val?.length <= 40
    )
    .test(
      "len",
      "Program name can not be less than 3 characters",
      (val) => val?.length >= 3
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Program name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Program name",
        "more than one space between two words"
      )
    ),
  // date: Yup.date()
  //   .required("Please select date")
  //   .test("date_test", "Date must be in the future", function (value) {
  //     return moment(value).isSameOrAfter(moment().startOf("day"));
  //   }),
  startTime: Yup.date().required("Please select start time"),
  endTime: Yup.date()
    .required("Please select end time")
    .test(
      "end_time_test",
      "End time must be after start time",
      function (value) {
        const { startTime } = this.parent;
        return isSameOrAfter(value, startTime);
      }
    ),
});
export const uploadDocumentSchema = Yup.object().shape({
  documentType: Yup.string().required("Please enter document name"),
  image: Yup.mixed()
    .required("Please upload document")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png, pdf files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png", "pdf"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
            value?.type
          ))
    )
    .test(
      "fileSize",
      "File size too large, max file size is 5.048 Mb",
      (file) => {
        if (file) {
          return file.size <= 5048000;
        } else {
          return true;
        }
      }
    ),
});
export const uploadSalesDocumentSchema = Yup.object().shape({
  name: Yup.string().required("Please select document type"),
  image: Yup.mixed()
    .required("Please upload document")
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png, pdf files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png", "pdf"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(
            value?.type
          ))
    ),
});

export const elementAddEditSchema = Yup.object().shape({
  // programType: Yup.string().required("Please select program type"),
  elementName: Yup.string()
    .required("Please enter element name")
    .test(
      "len",
      "Element name can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Element name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Element name",
        "more than one space between two words"
      )
    ),
  rate: Yup.string().required("Please enter rate"),
  unit: Yup.string().required("Please select unit"),
});
export const creativeAddSchema = Yup.object().shape({
  clientId: Yup.string().required("Please select client"),
  elements: Yup.string().required("Please select element"),
  platform: Yup.string().required("Please select platform"),
  code: Yup.string()
    .required("Please enter code")
    .test(
      "len",
      "Code can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Code", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Code", "more than one space between two words")
    ),
  name: Yup.string()
    .required("Please enter creative name")
    .test(
      "len",
      "Creative name can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Creative name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Creative name",
        "more than one space between two words"
      )
    ),
  duration: Yup.string().when("platform", {
    is: "664360b5b539341a1a081253",
    then: () =>
      Yup.string()
        .required("Please enter duration")
        .matches(/^\d{1,4}$/, "Please enter valid duration"),
    otherwise: () => Yup.string().nullable(),
  }),
  imageVideo: Yup.mixed()
    // .required("Please upload image/video")
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only jpg, jpeg, png, mp4 files allowed",
      (value) =>
        !value ||
        (["jpg", "jpeg", "png", "mp4"].includes(
          value?.name?.split(".").pop().toLowerCase()
        ) &&
          ["image/jpg", "image/jpeg", "image/png", "video/mp4"].includes(
            value?.type
          ))
    ),
});
export const creativeEditSchema = Yup.object().shape({
  clientId: Yup.string().required("Please select client"),
  elements: Yup.string().required("Please select element"),
  platform: Yup.string().required("Please select platform"),
  code: Yup.string()
    .required("Please enter code")
    .test(
      "len",
      "Code can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Code", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Code", "more than one space between two words")
    ),
  name: Yup.string()
    .required("Please enter creative name")
    .test(
      "len",
      "Creative name can not be more than 100 characters",
      (val) => val?.length <= 100
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Creative name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Creative name",
        "more than one space between two words"
      )
    ),
  duration: Yup.string().when("platform", {
    is: "664360b5b539341a1a081253",
    then: () =>
      Yup.string()
        .required("Please enter duration")
        .matches(/^\d{1,4}$/, "Please enter valid duration"),
    otherwise: () => Yup.string().nullable(),
  }),

  imageVideo: Yup.mixed().test(
    "FILE_FORMAT",
    "Only jpg, jpeg, png, mp4 files allowed",
    (value) =>
      !value ||
      (["jpg", "jpeg", "png", "mp4"].includes(
        value?.name?.split(".").pop().toLowerCase()
      ) &&
        ["image/jpg", "image/jpeg", "image/png", "video/mp4"].includes(
          value?.type
        ))
  ),
});
export const slotAddEditSchema = Yup.object().shape({
  slotType: Yup.string().required("Please select slot type"),
  slotName: Yup.string()
    .required("Please enter template name")
    .test(
      "len",
      "Slot name can not be more than 80 characters",
      (val) => val?.length <= 80
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Slot name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Slot name", "more than one space between two words")
    ),
  timeDetails: Yup.array().of(
    Yup.object().shape({
      fromTime: Yup.date().required("Please select from time"),
      toTime: Yup.date()
        .required("Please select to time")
        .test(
          "end_time_test",
          "To time must be after from time",
          function (value) {
            const { fromTime } = this.parent;
            return isSameOrAfter(value, fromTime);
          }
        ),
    })
  ),
});
export const scheduleAddEditSchema = Yup.object().shape({
  slotType: Yup.string().required("Please select slot type"),
  slotId: Yup.string().required("Please select slot"),
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),
});
export const addSlotSchema = Yup.object().shape({
  advertiser: Yup.string().required("Please select client"),
  creative: Yup.object().shape({
    _id: Yup.string().required("Please select creative"),
  }),
  noOfTimesPlay: Yup.mixed().when("type", {
    is: "NonFCT",
    then: () => Yup.string().required("Please enter no. of times play"),
    otherwise: () => Yup.string().nullable(),
  }),
});
export const websiteScheduleAddSchema = Yup.object().shape(
  {
    clientId: Yup.string().required("Please select client"),
    startDate: Yup.date()
      .required("Please select start date")
      .typeError("Please enter valid date"),
    endDate: Yup.date()
      .required("Please select end date")
      .typeError("Please enter valid date"),
    type: Yup.string().required("Please select type"),
    adType: Yup.string().required("Please select advertisement type"),
    elementId: Yup.string().required("Please select element"),
    creativeId: Yup.mixed().when(
      ["adType", "imageVideo"],
      (adType, creativeId, values) => {
        if (
          (adType[0] === "image" || adType[0] === "video") &&
          values?.context?.imageVideo === undefined
        ) {
          return Yup.string().required("Please select creative");
        } else {
          Yup.string().notRequired();
        }
      }
    ),
    // imageVideo: Yup.mixed().when(
    //   ["adType", "creativeId"],
    //   (adType, creativeId, values) => {
    //     if (adType[0] === "video" && !values?.context?.creativeId) {
    //       return Yup.mixed()
    //         .required("Please upload video")
    //         .test("FILE_TYPE", "Only .mp4 file allowed", (values) => {
    //           const validFile = values.type === "video/mp4";
    //           return validFile;
    //         });
    //     } else if (adType[0] === "image" && !values?.context?.creativeId) {
    //       return Yup.mixed()
    //         .required("Please upload image")
    //         .test(
    //           "FILE_TYPE",
    //           "Allowed only .jpeg, .jpg, .png file",
    //           (values) => {
    //             const validFile =
    //               values.type === "image/jpeg" ||
    //               values.type === "image/jpg" ||
    //               values.type === "image/png";
    //             return validFile;
    //           }
    //         )
    //         .test(
    //           "fileSize",
    //           "File size too large, max file size is 5.048 Mb",
    //           (file) => {
    //             if (file) {
    //               return file.size <= 5048000;
    //             } else {
    //               return true;
    //             }
    //           }
    //         );
    //     }
    //   }
    // ),
    code: Yup.mixed().when("adType", {
      is: "code",
      then: () =>
        Yup.string()
          .required("Please enter code")
          .matches(
            /^(?!\s)(.*\S)?(?<!\s)$/,
            valueValidationError("code", "spaces at start and end")
          )
          .matches(
            /^(?:(?!\s{2,}).)+$/,
            valueValidationError(
              "code",
              "more than one spaces between two words"
            )
          ),
      otherwise: () => Yup.string().nullable(),
    }),
    redirectUrl: Yup.mixed().when("type", {
      is: "click",
      then: () =>
        Yup.string()
          .required("Please enter redirect url")
          .matches(
            /^(?!\s)(.*\S)?(?<!\s)$/,
            valueValidationError("Redirect URL", "spaces at start and end")
          )
          .matches(
            /^(?:(?!\s{2,}).)+$/,
            valueValidationError(
              "Redirect URL",
              "more than one spaces between two words"
            )
          ),
      otherwise: () => Yup.string().nullable(),
    }),
  },
  ["creativeId", "imageVideo"]
);
export const websiteScheduleEditSchema = Yup.object().shape(
  {
    clientId: Yup.string().required("Please select client"),
    startDate: Yup.date()
      .required("Please select start date")
      .typeError("Please enter valid date"),
    endDate: Yup.date()
      .required("Please select end date")
      .typeError("Please enter valid date"),
    type: Yup.string().required("Please select type"),
    adType: Yup.string().required("Please select advertisement type"),
    elementId: Yup.string().required("Please select element"),
    creativeId: Yup.mixed().when(
      ["adType", "imageVideo"],
      (adType, creativeId, values) => {
        if (
          (adType[0] === "image" || adType[0] === "video") &&
          !values?.context?.imageVideo &&
          !values?.context?.imageUrl
        ) {
          return Yup.string().required("Please select creative");
        } else {
          Yup.string().notRequired();
        }
      }
    ),
    // imageVideo: Yup.mixed().when(
    //   ["adType", "creativeId"],
    //   (adType, creativeId, values) => {
    //     if (
    //       adType[0] === "video" &&
    //       !values?.context?.creativeId &&
    //       !values?.context?.imageUrl
    //     ) {
    //       return Yup.mixed()
    //         .required("Please upload video")
    //         .test("FILE_TYPE", "Only .mp4 file allowed", (values) => {
    //           const validFile = values.type === "video/mp4";
    //           return validFile;
    //         });
    //     } else if (
    //       adType[0] === "image" &&
    //       !values?.context?.creativeId &&
    //       !values?.context?.imageUrl
    //     ) {
    //       return Yup.mixed()
    //         .required("Please upload image")
    //         .test(
    //           "FILE_TYPE",
    //           "Allowed only .jpeg, .jpg, .png file",
    //           (values) => {
    //             const validFile =
    //               values.type === "image/jpeg" ||
    //               values.type === "image/jpg" ||
    //               values.type === "image/png";
    //             return validFile;
    //           }
    //         )
    //         .test(
    //           "fileSize",
    //           "File size too large, max file size is 5.048 Mb",
    //           (file) => {
    //             if (file) {
    //               return file.size <= 5048000;
    //             } else {
    //               return true;
    //             }
    //           }
    //         );
    //     }
    //   }
    // ),
    code: Yup.mixed().when("adType", {
      is: "code",
      then: () =>
        Yup.string()
          .required("Please enter code")
          .matches(
            /^(?!\s)(.*\S)?(?<!\s)$/,
            valueValidationError("code", "spaces at start and end")
          )
          .matches(
            /^(?:(?!\s{2,}).)+$/,
            valueValidationError(
              "code",
              "more than one spaces between two words"
            )
          ),
      otherwise: () => Yup.string().nullable(),
    }),
    redirectUrl: Yup.mixed().when("type", {
      is: "click",
      then: () =>
        Yup.string()
          .required("Please enter redirect url")
          .matches(
            /^(?!\s)(.*\S)?(?<!\s)$/,
            valueValidationError("Redirect URL", "spaces at start and end")
          )
          .matches(
            /^(?:(?!\s{2,}).)+$/,
            valueValidationError(
              "Redirect URL",
              "more than one spaces between two words"
            )
          ),
      otherwise: () => Yup.string().nullable(),
    }),
  },
  ["creativeId", "imageVideo"]
);

export const salesOrderModal = Yup.object().shape({
  platform: Yup.string().required("Please select platform"),
  elements: Yup.string().required("Please select element"),
  totalSeconds: Yup.string().notRequired(),
  rate: Yup.string().required("Please enter rate"),
  discountrate: Yup.string().required("Please enter selling rate"),
  // program: Yup.array().notRequired(),
  // schedule: Yup.mixed().when(["platform", "type"], (values) => {
  //   if (values[0] === "TV FCT" || values[0] === "TV Non FCT") {
  //     return Yup.string().required("Please select schedule");
  //   } else {
  //     return Yup.string().notRequired();
  //   }
  // }),
  // startDate: Yup.mixed().when(["platform", "type"], (values) => {
  //   if (
  //     (values[0] === "TV FCT" || values[0] === "TV Non FCT") &&
  //     values[1] === "dateRange"
  //   ) {
  //     return Yup.date()
  //       .required("Please select start date")
  //       .typeError("Please enter valid date");
  //   } else {
  //     return Yup.date().notRequired().nullable();
  //   }
  // }),
  // endDate: Yup.mixed().when(["platform", "type"], (values) => {
  //   if (
  //     (values[0] === "TV FCT" || values[0] === "TV Non FCT") &&
  //     values[1] === "dateRange"
  //   ) {
  //     return Yup.date()
  //       .required("Please select end date")
  //       .typeError("Please enter valid date");
  //   } else {
  //     return Yup.date().notRequired().nullable();
  //   }
  // }),
  // slots: Yup.array().notRequired(),
  // slots: Yup.mixed().when(["platform", "type"], (values) => {
  //   if (
  //     (values[0] === "TV FCT" || values[0] === "TV Non FCT") &&
  //     values[1] === "dateRange"
  //   ) {
  //     return Yup.array()
  //       .required("At least one slot is required")
  //       .min(1, "Please select at least one slot");
  //   } else {
  //     return Yup.array().notRequired();
  //   }
  // }),
  selectedDates: Yup.mixed().when(["platform", "type"], (values) => {
    if (
      (values[0] === "TV FCT" || values[0] === "TV Non FCT") &&
      values[1] === "date"
    ) {
      return Yup.array().of(
        Yup.object().shape({
          date: Yup.array()
            .required("Please select at least one date")
            .min(1, "Please select at least one date"),
          slots: Yup.array().notRequired(),
        })
      );
    } else {
      return Yup.array().of(
        Yup.object().shape({
          date: Yup.array().notRequired().nullable(),
          slots: Yup.array().notRequired(),
        })
      );
    }
  }),
  amount: Yup.number().notRequired(),
});

export const salesOrder = Yup.object().shape({
  client: Yup.string().required("Please select client"),
  campaignName: Yup.string()
    .required("Please enter deal name")
    .test(
      "len",
      "Deal name can not be more than 50 characters",
      (val) => val?.length <= 50
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Deal name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Deal name", "more than one space between two words")
    ),
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),
  // approvelPerson: Yup.string().required("Please select approvel person"),
});
export const salesSubOrder = Yup.object().shape({
  name: Yup.string()
    .required("Please enter booking name")
    .test(
      "len",
      "Booking name can not be more than 50 characters",
      (val) => val?.length <= 50
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Booking name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Booking name",
        "more than one space between two words"
      )
    ),
  startDate: Yup.date()
    .required("Please select start date")
    .typeError("Please enter valid date"),
  endDate: Yup.date()
    .required("Please select end date")
    .typeError("Please enter valid date"),
});

export const salesSubOrderModal = Yup.object().shape({
  scheduleDetails: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.mixed().when(["type"], (values) => {
        if (values[0] === "dateRange") {
          return Yup.date()
            .required("Please select start date")
            .typeError("Please enter valid date");
        } else {
          return Yup.date().notRequired().nullable();
        }
      }),
      endDate: Yup.mixed().when(["type"], (values) => {
        if (values[0] === "dateRange") {
          return Yup.date()
            .required("Please select end date")
            .typeError("Please enter valid date");
        } else {
          return Yup.date().notRequired().nullable();
        }
      }),
      selectedDates: Yup.mixed().when(["type"], (values) => {
        if (values[0] === "date") {
          return Yup.array()
            .required("Please select dates")
            .min(1, "Please select at least one date");
        } else {
          return Yup.array().notRequired().nullable();
        }
      }),
      creative: Yup.string().required("Please select creative"),
      program: Yup.mixed().when(["scheduleType"], (values) => {
        if (values[0] === "program") {
          return Yup.string().required("Please select program");
        } else {
          return Yup.string().notRequired().nullable();
        }
      }),
      fromTime: Yup.mixed().when(["scheduleType"], (values) => {
        if (values[0] === "timeBand") {
          return Yup.date().required("Please select from time");
        } else {
          return Yup.date().notRequired().nullable();
        }
      }),
      toTime: Yup.mixed().when(["scheduleType"], (values) => {
        if (values[0] === "timeBand") {
          return Yup.date()
            .required("Please select to time")
            .test(
              "end_time_test",
              "To time must be after from time",
              function (value) {
                const { fromTime } = this.parent;
                return isSameOrAfter(value, fromTime);
              }
            );
        } else {
          return Yup.date().notRequired().nullable();
        }
      }),
    })
  ),
});
export const agencySchema = Yup.object().shape({
  agencyName: Yup.string()
    .required("Please enter agency name")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Agency name", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError(
        "Agency name",
        "more than one space between two words"
      )
    ),
});
export const uploadVideoSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Title", "more than one space between two words")
    ),
  category: Yup.array().notRequired(),
  tags: Yup.array().notRequired(),
  description: Yup.string().required("Please enter description"),
  video: Yup.mixed()
    .required("Please upload video")
    .test(
      "FILE_FORMAT",
      "Only .mp4 files allowed",
      (value) =>
        !value ||
        (["mp4"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["video/mp4"].includes(value?.type))
    ),
  serverLink: Yup.string().required("Please select server link"),
});
export const editVideoSchema = Yup.object().shape({
  title: Yup.string()
    .required("Please enter title")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Title", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Title", "more than one space between two words")
    ),
  category: Yup.array().notRequired(),
  tags: Yup.array().notRequired(),
  description: Yup.string().required("Please enter description"),
  serverLink: Yup.string().required("Please select server link"),
  video: Yup.mixed()
    .notRequired()
    .test(
      "FILE_FORMAT",
      "Only .mp4 files allowed",
      (value) =>
        !value ||
        (["mp4"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["video/mp4"].includes(value?.type))
    ),
});
export const uploadInternalVideoSchema = Yup.object().shape({
  video: Yup.mixed()
    .required("Please upload video")
    .test(
      "FILE_FORMAT",
      "Only .mp4 files allowed",
      (value) =>
        !value ||
        (["mp4"].includes(
          value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
        ) &&
          ["video/mp4"].includes(value?.type))
    ),
});

export const serverAddSchema = Yup.object().shape({
  name: Yup.string()
    .required("Please enter internal server link")
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}(\/\S*)?$/,
      "Please enter valid internal server link"
    ),
});
