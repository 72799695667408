import React, { useEffect, useState } from "react";
import PagesIndex from "../../PagesIndex";
import Index from "../../Index";
import "./defaultLayout.css";
import "./defaultLayout.responsive.css";
import PropTypes from "prop-types";

export default function Sidebar(props) {
  // for page redirect
  const location = PagesIndex.useLocation();
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  // for submenu dropdown
  const [open, setOpen] = useState(false);
  const handleClickAccount = (e) => {
    setOpen(!open);
  };
  const [openNews, setOpenNews] = useState(false);
  const handleClickNews = (e) => {
    setOpenNews(!openNews);
  };

  const [openClients, setOpenClients] = useState(false);
  const handleClickClients = (e) => {
    setOpenClients(!openClients);
  };

  const [openAdvertisement, setOpenAdvertisement] = useState(false);
  const handleClickAdvertisement = (e) => {
    setOpenAdvertisement(!openAdvertisement);
  };

  const [openUnderAdvertisement, setOpenUnderAdvertisement] = useState(false);
  const handleClickUnderAdvertisement = (e) => {
    setOpenUnderAdvertisement(!openUnderAdvertisement);
  };

  const [openInventory, setOpenInventory] = useState(false);
  const handleClickInventory = (e) => {
    setOpenInventory(!openInventory);
  };

  const [openUnderInventoryTV, setOpenUnderInventoryTV] = useState(false);
  const handleClickUnderInventoryTV = (e) => {
    setOpenUnderInventoryTV(!openUnderInventoryTV);
  };
  const [openUnderInventoryWeb, setOpenUnderInventoryWeb] = useState(false);
  const handleClickUnderInventoryWeb = (e) => {
    setOpenUnderInventoryWeb(!openUnderInventoryWeb);
  };

  const [openUnderInventoryOtt, setOpenUnderInventoryOtt] = useState(false);
  const handleClickUnderInventoryOtt = (e) => {
    setOpenUnderInventoryOtt(!openUnderInventoryOtt);
  };

  const [openUnderInventoryShots, setOpenUnderInventoryShots] = useState(false);
  const handleClickUnderInventoryShots = (e) => {
    setOpenUnderInventoryShots(!openUnderInventoryShots);
  };

  const [openUnderInventoryTVFCT, setOpenUnderInventoryTVFCT] = useState(false);
  const handleClickUnderInventoryTVFCT = (e) => {
    setOpenUnderInventoryTVFCT(!openUnderInventoryTVFCT);
  };

  const [openUnderInventoryTVNFCT, setOpenUnderInventoryTVNFCT] =
    useState(false);
  const handleClickUnderInventoryTVNFCT = (e) => {
    setOpenUnderInventoryTVNFCT(!openUnderInventoryTVNFCT);
  };

  const [openCreative, setOpenCreative] = useState(false);
  const handleClickCreative = (e) => {
    setOpenCreative(!openCreative);
  };

  const [openAdBooking, setOpenAdBooking] = useState(false);
  const handleClickAdBooking = (e) => {
    setOpenAdBooking(!openAdBooking);
  };

  const [openMoviesSeries, setOpenMoviesSeries] = useState(false);
  const handleClickMoviesSeries = (e) => {
    setOpenMoviesSeries(!openMoviesSeries);
  };

  const [openSponsorLogo, setopenSponsorLogo] = useState(false);
  const handleClickSponsorLogo = (e) => {
    setopenSponsorLogo(!openSponsorLogo);
  };

  const [openSettings, setOpenSettings] = useState(false);
  const handleClickSettings = (e) => {
    setOpenSettings(!openSettings);
  };
  const [openListings, setOpenListings] = useState(false);
  const handleClickListings = (e) => {
    setOpenListings(!openListings);
  };
  const [openReports, setOpenReports] = useState(false);
  const handleClickReports = (e) => {
    setOpenReports(!openReports);
  };
  const [openUnderAdvertisementTV, setOpenUnderAdvertisementTV] =
    useState(false);
  const [pendingVideoCount, setPendingVideoCount] = useState(0);
  const handleClickUnderAdvertisementTV = (e) => {
    setOpenUnderAdvertisementTV(!openUnderAdvertisementTV);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) {
      props.setOpen(false);
    } else {
      props.setOpen(true);
    }
    getPendingVideoCount();
  }, []);
  const getPendingVideoCount = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_PENDINGVIDEO_COUNT).then(
      (res) => {
        if (res.status === 200) {
          setPendingVideoCount(res.data);
        } else {
          PagesIndex.toasterError(res?.message);
        }
      }
    );
  };
  const Video = [
    {
      title: "Add video",
      pathName: "/admin/videos",
      icon: PagesIndex.Svg.postVideo,
      show: adminDetails?.roleId?.permissions?.includes("videos_view"),
      menuHighlight: ["view-video", "view-video", "add-video", "videos"],
    },
    {
      title: "Category",
      pathName: "/admin/category-management",
      icon: PagesIndex.Svg.categoryIcon,
      show: adminDetails?.roleId?.permissions?.includes("category_view"),
      menuHighlight: ["category-management"],
    },
    {
      title: "Home Category",
      pathName: "/admin/home-category-management",
      icon: PagesIndex.Svg.subCategoryIcon,
      show: adminDetails?.roleId?.permissions?.includes("home_category_view"),
      menuHighlight: ["home-category-management"],
    },
    {
      title: "State",
      pathName: "/admin/state-management",
      icon: PagesIndex.Svg.stateIcon,
      show: adminDetails?.roleId?.permissions?.includes("state_view"),
      menuHighlight: ["state-management"],
    },
    {
      title: "Zone",
      pathName: "/admin/zone-details",
      icon: PagesIndex.Svg.zoneicon,
      show: adminDetails?.roleId?.permissions?.includes("zone_view"),
      menuHighlight: ["zone-details"],
    },
    {
      title: "District",
      pathName: "/admin/district",
      icon: PagesIndex.Svg.districticon,
      show: adminDetails?.roleId?.permissions?.includes("district_view"),
      menuHighlight: ["district"],
    },
    {
      title: "Tag",
      pathName: "/admin/tag-master",
      icon: PagesIndex.Svg.tagicon,
      show: adminDetails?.roleId?.permissions?.includes("tag_view"),
      menuHighlight: ["tag-master"],
    },
  ];

  const Clients = [
    {
      title: "All Clients",
      pathName: "/admin/clients",
      icon: PagesIndex.Svg.advertising,
      show: adminDetails?.roleId?.permissions?.includes("clients_view"),
      menuHighlight: [
        "view-client",
        "view-client",
        "add-client",
        "clients",
        "edit-client",
      ],
    },
    {
      title: "Agency",
      pathName: "/admin/agency-management",
      icon: PagesIndex.Svg.categoryIcon,
      show: adminDetails?.roleId?.permissions?.includes("category_view"),
      menuHighlight: ["agency-management"],
    },
    {
      title: "Event",
      pathName: "/admin/event-management",
      icon: PagesIndex.Svg.categoryIcon,
      show: adminDetails?.roleId?.permissions?.includes("event_view"),
      menuHighlight: ["event-management"],
    },
    {
      title: "Zone",
      pathName: "/admin/ad-zone-details",
      icon: PagesIndex.Svg.zoneicon,
      show: adminDetails?.roleId?.permissions?.includes("client_zone_view"),
      menuHighlight: ["ad-zone-details"],
    },
    {
      title: "Sales Deal",
      pathName: "/admin/sales-order-list",
      icon: PagesIndex.Svg.salesCampainIcon,
      show: adminDetails?.roleId?.permissions?.includes("sales_order_view"),
      menuHighlight: [
        "sales-order-list",
        "add-sales-order",
        "sales-sub-order-list",
        "add-sales-sub-order",
      ],
    },
    {
      title: "Category",
      pathName: "/admin/category",
      icon: PagesIndex.Svg.categoryIcon,
      show: adminDetails?.roleId?.permissions?.includes("client_category_view"),
      menuHighlight: ["category"],
    },
    {
      title: "Sub Category",
      pathName: "/admin/sub-category",
      icon: PagesIndex.Svg.subCategoryIcon,
      show: adminDetails?.roleId?.permissions?.includes(
        "client_sub_category_view"
      ),
      menuHighlight: ["sub-category"],
    },
    {
      title: "Media Platform",
      pathName: "/admin/media-platform",
      icon: PagesIndex.Svg.socialIcon,
      show: adminDetails?.roleId?.permissions?.includes("media_platform_view"),
      menuHighlight: ["media-platform"],
    },
  ];

  const TvAds = [
    {
      title: "FCT",
      pathName: "/admin/slot-schedule-management/FCT",
      icon: PagesIndex.Svg.scheduleeManagmentIcon,
      show: adminDetails?.roleId?.permissions?.includes("slot_schedule_view"),
      menuHighlight: [
        "FCT",
        "slot-schedule-management",
        "add-slot-schedule",
        "view-slot-schedule",
      ],
    },
    {
      title: "Non FCT",
      pathName: "/admin/slot-schedule-management/NonFCT",
      icon: PagesIndex.Svg.scheduleIcons,
      show: adminDetails?.roleId?.permissions?.includes("slot_schedule_view"),
      menuHighlight: [
        "NonFCT",
        "slot-schedule-management",
        "add-slot-schedule",
        "view-slot-schedule",
      ],
    },
  ];

  const Advertisement = [
    {
      title: "Website Ad",
      pathName: "/admin/website-schedule-management",
      icon: PagesIndex.Svg.zoneicon,
      show: adminDetails?.roleId?.permissions?.includes("website_ad_view"),
      menuHighlight: ["website-schedule-management"],
    },
    {
      title: "NC Shots Ad",
      pathName: "/admin/ncshots-schedule-management",
      icon: PagesIndex.Svg.advertisementIcon,
      show: adminDetails?.roleId?.permissions?.includes(
        "nc_slot_schedule_view"
      ),
      menuHighlight: ["ncshots-schedule-management"],
    },
    {
      title: "OTT Ad",
      pathName: "/admin/ads-management",
      icon: PagesIndex.Svg.advertising,
      show: adminDetails?.roleId?.permissions?.includes("ads_view"),
      menuHighlight: [
        "ads-management",
        "view-ads-management",
        "add-ads-management",
        "edit-ads-management",
      ],
    },
  ];

  const MoviesAndSeries = [
    {
      title: "Add New Movie",
      pathName: "/admin/movies",
      icon: PagesIndex.Svg.movieicon,
      show: adminDetails?.roleId?.permissions?.includes("movies_view"),
      menuHighlight: ["movies", "view-movie", "add-movie"],
    },
    {
      title: "Add New Series",
      pathName: "/admin/web-series",
      icon: PagesIndex.Svg.webseriesicon,
      show: adminDetails?.roleId?.permissions?.includes("series_view"),
      menuHighlight: ["web-series", "view-web-series", "seasons", "episode"],
    },
    {
      title: "Cast & Crew",
      pathName: "/admin/cast-and-crew-master",
      icon: PagesIndex.Svg.casticon,
      show: adminDetails?.roleId?.permissions?.includes("actor_view"),
      menuHighlight: ["cast-and-crew-master"],
    },
    {
      title: "Category",
      pathName: "/admin/movie-category-management",
      icon: PagesIndex.Svg.categoryIcon,
      show: adminDetails?.roleId?.permissions?.includes("movie_category_view"),
      menuHighlight: ["movie-category-management"],
    },
    {
      title: "Tag",
      pathName: "/admin/movie-tag-master",
      icon: PagesIndex.Svg.tagicon,
      show: adminDetails?.roleId?.permissions?.includes("movie_tag_view"),
      menuHighlight: ["movie-tag-master"],
    },
  ];

  const ManageInquiry = [
    {
      title: "Advertising List",
      pathName: "/admin/advertising",
      icon: PagesIndex.Svg.advertising,
      show: adminDetails?.roleId?.permissions?.includes("advertising_view"),
    },

    {
      title: "Support List",
      pathName: "/admin/support",
      icon: PagesIndex.Svg.supporticon,
      show: adminDetails?.roleId?.permissions?.includes("support_view"),
    },
  ];

  return (
    <Index.Box className={`admin-sidebar-main`}>
      <Index.Box className="admin-sidebar-inner-main">
        <Index.Box className="mobile-sidebar-header">
          <Index.Link
            className="admin-sidebar-logo-main"
            to={
              adminDetails?.roleId?.permissions?.includes("dashboard_view")
                ? "/admin/dashboard"
                : "/admin/account"
            }
          >
            <img
              src={PagesIndex.Gif.logoGif}
              className="admin-sidebar-logo"
              alt="logo"
            />
          </Index.Link>
          <Index.Button
            onClick={() => {
              props.setOpen(false);
              document.body.classList[props.open ? "remove" : "add"](
                "admin-body-overflow"
              );
            }}
            className="admin-sidebar-close-btn"
          >
            <img
              src={PagesIndex.Svg.close}
              className="admin-close-icon"
              alt="logo"
            />
          </Index.Button>
        </Index.Box>

        <Index.Box className="admin-sidebar-list-main scrollbar">
          <Index.List className="admin-sidebar-list">
            {[
              {
                title: "Dashboard",
                pathName: "/admin/dashboard",
                icon: PagesIndex.Svg.dashboard,
                show: adminDetails?.roleId?.permissions?.includes(
                  "dashboard_view"
                ),
                menuHighlight: ["dashboard"],
              },
              {
                title: "App User",
                pathName: "/admin/manage-user",
                icon: PagesIndex.Svg.adminicon,
                show: adminDetails?.roleId?.permissions?.includes("user_view"),
                menuHighlight: ["manage-user", "view-user"],
              },
              {
                title: "User Posted Videos",
                pathName: "/admin/user-posted-videos",
                icon: PagesIndex.Svg.postVideo,
                show: adminDetails?.roleId?.permissions?.includes(
                  "user_posted_videos_view"
                ),
                menuHighlight: ["user-posted-videos"],
              },
            ]?.map((data) => {
              return (
                data?.show && (
                  <Index.ListItem
                    className="admin-sidebar-listitem"
                    key={data?.pathName}
                  >
                    <Index.Link
                      to={data?.pathName}
                      onClick={() => {
                        localStorage.removeItem("currentPage");
                        if (window.innerWidth < 786) {
                          props.setOpen(false);
                        }
                        document.body.classList[props.open ? "remove" : "add"](
                          "admin-body-overflow"
                        );
                      }}
                      className={
                        data?.menuHighlight?.includes(
                          location?.pathname?.split("/")[2]
                        )
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <img
                        src={data?.icon}
                        alt={data?.title}
                        className="admin-sidebar-icons"
                      />
                      <span
                        className={`${
                          data?.title === "User Posted Videos" &&
                          location?.pathname === "/admin/user-posted-videos" &&
                          props.open
                            ? "badge-flex"
                            : "badge-flex badge-deselect"
                        }`}
                      >
                        <span className={`admin-sidebar-link-text`}>
                          {data?.title}
                        </span>
                        {data?.title === "User Posted Videos" &&
                        pendingVideoCount ? (
                          <span className="badge-bg">
                            <p className="badge-text">{pendingVideoCount}</p>
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </Index.Link>
                  </Index.ListItem>
                )
              );
            })}
            {Video?.filter((ele) => ele?.show == true)?.length > 0 && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickNews}
                  >
                    <img
                      src={PagesIndex.Svg.newsicon}
                      alt="Video"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">Video</span>
                    {openNews ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openNews}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {Video?.map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    data?.menuHighlight?.includes(
                                      location?.pathname?.split("/")[2]
                                    )
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}
            {Clients?.filter((ele) => ele?.show == true)?.length > 0 && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickClients}
                  >
                    <img
                      src={PagesIndex.Svg.clientIcon}
                      alt="Clients"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">Clients</span>
                    {openClients ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openClients}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {Clients?.map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    data?.menuHighlight?.includes(
                                      location?.pathname?.split("/")[2]
                                    )
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {(Advertisement?.filter((ele) => ele?.show == true)?.length > 0 ||
              TvAds?.filter((ele) => ele?.show == true)?.length > 0) && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickAdvertisement}
                  >
                    <img
                      src={PagesIndex.Svg.scheduleIcons}
                      alt="Advertisement Scheduling"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">
                      Advertisement Scheduling
                    </span>
                    {openAdvertisement ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openAdvertisement}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      {TvAds?.filter((ele) => ele?.show == true)?.length >
                        0 && (
                        <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                          <Index.Box className="admin-submenu-link-box">
                            <Index.Box
                              className="admin-sidebar-link"
                              onClick={handleClickUnderAdvertisementTV}
                            >
                              <img
                                src={PagesIndex.Svg.advertisementIcon}
                                alt="TV Ads"
                                className="admin-sidebar-icons"
                              />
                              <span className="admin-sidebar-link-text">
                                TV Ads
                              </span>
                              {openUnderAdvertisementTV ? (
                                <Index.ExpandLess className="expandless-icon" />
                              ) : (
                                <Index.ExpandMore className="expandmore-icon" />
                              )}
                            </Index.Box>
                            <Index.Box className="admin-submenu-main">
                              <Index.Collapse
                                in={openUnderAdvertisementTV}
                                timeout="auto"
                                className="admin-submenu-collapse"
                              >
                                <Index.List
                                  component="div"
                                  disablePadding
                                  className="admin-sidebar-submenulist"
                                >
                                  {TvAds?.map((data) => {
                                    return (
                                      data?.show && (
                                        <Index.ListItem
                                          className="admin-sidebar-listitem"
                                          key={data?.pathName}
                                        >
                                          <Index.Link
                                            to={data?.pathName}
                                            onClick={() => {
                                              localStorage.removeItem(
                                                "currentPage"
                                              );
                                              if (window.innerWidth < 786) {
                                                props.setOpen(false);
                                              }
                                              document.body.classList[
                                                props.open ? "remove" : "add"
                                              ]("admin-body-overflow");
                                            }}
                                            className={
                                              data?.menuHighlight?.includes(
                                                location?.pathname?.split(
                                                  "/"
                                                )[3]
                                              ) &&
                                              data?.menuHighlight?.includes(
                                                location?.pathname?.split(
                                                  "/"
                                                )[2]
                                              )
                                                ? "admin-sidebar-link active"
                                                : "admin-sidebar-link"
                                            }
                                          >
                                            <img
                                              src={data?.icon}
                                              alt={data?.title}
                                              className="admin-sidebar-icons"
                                            />
                                            <span className="admin-sidebar-link-text">
                                              {data?.title}
                                            </span>
                                          </Index.Link>
                                        </Index.ListItem>
                                      )
                                    );
                                  })}
                                </Index.List>
                              </Index.Collapse>
                            </Index.Box>
                          </Index.Box>
                        </Index.ListItem>
                      )}
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {Advertisement?.map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    data?.menuHighlight?.includes(
                                      location?.pathname?.split("/")[2]
                                    )
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {(adminDetails?.roleId?.permissions?.includes("slot_view") ||
              adminDetails?.roleId?.permissions?.includes("element_view") ||
              adminDetails?.roleId?.permissions?.includes("ads_view")) && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickInventory}
                  >
                    <img
                      src={PagesIndex.Svg.inventoryIcons}
                      alt="Inventory"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">Inventory</span>
                    {openInventory ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openInventory}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      {(adminDetails?.roleId?.permissions?.includes(
                        "slot_view"
                      ) ||
                        adminDetails?.roleId?.permissions?.includes(
                          "element_view"
                        )) && (
                        <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                          <Index.Box className="admin-submenu-link-box">
                            <Index.Box
                              className="admin-sidebar-link"
                              onClick={handleClickUnderInventoryTV}
                            >
                              <img
                                src={PagesIndex.Svg.TvIcon}
                                alt="TV"
                                className="admin-sidebar-icons"
                              />
                              <span className="admin-sidebar-link-text">
                                TV
                              </span>
                              {openUnderInventoryTV ? (
                                <Index.ExpandLess className="expandless-icon" />
                              ) : (
                                <Index.ExpandMore className="expandmore-icon" />
                              )}
                            </Index.Box>
                            <Index.Box className="admin-submenu-main">
                              <Index.Collapse
                                in={openUnderInventoryTV}
                                timeout="auto"
                                className="admin-submenu-collapse"
                              >
                                {adminDetails?.roleId?.permissions?.includes(
                                  "slot_view"
                                ) && (
                                  <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                                    <Index.Box className="admin-submenu-link-box">
                                      <Index.Box
                                        className="admin-sidebar-link"
                                        onClick={handleClickUnderInventoryTVFCT}
                                      >
                                        <img
                                          src={PagesIndex.Svg.manageadvertiser}
                                          alt="FCT"
                                          className="admin-sidebar-icons"
                                        />
                                        <span className="admin-sidebar-link-text">
                                          FCT
                                        </span>
                                        {openUnderInventoryTVFCT ? (
                                          <Index.ExpandLess className="expandless-icon" />
                                        ) : (
                                          <Index.ExpandMore className="expandmore-icon" />
                                        )}
                                      </Index.Box>
                                      <Index.Box className="admin-submenu-main">
                                        <Index.Collapse
                                          in={openUnderInventoryTVFCT}
                                          timeout="auto"
                                          className="admin-submenu-collapse"
                                        >
                                          <Index.List
                                            component="div"
                                            disablePadding
                                            className="admin-sidebar-submenulist"
                                          >
                                            {[
                                              {
                                                title: "Template",
                                                pathName:
                                                  "/admin/slot-management/FCT",
                                                icon: PagesIndex.Svg
                                                  .slotManagmentIcon,
                                                show: adminDetails?.roleId?.permissions?.includes(
                                                  "slot_view"
                                                ),
                                                menuHighlight: [
                                                  "FCT",
                                                  "slot-management",
                                                  "add-slot",
                                                  "edit-slot",
                                                ],
                                              },
                                            ].map((data) => {
                                              return (
                                                data?.show && (
                                                  <Index.ListItem
                                                    className="admin-sidebar-listitem"
                                                    key={data?.pathName}
                                                  >
                                                    <Index.Link
                                                      to={data?.pathName}
                                                      onClick={() => {
                                                        localStorage.removeItem(
                                                          "currentPage"
                                                        );
                                                        if (
                                                          window.innerWidth <
                                                          786
                                                        ) {
                                                          props.setOpen(false);
                                                        }
                                                        document.body.classList[
                                                          props.open
                                                            ? "remove"
                                                            : "add"
                                                        ](
                                                          "admin-body-overflow"
                                                        );
                                                      }}
                                                      className={
                                                        data?.menuHighlight?.includes(
                                                          location?.pathname?.split(
                                                            "/"
                                                          )[3]
                                                        ) &&
                                                        data?.menuHighlight?.includes(
                                                          location?.pathname?.split(
                                                            "/"
                                                          )[2]
                                                        )
                                                          ? "admin-sidebar-link active"
                                                          : "admin-sidebar-link"
                                                      }
                                                    >
                                                      <img
                                                        src={data?.icon}
                                                        alt={data?.title}
                                                        className="admin-sidebar-icons"
                                                      />
                                                      <span className="admin-sidebar-link-text">
                                                        {data?.title}
                                                      </span>
                                                    </Index.Link>
                                                  </Index.ListItem>
                                                )
                                              );
                                            })}
                                          </Index.List>
                                        </Index.Collapse>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.ListItem>
                                )}
                                {(adminDetails?.roleId?.permissions?.includes(
                                  "slot_view"
                                ) ||
                                  adminDetails?.roleId?.permissions?.includes(
                                    "element_view"
                                  )) && (
                                  <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                                    <Index.Box className="admin-submenu-link-box">
                                      <Index.Box
                                        className="admin-sidebar-link"
                                        onClick={
                                          handleClickUnderInventoryTVNFCT
                                        }
                                      >
                                        <img
                                          src={PagesIndex.Svg.sponsershipicon}
                                          alt="Non FCT"
                                          className="admin-sidebar-icons"
                                        />
                                        <span className="admin-sidebar-link-text">
                                          Non FCT
                                        </span>
                                        {openUnderInventoryTVNFCT ? (
                                          <Index.ExpandLess className="expandless-icon" />
                                        ) : (
                                          <Index.ExpandMore className="expandmore-icon" />
                                        )}
                                      </Index.Box>
                                      <Index.Box className="admin-submenu-main">
                                        <Index.Collapse
                                          in={openUnderInventoryTVNFCT}
                                          timeout="auto"
                                          className="admin-submenu-collapse"
                                        >
                                          <Index.List
                                            component="div"
                                            disablePadding
                                            className="admin-sidebar-submenulist"
                                          >
                                            {[
                                              {
                                                title: "Element",
                                                pathName:
                                                  "/admin/element-management/NonFCT",
                                                icon: PagesIndex.Svg
                                                  .elementIcon,
                                                show: adminDetails?.roleId?.permissions?.includes(
                                                  "element_view"
                                                ),
                                                menuHighlight: [
                                                  "NonFCT",
                                                  "element-management",
                                                ],
                                              },
                                              {
                                                title: "Template",
                                                pathName:
                                                  "/admin/slot-management/NonFCT",
                                                icon: PagesIndex.Svg
                                                  .slotManagmentIcon,
                                                show: adminDetails?.roleId?.permissions?.includes(
                                                  "slot_view"
                                                ),
                                                menuHighlight: [
                                                  "NonFCT",
                                                  "slot-management",
                                                  "add-slot",
                                                  "edit-slot",
                                                ],
                                              },
                                              // {
                                              //   title: "Program Type",
                                              //   pathName:
                                              //     "/admin/program-type-management",
                                              //   icon: PagesIndex.Svg.programTypeIcon,
                                              //   show: adminDetails?.roleId?.permissions?.includes(
                                              //     "program_type_view"
                                              //   ),
                                              //   menuHighlight: [
                                              //     "program-type-management",
                                              //   ],
                                              // },
                                              // {
                                              //   title: "Platform",
                                              //   pathName:
                                              //     "/admin/platform-management",
                                              //   icon: PagesIndex.Svg.channel,
                                              //   show: adminDetails?.roleId?.permissions?.includes(
                                              //     "platform_view"
                                              //   ),
                                              //   menuHighlight: [
                                              //     "platform-management",
                                              //   ],
                                              // },
                                            ].map((data) => {
                                              return (
                                                data?.show && (
                                                  <Index.ListItem
                                                    className="admin-sidebar-listitem"
                                                    key={data?.pathName}
                                                  >
                                                    <Index.Link
                                                      to={data?.pathName}
                                                      onClick={() => {
                                                        localStorage.removeItem(
                                                          "currentPage"
                                                        );
                                                        if (
                                                          window.innerWidth <
                                                          786
                                                        ) {
                                                          props.setOpen(false);
                                                        }
                                                        document.body.classList[
                                                          props.open
                                                            ? "remove"
                                                            : "add"
                                                        ](
                                                          "admin-body-overflow"
                                                        );
                                                      }}
                                                      className={
                                                        location?.pathname?.split(
                                                          "/"
                                                        )[3]
                                                          ? data?.menuHighlight?.includes(
                                                              location?.pathname?.split(
                                                                "/"
                                                              )[3]
                                                            ) &&
                                                            data?.menuHighlight?.includes(
                                                              location?.pathname?.split(
                                                                "/"
                                                              )[2]
                                                            )
                                                            ? "admin-sidebar-link active"
                                                            : "admin-sidebar-link"
                                                          : data?.menuHighlight?.includes(
                                                              location?.pathname?.split(
                                                                "/"
                                                              )[2]
                                                            )
                                                          ? "admin-sidebar-link active"
                                                          : "admin-sidebar-link"
                                                      }
                                                    >
                                                      <img
                                                        src={data?.icon}
                                                        alt={data?.title}
                                                        className="admin-sidebar-icons"
                                                      />
                                                      <span className="admin-sidebar-link-text">
                                                        {data?.title}
                                                      </span>
                                                    </Index.Link>
                                                  </Index.ListItem>
                                                )
                                              );
                                            })}
                                          </Index.List>
                                        </Index.Collapse>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.ListItem>
                                )}
                              </Index.Collapse>
                            </Index.Box>
                          </Index.Box>
                        </Index.ListItem>
                      )}
                      {adminDetails?.roleId?.permissions?.includes(
                        "element_view"
                      ) && (
                        <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                          <Index.Box className="admin-submenu-link-box">
                            <Index.Box
                              className="admin-sidebar-link"
                              onClick={handleClickUnderInventoryWeb}
                            >
                              <img
                                src={PagesIndex.Svg.zoneicon}
                                alt="Website"
                                className="admin-sidebar-icons"
                              />
                              <span className="admin-sidebar-link-text">
                                Website
                              </span>
                              {openUnderInventoryWeb ? (
                                <Index.ExpandLess className="expandless-icon" />
                              ) : (
                                <Index.ExpandMore className="expandmore-icon" />
                              )}
                            </Index.Box>
                            <Index.Box className="admin-submenu-main">
                              <Index.Collapse
                                in={openUnderInventoryWeb}
                                timeout="auto"
                                className="admin-submenu-collapse"
                              >
                                <Index.List
                                  component="div"
                                  disablePadding
                                  className="admin-sidebar-submenulist"
                                >
                                  {[
                                    {
                                      title: "Element",
                                      pathName:
                                        "/admin/element-management/website",
                                      icon: PagesIndex.Svg.elementIcon,
                                      show: adminDetails?.roleId?.permissions?.includes(
                                        "element_view"
                                      ),
                                      menuHighlight: [
                                        "website",
                                        "element-management",
                                      ],
                                    },
                                  ].map((data) => {
                                    return (
                                      data?.show && (
                                        <Index.ListItem
                                          className="admin-sidebar-listitem"
                                          key={data?.pathName}
                                        >
                                          <Index.Link
                                            to={data?.pathName}
                                            onClick={() => {
                                              localStorage.removeItem(
                                                "currentPage"
                                              );
                                              if (window.innerWidth < 786) {
                                                props.setOpen(false);
                                              }
                                              document.body.classList[
                                                props.open ? "remove" : "add"
                                              ]("admin-body-overflow");
                                            }}
                                            className={
                                              data?.menuHighlight?.includes(
                                                location?.pathname?.split(
                                                  "/"
                                                )[3]
                                              )
                                                ? "admin-sidebar-link active"
                                                : "admin-sidebar-link"
                                            }
                                          >
                                            <img
                                              src={data?.icon}
                                              alt={data?.title}
                                              className="admin-sidebar-icons"
                                            />
                                            <span className="admin-sidebar-link-text">
                                              {data?.title}
                                            </span>
                                          </Index.Link>
                                        </Index.ListItem>
                                      )
                                    );
                                  })}
                                </Index.List>
                              </Index.Collapse>
                            </Index.Box>
                          </Index.Box>
                        </Index.ListItem>
                      )}
                      {adminDetails?.roleId?.permissions?.includes(
                        "element_view"
                      ) && (
                        <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                          <Index.Box className="admin-submenu-link-box">
                            <Index.Box
                              className="admin-sidebar-link"
                              onClick={handleClickUnderInventoryShots}
                            >
                              <img
                                src={PagesIndex.Svg.advertisementIcon}
                                alt="NC Shots"
                                className="admin-sidebar-icons"
                              />
                              <span className="admin-sidebar-link-text">
                                NC Shots
                              </span>
                              {openUnderInventoryShots ? (
                                <Index.ExpandLess className="expandless-icon" />
                              ) : (
                                <Index.ExpandMore className="expandmore-icon" />
                              )}
                            </Index.Box>
                            <Index.Box className="admin-submenu-main">
                              <Index.Collapse
                                in={openUnderInventoryShots}
                                timeout="auto"
                                className="admin-submenu-collapse"
                              >
                                <Index.List
                                  component="div"
                                  disablePadding
                                  className="admin-sidebar-submenulist"
                                >
                                  {[
                                    {
                                      title: "Element",
                                      pathName:
                                        "/admin/element-management/NCShots",
                                      icon: PagesIndex.Svg.elementIcon,
                                      show: adminDetails?.roleId?.permissions?.includes(
                                        "element_view"
                                      ),
                                      menuHighlight: [
                                        "NCShots",
                                        "element-management",
                                      ],
                                    },
                                  ].map((data) => {
                                    return (
                                      data?.show && (
                                        <Index.ListItem
                                          className="admin-sidebar-listitem"
                                          key={data?.pathName}
                                        >
                                          <Index.Link
                                            to={data?.pathName}
                                            onClick={() => {
                                              localStorage.removeItem(
                                                "currentPage"
                                              );
                                              if (window.innerWidth < 786) {
                                                props.setOpen(false);
                                              }
                                              document.body.classList[
                                                props.open ? "remove" : "add"
                                              ]("admin-body-overflow");
                                            }}
                                            className={
                                              data?.menuHighlight?.includes(
                                                location?.pathname?.split(
                                                  "/"
                                                )[3]
                                              )
                                                ? "admin-sidebar-link active"
                                                : "admin-sidebar-link"
                                            }
                                          >
                                            <img
                                              src={data?.icon}
                                              alt={data?.title}
                                              className="admin-sidebar-icons"
                                            />
                                            <span className="admin-sidebar-link-text">
                                              {data?.title}
                                            </span>
                                          </Index.Link>
                                        </Index.ListItem>
                                      )
                                    );
                                  })}
                                </Index.List>
                              </Index.Collapse>
                            </Index.Box>
                          </Index.Box>
                        </Index.ListItem>
                      )}
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {[
                          {
                            title: "OTT",
                            pathName: "/admin/inventory-ads-management",
                            icon: PagesIndex.Svg.stateIcon,
                            show: adminDetails?.roleId?.permissions?.includes(
                              "ads_view"
                            ),
                            menuHighlight: [
                              "inventory-ads-management",
                              "view-ads-management",
                              "add-ads-management",
                              "edit-ads-management",
                            ],
                          },
                        ].map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    data?.menuHighlight?.includes(
                                      location?.pathname?.split("/")[2]
                                    )
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {adminDetails?.roleId?.permissions?.includes("creative_view") && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickCreative}
                  >
                    <img
                      src={PagesIndex.Svg.creativeIcon}
                      alt="Creative"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">Creative</span>
                    {openCreative ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>

                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openCreative}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {[
                          {
                            title: "Add Creative",
                            pathName: "/admin/creative-management",
                            icon: PagesIndex.Svg.creativeIcon,
                            show: adminDetails?.roleId?.permissions?.includes(
                              "creative_view"
                            ),
                            menuHighlight: ["creative-management"],
                          },
                        ].map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    if (data?.pathName) {
                                      localStorage.removeItem("currentPage");
                                      if (window.innerWidth < 786) {
                                        props.setOpen(false);
                                      }
                                      document.body.classList[
                                        props.open ? "remove" : "add"
                                      ]("admin-body-overflow");
                                    }
                                  }}
                                  className={
                                    data?.menuHighlight?.includes(
                                      location?.pathname?.split("/")[2]
                                    )
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {/* <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
              <Index.Box className="admin-submenu-link-box">
                <Index.Box
                  className="admin-sidebar-link"
                  onClick={handleClickAdBooking}
                >
                  <img
                    src={PagesIndex.Svg.addBookingIcon}
                    alt="AD Booking"
                    className="admin-sidebar-icons"
                  />
                  <span className="admin-sidebar-link-text">AD Booking</span>
                  {openAdBooking ? (
                    <Index.ExpandLess className="expandless-icon" />
                  ) : (
                    <Index.ExpandMore className="expandmore-icon" />
                  )}
                </Index.Box>
                <Index.Box className="admin-submenu-main">
                  <Index.Collapse
                    in={openAdBooking}
                    timeout="auto"
                    className="admin-submenu-collapse"
                  >
                    <Index.List
                      component="div"
                      disablePadding
                      className="admin-sidebar-submenulist"
                    >
                      {[
                        {
                          title: "Book Ad",
                          pathName: "/admin/comingSoon/Book-Ad",

                          icon: PagesIndex.Svg.addBookingIcon,
                          show: true,
                          menuHighlight: ["Book-Ad"],
                        },
                      ].map((data) => {
                        return (
                          data?.show && (
                            <Index.ListItem
                              className="admin-sidebar-listitem"
                              key={data?.pathName}
                            >
                              <Index.Link
                                to={data?.pathName}
                                onClick={() => {
                                  if (window.innerWidth < 786) {
                                    props.setOpen(false);
                                  }
                                  document.body.classList[
                                    props.open ? "remove" : "add"
                                  ]("admin-body-overflow");
                                }}
                                className={
                                  data?.menuHighlight?.includes(
                                    location?.pathname?.split("/")[3]
                                  )
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                <img
                                  src={data?.icon}
                                  alt={data?.title}
                                  className="admin-sidebar-icons"
                                />
                                <span className="admin-sidebar-link-text">
                                  {data?.title}
                                </span>
                              </Index.Link>
                            </Index.ListItem>
                          )
                        );
                      })}
                    </Index.List>
                  </Index.Collapse>
                </Index.Box>
              </Index.Box>
            </Index.ListItem> */}
            {MoviesAndSeries?.filter((ele) => ele?.show == true)?.length >
              0 && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickMoviesSeries}
                  >
                    <img
                      src={PagesIndex.Svg.movieicon}
                      alt="Movies & Series"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">
                      Movies & Series
                    </span>
                    {openMoviesSeries ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openMoviesSeries}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {MoviesAndSeries?.map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    data?.menuHighlight?.includes(
                                      location?.pathname?.split("/")[2]
                                    )
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {(adminDetails?.roleId?.permissions?.includes(
              "admin_user_view",
              "user-activity-log"
            ) ||
              adminDetails?.roleId?.permissions?.includes("role_view")) && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickSettings}
                  >
                    <img
                      src={PagesIndex.Svg.manageadminuser}
                      alt="General Settings"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">
                      Manage Admin
                    </span>
                    {openSettings ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openSettings}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {[
                          {
                            title: "Roles",
                            pathName: "/admin/role-management",
                            icon: PagesIndex.Svg.roleManagment,
                            show: adminDetails?.roleId?.permissions?.includes(
                              "role_view"
                            ),
                          },
                          {
                            title: "Admin Users",
                            pathName: "/admin/subAdmin-management",
                            icon: PagesIndex.Svg.manageadminicon,
                            show: adminDetails?.roleId?.permissions?.includes(
                              "admin_user_view",
                              "user-activity-log"
                            ),
                          },
                        ].map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    location?.pathname.includes(data?.pathName)
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {ManageInquiry?.filter((ele) => ele?.show == true)?.length > 0 && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickListings}
                  >
                    <img
                      src={PagesIndex.Svg.managelist}
                      alt="General Listings"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">
                      Manage Inquiry
                    </span>
                    {openListings ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openListings}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {ManageInquiry?.map((data) => {
                          return (
                            data?.show && (
                              <Index.ListItem
                                className="admin-sidebar-listitem"
                                key={data?.pathName}
                              >
                                {/* <Index.Tooltip
                                title={data?.title}
                                arrow
                                placement="right"
                                className="admin-tooltip"
                              > */}
                                <Index.Link
                                  to={data?.pathName}
                                  onClick={() => {
                                    localStorage.removeItem("currentPage");
                                    if (window.innerWidth < 786) {
                                      props.setOpen(false);
                                    }
                                    document.body.classList[
                                      props.open ? "remove" : "add"
                                    ]("admin-body-overflow");
                                  }}
                                  className={
                                    location?.pathname.includes(data?.pathName)
                                      ? "admin-sidebar-link active"
                                      : "admin-sidebar-link"
                                  }
                                >
                                  <img
                                    src={data?.icon}
                                    alt={data?.title}
                                    className="admin-sidebar-icons"
                                  />
                                  <span className="admin-sidebar-link-text">
                                    {data?.title}
                                  </span>
                                </Index.Link>
                                {/* </Index.Tooltip> */}
                              </Index.ListItem>
                            )
                          );
                        })}
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {adminDetails?.roleId?.permissions?.includes("cms_view") && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickAccount}
                  >
                    <img
                      src={PagesIndex.Svg.cmsicon}
                      alt="CMS"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">CMS</span>
                    {open ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={open}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/terms-and-condition"
                            className={
                              location?.pathname.includes(
                                "/admin/cms/terms-and-condition"
                              )
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            <img
                              src={PagesIndex.Svg.termsandconditionicon}
                              alt="Terms & Conditions"
                              className="admin-sidebar-icons"
                            />
                            <span className="admin-sidebar-link-text">
                              Terms & Conditions
                            </span>
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/privacy-policy"
                            className={
                              location?.pathname.includes(
                                "/admin/cms/privacy-policy"
                              )
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            <img
                              src={PagesIndex.Svg.policyicon}
                              alt="Privacy Policy"
                              className="admin-sidebar-icons"
                            />
                            <span className="admin-sidebar-link-text">
                              Privacy Policy
                            </span>
                          </Index.Link>
                        </Index.ListItem>

                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/faq-management"
                            className={
                              location?.pathname.includes(
                                "/admin/faq-management"
                              )
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            <img
                              src={PagesIndex.Svg.faqIcon}
                              alt="Privacy Policy"
                              className="admin-sidebar-icons"
                            />
                            <span className="admin-sidebar-link-text">FAQ</span>
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}

            {adminDetails?.roleId?.permissions?.includes("sponsor_view") && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickSponsorLogo}
                  >
                    <img
                      src={PagesIndex.Svg.sponsershipicon}
                      alt="Sponsor Logo"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">
                      Sponsor Logo
                    </span>
                    {openSponsorLogo ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openSponsorLogo}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/sponsor-management"
                            className={
                              location?.pathname.includes(
                                "/admin/sponsor-management"
                              ) ||
                              location?.pathname.includes(
                                "/admin/sponsor/sponsor-activity-log"
                              ) ||
                              location?.pathname.includes("/admin/view-sponsor")
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link"
                            }
                          >
                            <img
                              src={PagesIndex.Svg.scheduleIcons}
                              alt="Manage Sponsor Logo"
                              className="admin-sidebar-icons"
                            />
                            <span className="admin-sidebar-link-text">
                              Manage Sponsor Logo
                            </span>
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}
            {(adminDetails?.roleId?.permissions?.includes("voters_list_view") ||
              adminDetails?.roleId?.permissions?.includes(
                "report_analytics_view"
              )) && (
              <Index.ListItem className="admin-sidebar-listitem admin-submenu-listitem-main">
                <Index.Box className="admin-submenu-link-box">
                  <Index.Box
                    className="admin-sidebar-link"
                    onClick={handleClickReports}
                  >
                    <img
                      src={PagesIndex.Svg.anlytics}
                      alt="Reports & Analytics"
                      className="admin-sidebar-icons"
                    />
                    <span className="admin-sidebar-link-text">
                      Reports & Analytics
                    </span>
                    {openReports ? (
                      <Index.ExpandLess className="expandless-icon" />
                    ) : (
                      <Index.ExpandMore className="expandmore-icon" />
                    )}
                  </Index.Box>
                  <Index.Box className="admin-submenu-main">
                    <Index.Collapse
                      in={openReports}
                      timeout="auto"
                      className="admin-submenu-collapse"
                    >
                      {[
                        {
                          title: "Advertisement",
                          pathName: "/admin/report-and-analytics",
                          icon: PagesIndex.Svg.advertisementIcon,
                          show: adminDetails?.roleId?.permissions?.includes(
                            "report_analytics_view"
                          ),
                          menuHighlight: ["report-and-analytics"],
                        },
                        {
                          title: "Voters",
                          pathName: "/admin/voter-list",
                          icon: PagesIndex.Svg.categoryIcon,
                          show: adminDetails?.roleId?.permissions?.includes(
                            "voters_list_view"
                          ),
                          menuHighlight: ["voter-list"],
                        },
                      ].map((data) => {
                        return (
                          data?.show && (
                            <Index.ListItem
                              className="admin-sidebar-listitem"
                              key={data?.pathName}
                            >
                              <Index.Link
                                to={data?.pathName}
                                onClick={() => {
                                  localStorage.removeItem("currentPage");
                                  if (window.innerWidth < 786) {
                                    props.setOpen(false);
                                  }
                                  document.body.classList[
                                    props.open ? "remove" : "add"
                                  ]("admin-body-overflow");
                                }}
                                className={
                                  data?.menuHighlight?.includes(
                                    location?.pathname?.split("/")[2]
                                  )
                                    ? "admin-sidebar-link active"
                                    : "admin-sidebar-link"
                                }
                              >
                                <img
                                  src={data?.icon}
                                  alt={data?.title}
                                  className="admin-sidebar-icons"
                                />
                                <span className="admin-sidebar-link-text">
                                  {data?.title}
                                </span>
                              </Index.Link>
                            </Index.ListItem>
                          )
                        );
                      })}
                    </Index.Collapse>
                  </Index.Box>
                </Index.Box>
              </Index.ListItem>
            )}
            {[
              {
                title: "Program",
                pathName: "/admin/program-management",
                icon: PagesIndex.Svg.programTypeIcon,
                show: adminDetails?.roleId?.permissions?.includes(
                  "program_view"
                ),
                menuHighlight: ["program-management"],
              },
              {
                title: "Phone Directory",
                pathName: "/admin/phone-directory",
                icon: PagesIndex.Svg.phoneDirectory,
                show: adminDetails?.roleId?.permissions?.includes(
                  "phone_directory_view"
                ),
                menuHighlight: ["phone-directory"],
              },
              {
                title: "Internal Server Video",
                pathName: "/admin/upload-video",
                icon: PagesIndex.Svg.serverVideoIcon,
                show:
                  adminDetails?.roleId?.permissions?.includes(
                    "upload_video_view"
                  ) ||
                  adminDetails?.roleId?.permissions?.includes(
                    "upload_video_view_all"
                  ),
                menuHighlight: ["upload-video"],
              },
              {
                title: "Inernal Server Link",
                pathName: "/admin/server-link-management",
                icon: PagesIndex.Svg.serverLinkIcon,
                show:
                  adminDetails?.roleId?.permissions?.includes(
                    "upload_video_view"
                  ) ||
                  adminDetails?.roleId?.permissions?.includes(
                    "upload_video_view_all"
                  ),
                menuHighlight: ["server-link-management"],
              },
            ].map((data) => {
              return (
                data?.show && (
                  <Index.ListItem
                    className="admin-sidebar-listitem"
                    key={data?.pathName}
                  >
                    <Index.Link
                      to={data?.pathName}
                      onClick={() => {
                        localStorage.removeItem("currentPage");
                        if (window.innerWidth < 786) {
                          props.setOpen(false);
                        }
                        document.body.classList[props.open ? "remove" : "add"](
                          "admin-body-overflow"
                        );
                      }}
                      className={
                        data?.menuHighlight?.includes(
                          location?.pathname?.split("/")[2]
                        )
                          ? "admin-sidebar-link active"
                          : "admin-sidebar-link"
                      }
                    >
                      <img
                        src={data?.icon}
                        alt={data?.title}
                        className="admin-sidebar-icons"
                      />
                      <span className="admin-sidebar-link-text">
                        {data?.title}
                      </span>
                    </Index.Link>
                  </Index.ListItem>
                )
              );
            })}
          </Index.List>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}
Sidebar.propTypes = {
  open: PropTypes.bool.isRequired, // Assuming open is a required boolean prop
  setOpen: PropTypes.func.isRequired, // Assuming setOpen is a required function prop
};
