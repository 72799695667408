import React, { useCallback, useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import PagesIndex from "../../../PagesIndex";
import "./news.css";
import "./news.responsive.css";
import News from "./News";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

function BpCheckbox(props) {
  return (
    <Index.Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  border: "1px solid #b2b3b3",
  width: 12,
  height: 12,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "transparent",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: 12,
    height: 12,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23114A65'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export default function NewsTab() {
  const { adminDetails } = PagesIndex.useSelector(
    (state) => state.AdminReducer
  );
  const formRef = useRef();
  const exportFormRef = useRef();
  const params = useLocation();
  const [valueTabs, setValueTabs] = useState(
    params?.state?.page && params?.state?.page !== 1 ? params?.state?.page : 1
  );

  const handleChangeTabs = (event, newValue) => {
    setCurrentPage(1);
    setValueTabs(newValue);
    formRef.current.resetForm();
  };
  // page navigate
  const navigate = PagesIndex.useNavigate();
  const [currentPage, setCurrentPage] = useState(
    localStorage.getItem("currentPage") || 1
  );
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // for chip tags

  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [openCalenderFrom, setOpenCalenderFrom] = useState(false);
  const [openCalenderTo, setOpenCalenderTo] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [sectionData, setSectionData] = useState([]);
  const [homeCategory, setHomeCategory] = useState([]);

  const searchInitialValues = {
    district: "",
    sectionToShow: "",
    category: "",
    search: "",
  };
  // getNews
  const handleGetNews = useCallback(() => {
    const values = formRef.current.values;
    let selectType =
      valueTabs === 1 ? "published" : valueTabs === 2 ? "scheduled" : "draft";
    const urlencoded = new URLSearchParams();
    urlencoded.append("type", selectType);
    urlencoded.append("search", values?.search ? values?.search : "");
    urlencoded.append("category", values?.category ? values?.category : "");
    urlencoded.append(
      "sectionToShow",
      values?.sectionToShow ? values?.sectionToShow : ""
    );
    urlencoded.append("district", values?.district ? values?.district : "");

    urlencoded.append("pageSize", rowsPerPage);
    urlencoded.append("pageNumber", currentPage);
    setLoading(true);
    PagesIndex.apiPostHandler(PagesIndex.Api.GET_NEWS, urlencoded).then(
      (res) => {
        setLoading(false);
        if (res.status === 200) {
          setFilteredData(res.data?.paginatedResults);
          setDataLength(res.data?.totalCount?.count);
        } else {
          PagesIndex.toasterError(res?.message);
          setFilteredData([]);
        }
      }
    );
  }, [valueTabs, currentPage, rowsPerPage]);

  const handleGetCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_CATEGORY).then((res) => {
      if (res.status === 200) {
        const activeCategory = res?.data?.filter((data) => data?.isActive);
        setCategoryData(activeCategory);
      }
    });
  };

  useEffect(() => {
    handleGetCategory();
    handleGetDistrict();
    handleGetHomeCategory();
  }, []);
  useEffect(() => {
    handleGetNews(formRef?.current.values);
  }, [handleGetNews]);
  const handleOpenExportModal = () => {
    setExportModalOpen(true);
  };
  const handleCloseExportModal = () => {
    exportFormRef.current.resetForm();
    setExportModalOpen(false);
  };
  const initialValues = {
    startDate: null,
    endDate: null,
    category: [],
    newsType: [],
    district: [],
    checked: sectionData?.length ? sectionData : [],
  };
  const handleGetDistrict = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_DISTRICTMASTER).then((res) => {
      if (res.status === 200) {
        setDistrictData(res.data?.filter((data) => data?.isActive));
      }
    });
  };
  const handleGetHomeCategory = () => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_HOME_CATEGORY).then((res) => {
      if (res.status === 200) {
        const sectionDataList = res.data
          ?.filter(
            (data) =>
              data?._id !== "660cfd2b2005bb0bea620452" &&
              data?._id !== "660cfd1c2005bb0bea62043e"
          )
          .map((data) => ({
            _id: data?._id,
            label: data?.name,
            checked: false,
          }));
        setSectionData(sectionDataList);
        setHomeCategory(res.data);
      }
    });
  };
  const exportData = (data, fileName, type) => {
    // Create a link and download the file
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleExportCsv = (values) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append(
      "from",
      PagesIndex.moment(new Date(values?.startDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append(
      "to",
      PagesIndex.moment(new Date(values?.endDate)).format("YYYY-MM-DD")
    );
    urlEncoded.append("type", valueTabs);
    urlEncoded.append("district", JSON.stringify(values?.district));
    urlEncoded.append("category", JSON.stringify(values?.category));
    urlEncoded.append(
      "sectionToShow",
      JSON.stringify(
        values?.checked
          ?.filter((data) => data?.checked)
          ?.map((data) => data?._id)
      )
    );
    PagesIndex.apiPostHandler(PagesIndex.Api.EXPORT_NEWS, urlEncoded).then(
      (res) => {
        if (res.status === 200) {
          const headers = [
            "Sr. No.",
            "Video Id",
            "Video Title",
            "Description",
            "Location",
            "State",
            "District",
            "Zone",
            "City",
            "Categories",
            "Tags",
          ];
          let modifiedData = res?.data?.map((data, index) => {
            let objData = [
              `${index + 1}`,
              data?.newsNo ? data?.newsNo.replaceAll(",", "") : "-",
              data?.newsTitle
                ? data?.newsTitle
                    .replaceAll(",", "")
                    .replaceAll(/(\r\n|\n|\r)/gm, "")
                : "-",
              data?.description
                ? data?.description
                    .replaceAll(",", "")
                    .replaceAll(/(\r\n|\n|\r)/gm, "")
                : "-",
              data?.location ? data?.location?.replaceAll(",", "") : "-",
              data?.state?.stateName
                ? data?.state?.stateName.replaceAll(",", "")
                : "-",
              data?.district?.name
                ? data?.district?.name.replaceAll(",", "")
                : "-",
              data?.zone?.name ? data?.zone?.name.replaceAll(",", "") : "-",
              data?.city ? data?.city.replaceAll(",", "") : "-",
              data?.category?.length
                ? `${data?.category
                    ?.map((data) => data?.categoryName.replaceAll(",", ""))
                    ?.toString()
                    ?.replaceAll(",", "|")}`
                : "-",
              data?.tags?.length
                ? `${data?.tags
                    ?.map((data) => data?.tag.replaceAll(",", ""))
                    ?.toString()
                    ?.replaceAll(",", "|")}`
                : "-",
            ];
            return objData.join(",");
          });
          handleCloseExportModal();
          exportData(
            [[headers, ...modifiedData].join("\n")],
            `${PagesIndex.moment().format(
              "DD-MM-YYYY hh:mm:ss A"
            )}_Videos_list.csv`,
            "text/csv"
          );
        }
      }
    );
  };

  const handleSearchData = (values) => {
    if (currentPage === 1) {
      handleGetNews(values);
    }
    if (currentPage > 1) {
      setCurrentPage(1);
    }
  };
  return (
    <Index.Box className="admin-dashboard-content admin-user-management-content">
      <Index.Box className="admin-user-list-flex admin-page-title-main">
        <Index.Typography
          className="admin-page-title admin-user-list-page-title"
          component="h2"
          variant="h2"
        >
          Videos List
        </Index.Typography>
        <Index.Box className="admin-userlist-btn-flex">
          <Index.Box className="admin-search-main">
            {/* <Index.Box className="admin-search-box">
              <Index.Box className="admin-form-group">
                <Index.TextField
                  fullWidth
                  id="fullWidth"
                  className="admin-form-control"
                  placeholder="Search"
                  value={searchValue}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setSearchValue(e.target.value);
                  }}
                />
                <img
                  src={PagesIndex.Svg.search}
                  className="admin-search-grey-img admin-icon"
                  alt="search"
                ></img>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>

          <Index.Box className="admin-userlist-inner-btn-flex">
            {adminDetails?.roleId?.permissions?.includes("videos_add") && (
              <Index.Box className="admin-adduser-btn-main btn-main-primary">
                <Index.Button
                  className="admin-adduser-btn btn-primary"
                  onClick={() => {
                    navigate("/admin/add-video");
                    localStorage.setItem("currentPage", 1);
                  }}
                >
                  <img
                    src={PagesIndex.Svg.plus}
                    className="admin-plus-icon"
                    alt="plus"
                  />
                  <span>Add Video</span>
                </Index.Button>
              </Index.Box>
            )}
            <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={handleOpenExportModal}
              >
                <img
                  src={PagesIndex.Svg.exporticon}
                  className="admin-plus-icon"
                  alt="plus"
                />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>
          </Index.Box>

          {/* <Index.Box className="admin-userlist-inner-btn-flex">
            <Index.Box className="admin-adduser-btn-main btn-main-secondary">
              <Index.Button
                className="admin-adduser-btn btn-secondary"
                onClick={handleOpenExportModal}
              >
                 <img
                    src={PagesIndex.Svg.exporticon}
                    className="admin-plus-icon"
                    alt="plus"
                  />
                <span>Export CSV</span>
              </Index.Button>
            </Index.Box>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>

      <Index.Box className="filter-card common-card">
        <Index.Box className="filter-flex">
          <Index.Box className="grid-row">
            <PagesIndex.Formik
              enableReinitialize
              onSubmit={handleSearchData}
              initialValues={searchInitialValues}
              // validationSchema={PagesIndex.newsListFilterSchema}
              innerRef={formRef}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit} noValidate="novalidate">
                  <Index.Box className="grid-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                      <Index.Box
                        display="grid"
                        gridTemplateColumns="repeat(12, 1fr)"
                        gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                      >
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 3",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              District
                            </Index.FormHelperText>
                            <Index.Box className="admin-dropdown-box">
                              <Index.FormControl className="admin-form-control admin-drop-form-control">
                                <Index.Autocomplete
                                  id="district"
                                  className="admin-auto-complete-control"
                                  options={(districtData || []).sort((a, b) =>
                                    a?.name?.localeCompare(b?.name)
                                  )}
                                  // disableClearable={true}
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={
                                    values?.district && districtData
                                      ? districtData.find(
                                          (o) => o._id === values.district
                                        ) || null
                                      : null
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("district", value?._id);
                                  }}
                                  getOptionLabel={(option) => option?.name}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      name="district"
                                      className="admin-form-control"
                                      {...params}
                                      placeholder="Select District"
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Index.FormControl>
                              {touched.district && errors.district && (
                                <PagesIndex.FormHelperText className="admin-error-text">
                                  {errors.district}
                                </PagesIndex.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Category
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  id="category"
                                  className="admin-auto-complete-control"
                                  options={(categoryData || []).sort((a, b) =>
                                    a?.name?.localeCompare(b?.name)
                                  )}
                                  // disableClearable={true}
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={
                                    values?.category && categoryData
                                      ? categoryData.find(
                                          (o) => o._id === values.category
                                        ) || null
                                      : null
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("category", value?._id);
                                  }}
                                  getOptionLabel={(option) =>
                                    option?.categoryName
                                  }
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.categoryName}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      name="category"
                                      className="admin-form-control"
                                      {...params}
                                      placeholder="Select Category"
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />
                                {touched.category && errors.category && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.category}
                                  </PagesIndex.FormHelperText>
                                )}
                              </Index.FormControl>

                              <Index.FormControl className="admin-form-control admin-drop-form-control"></Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 2",
                            md: "span 2",
                            lg: "span 2",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Section To Show
                            </Index.FormHelperText>
                            <Index.Box className="admin-form-group">
                              <Index.FormControl className="admin-form-control admin-auto-complete-control">
                                <Index.Autocomplete
                                  id="sectionToShow"
                                  className="admin-auto-complete-control"
                                  options={(homeCategory || []).sort((a, b) =>
                                    a?.name?.localeCompare(b?.name)
                                  )}
                                  // disableClearable={true}
                                  closeText={""}
                                  openText={""}
                                  clearText={""}
                                  value={
                                    values?.sectionToShow && homeCategory
                                      ? homeCategory.find(
                                          (o) => o._id === values.sectionToShow
                                        ) || null
                                      : null
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("sectionToShow", value?._id);
                                  }}
                                  getOptionLabel={(option) => option?.name}
                                  renderOption={(props, item) => (
                                    <li {...props} key={item?._id}>
                                      {item?.name}
                                    </li>
                                  )}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      fullWidth
                                      name="sectionToShow"
                                      className="admin-form-control"
                                      {...params}
                                      placeholder="Select Section To Show"
                                      size="small"
                                      variant="outlined"
                                    />
                                  )}
                                />

                                {touched.sectionToShow &&
                                  errors.sectionToShow && (
                                    <PagesIndex.FormHelperText className="admin-error-text">
                                      {errors.sectionToShow}
                                    </PagesIndex.FormHelperText>
                                  )}
                              </Index.FormControl>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 3",
                            md: "span 3",
                            lg: "span 3",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box">
                            <Index.FormHelperText className="admin-form-lable">
                              Search
                            </Index.FormHelperText>
                            <Index.Box className="admin-search-box">
                              <Index.Box className="admin-form-group">
                                <Index.TextField
                                  fullWidth
                                  id="search"
                                  className="admin-form-control"
                                  placeholder="Enter Search"
                                  name="search"
                                  onChange={handleChange}
                                  value={values?.search}
                                  inputProps={{ maxLength: 500 }}
                                />
                                {touched.search && errors.search && (
                                  <PagesIndex.FormHelperText className="admin-error-text">
                                    {errors.search}
                                  </PagesIndex.FormHelperText>
                                )}
                                <img
                                  src={PagesIndex.Svg.search}
                                  className="admin-search-grey-img admin-icon"
                                  alt="search"
                                ></img>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box
                          gridColumn={{
                            xs: "span 12",
                            sm: "span 1",
                            md: "span 1",
                            lg: "span 1",
                          }}
                          className="grid-column"
                        >
                          <Index.Box className="admin-input-box admin-add-news-input-box">
                            <Index.FormHelperText className="admin-form-lable"></Index.FormHelperText>
                            <Index.Box className="filter-btns filter-btns-news">
                              <Index.Tooltip
                                title="Search"
                                arrow
                                placement="top"
                                className="admin-tooltip"
                              >
                                <Index.Box className="btn-main-primary admin-icons-btn-main">
                                  <Index.Button
                                    className="btn-primary admin-icons-btn"
                                    type="submit"
                                  >
                                    <img
                                      src={PagesIndex.Svg.searchwhite}
                                      className="admin-icons"
                                      alt="Save"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip>
                              <Index.Tooltip
                                title="Reset"
                                arrow
                                placement="top"
                                className="admin-tooltip"
                              >
                                <Index.Box className="btn-main-primary admin-icons-btn-main">
                                  <Index.Button
                                    className="btn-primary admin-icons-btn"
                                    onClick={() => {
                                      formRef?.current?.resetForm();
                                      setCurrentPage(1);
                                      setTimeout(() => {
                                        handleGetNews();
                                      }, 1000);
                                    }}
                                  >
                                    <img
                                      src={PagesIndex.Svg.reset}
                                      className="admin-icons"
                                      alt="Reset"
                                    />
                                  </Index.Button>
                                </Index.Box>
                              </Index.Tooltip>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </PagesIndex.Formik>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Box className="news-tabs-main">
        <Index.Box className="admin-tabs-main-box">
          <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Index.Tabs
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              className="admin-tabs-main"
            >
              <Index.Tab label="Save as Draft" className="admin-tab" />
              <Index.Tab label="Publish" className="admin-tab" />
              <Index.Tab label="Publish with Schedule" className="admin-tab" />
            </Index.Tabs>
          </Index.Box>
          <TabPanel value={valueTabs} index={0} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <News
                filteredData={filteredData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleGetNews={handleGetNews}
                valueTabs={valueTabs}
                loading={loading}
                dataLength={dataLength}
              />
            </Index.Box>
          </TabPanel>
          <TabPanel value={valueTabs} index={1} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <News
                filteredData={filteredData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleGetNews={handleGetNews}
                valueTabs={valueTabs}
                loading={loading}
                dataLength={dataLength}
              />
            </Index.Box>
          </TabPanel>
          <TabPanel value={valueTabs} index={2} className="admin-tabpanel">
            <Index.Box className="admin-tabpanel-main">
              <News
                filteredData={filteredData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleGetNews={handleGetNews}
                valueTabs={valueTabs}
                loading={loading}
                dataLength={dataLength}
              />
            </Index.Box>
          </TabPanel>
        </Index.Box>
      </Index.Box>
      <PagesIndex.Formik
        enableReinitialize
        onSubmit={handleExportCsv}
        initialValues={initialValues}
        validationSchema={PagesIndex.exportNewsSchema}
        innerRef={exportFormRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Index.Modal
            open={exportModalOpen}
            onClose={handleCloseExportModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="admin-modal"
          >
            <form onSubmit={handleSubmit} noValidate="novalidate">
              <Index.Box
                sx={style}
                className="admin-add-user-modal-inner-main admin-modal-inner"
              >
                <Index.Box className="admin-modal-header">
                  <Index.Typography
                    id="modal-modal-title"
                    className="admin-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Export CSV
                  </Index.Typography>
                  <Index.Button
                    className="modal-close-btn"
                    onClick={handleCloseExportModal}
                  >
                    <img
                      src={PagesIndex.Svg.closeblack}
                      className="admin-modal-close-icon"
                      alt="Close"
                    />
                  </Index.Button>
                </Index.Box>
                <Index.Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}
                  className={"admin-modal-hgt-scroll cus-scrollbar"}
                >
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>From Date</span>
                        <span className="astrick-sing">*</span>
                        <span className="option-condition-text">
                          ({valueTabs === 0 ? "Created" : "Publish"})
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderFrom}
                            onClose={() => setOpenCalenderFrom(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.startDate} // Use '01/01/2000' as the default value
                            onChange={(date) => {
                              setFieldValue("startDate", date);
                              setFieldValue("endDate", null);
                            }}
                            disablePast={valueTabs === 2}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderFrom(true),
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-movie-input-box admin-date-input-main ">
                      <Index.FormHelperText className="admin-form-lable">
                        <span>To Date</span>
                        <span className="astrick-sing">*</span>
                        <span className="option-condition-text">
                          ({valueTabs === 0 ? "Created" : "Publish"})
                        </span>
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <PagesIndex.LocalizationProvider
                          dateAdapter={Index.AdapterDayjs}
                        >
                          <PagesIndex.DatePicker
                            open={openCalenderTo}
                            onClose={() => setOpenCalenderTo(false)}
                            className="admin-form-control admin-date-picker-control"
                            value={values.endDate} // Use '01/01/2000' as the default value
                            minDate={
                              values.startDate
                                ? dayjs(values.startDate).add(0, "day")
                                : dayjs().add(0, "day")
                            }
                            onChange={(date) => {
                              setFieldValue("endDate", date);
                            }}
                            disableFuture={valueTabs === 1}
                            slotProps={{
                              popper: {
                                className: "date-picker-body",
                              },
                              textField: {
                                readOnly: true,
                                onClick: () => setOpenCalenderTo(true),
                              },
                            }}
                            format="DD/MM/YYYY"
                          />
                        </PagesIndex.LocalizationProvider>
                        <Index.FormHelperText className="admin-error-text">
                          {errors.endDate && touched.endDate && errors.endDate}
                        </Index.FormHelperText>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                      <Index.FormHelperText className="admin-form-lable">
                        Categories
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.FormControl className="admin-form-control admin-auto-complete-control">
                          <Index.Autocomplete
                            className="admin-auto-complete-filed"
                            options={categoryData || []}
                            name="category"
                            multiple
                            disableCloseOnSelect
                            closeText={""}
                            openText={""}
                            clearText={""}
                            clearIcon={false}
                            value={
                              values?.category
                                ? categoryData.filter((data) =>
                                    values?.category?.some(
                                      (cat) => cat === data?._id
                                    )
                                  )
                                : []
                            }
                            onChange={(e, selectedOptions) => {
                              setFieldValue(
                                "category",
                                selectedOptions.map((option) => option?._id)
                              );
                            }}
                            getOptionLabel={(option) => option?.categoryName}
                            renderOption={(props, item) => (
                              <li {...props} key={item?._id}>
                                {item?.categoryName}
                              </li>
                            )}
                            renderInput={(params) => (
                              <Index.TextField
                                fullWidth
                                className="admin-form-control"
                                placeholder={
                                  values?.category?.length
                                    ? ""
                                    : "Select Category"
                                }
                                {...params}
                                size="small"
                                variant="outlined"
                              />
                            )}
                          />

                          {touched.category && errors.category && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.category}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 6",
                      md: "span 6",
                      lg: "span 6",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-input-box admin-add-news-input-box input-complete-box">
                      <Index.FormHelperText className="admin-form-lable">
                        District
                      </Index.FormHelperText>
                      <Index.Box className="admin-form-group">
                        <Index.FormControl className="admin-form-control admin-auto-complete-control">
                          <Index.Autocomplete
                            className="admin-auto-complete-filed"
                            options={(districtData || []).sort((a, b) =>
                              a?.name?.localeCompare(b?.name)
                            )}
                            name="district"
                            multiple
                            disableCloseOnSelect
                            closeText={""}
                            openText={""}
                            clearText={""}
                            clearIcon={false}
                            value={
                              values?.district
                                ? districtData.filter((data) =>
                                    values?.district?.some(
                                      (cat) => cat === data?._id
                                    )
                                  )
                                : []
                            }
                            onChange={(e, selectedOptions) => {
                              setFieldValue(
                                "district",
                                selectedOptions.map((option) => option?._id)
                              );
                            }}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, item) => (
                              <li {...props} key={item?._id}>
                                {item?.name}
                              </li>
                            )}
                            renderInput={(params) => (
                              <Index.TextField
                                fullWidth
                                className="admin-form-control"
                                placeholder={
                                  values?.district?.length
                                    ? ""
                                    : "Select District"
                                }
                                {...params}
                                size="small"
                                variant="outlined"
                              />
                            )}
                          />

                          {touched.district && errors.district && (
                            <PagesIndex.FormHelperText className="admin-error-text">
                              {errors.district}
                            </PagesIndex.FormHelperText>
                          )}
                        </Index.FormControl>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    gridColumn={{
                      xs: "span 12",
                      sm: "span 12",
                      md: "span 12",
                      lg: "span 12",
                    }}
                    className="grid-column"
                  >
                    <Index.Box className="admin-news-check-main">
                      {values?.checked?.map((item, index) => {
                        return (
                          <Index.Box
                            className="admin-checkbox-main"
                            key={item?._id}
                          >
                            <BpCheckbox
                              checked={item.checked}
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                let updatedChecked = values.checked.map(
                                  (item, idx) => ({
                                    ...item,
                                    checked:
                                      idx === index ? isChecked : item.checked,
                                  })
                                );
                                setFieldValue(`checked`, updatedChecked);
                              }}
                              name={`checked[${index}]`}
                            />
                            <Index.Typography className="admin-checkbox-lable">
                              {item?.label}
                            </Index.Typography>
                          </Index.Box>
                        );
                      })}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="modal-footer">
                  <Index.Box className="admin-modal-user-btn-flex">
                    <Index.Box className="admin-save-btn-main btn-main-primary">
                      <Index.Button
                        className="admin-save-user-btn btn-primary"
                        type="submit"
                      >
                        <img
                          src={PagesIndex.Svg.save}
                          className="admin-user-save-icon"
                          alt="Save"
                        />
                        <span>Export</span>
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </form>
          </Index.Modal>
        )}
      </PagesIndex.Formik>
    </Index.Box>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
  // If there are additional props, you can add them here
};
