import React from "react";
import Index from "../../../Index";

function WeekdayTableView(props) {
  const { data, creativeDuration } = props;
  return (
    <Index.Box className="week-main-wapper">
      <Index.Box className="week-content-main-flex">
        <Index.Box className="week-box">
          <Index.Box className="week-title-main">
            <Index.Typography className="week-title">
              {data?.name}
            </Index.Typography>
          </Index.Box>
        </Index.Box>
        {data?.dates?.map((values) => {
          return (
            <Index.Box className="week-box" key={values?.date}>
              <Index.Typography className="week-date-text">
                {values?.date}
              </Index.Typography>
              <Index.Box className="admin-input-box week-input-box">
                <Index.Box className="admin-form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="admin-form-control"
                    placeholder="0"
                    value={values?.value}
                    disabled
                    inputProps={{ maxLength: 3 }}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          );
        })}
        <Index.Box className="week-box">
          <Index.Typography className="week-date-text"></Index.Typography>
          <Index.Box className="admin-input-box week-input-box">
            <Index.Box className="admin-form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="admin-form-control"
                placeholder=""
                disabled
                value={data?.total}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="week-box">
          <Index.Typography className="week-date-text"></Index.Typography>
          <Index.Box className="admin-input-box week-input-box">
            <Index.Box className="admin-form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="admin-form-control"
                placeholder=""
                disabled
                value={data?.total * creativeDuration}
              />
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </Index.Box>
  );
}

export default WeekdayTableView;
